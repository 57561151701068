import { ThemeProvider } from "@mui/material";
import { Container } from "@mui/system";
import Banner from "./components/banner";
import Menubar from "./components/menubar";
import theme from "./styles/theme";
import Welcome from "./components/welcome";
import OurServices from "./components/ourservices";
import HowItWorks from "./components/howitworks";
import Footer from "./components/footer";
import { useState } from "react";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";

function Home() {
  const [open, setOpen] = useState(true);
  const notificationMessage = process.env.REACT_APP_NOTIFICATION_MESSAGE;
  return (
    <ThemeProvider theme={theme}>
      <Collapse in={open}>
        <Alert
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2, display: notificationMessage ? "flex" : "none" }}
        >
          {notificationMessage}
        </Alert>
      </Collapse>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          display: "column",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Menubar></Menubar>
        <Banner></Banner>
        <Welcome></Welcome>
        <OurServices></OurServices>
        <HowItWorks></HowItWorks>

        <Footer></Footer>
      </Container>
    </ThemeProvider>
  );
}
export default Home;
