import { Typography, useMediaQuery, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { WelcomeContainer } from "../../styles/welcome";
// import { RegisterForm } from "../../sections/auth/register";
import "@fontsource/heebo";
import Image from "../Image";
import ReactPlayer from "react-player";
import TermsandConditions from "./TermsandConditions";

export default function TermsBody() {
  const theme = useTheme();

  return (
    <WelcomeContainer>
      <Box
        align="justify"
        display={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          lineHeight: 2,
          textAlign: "justify",
          fontSize: 16,
          fontFamily: "heebo",
          pl: { md: 8, xl: 10 },
          pr: { md: 8, xl: 10 },
          pt: 0,
          pb: 0,
        }}
      >
        <Box width="100px">
          <Image src="../images/aboutus/RosePetalsTop.png"></Image>
        </Box>
        <Box
          height={8}
          width={50}
          sx={{ mt: "10px" }}
          bgcolor={"#F9C6BC"}
          marginX={"45%"}
          marginBottom={"20px"}
        ></Box>

        <TermsandConditions />

        {/* <Box display="flex" justifyContent="center" sx={{ mt: "100px" }}>
          <ReactPlayer
            controls
            url="" //To update the actual video
          ></ReactPlayer>
        </Box> */}
        <Box display="flex" sx={{ justifyContent: "flex-end", mt: 10 }}>
          <Image src="../images/aboutus/RosePetalsBottom.png"></Image>
        </Box>
      </Box>
    </WelcomeContainer>
  );
}
