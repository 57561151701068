import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { WelcomeContainer } from "../../styles/welcome";
import { RegisterForm } from "../../sections/auth/register";
import { Box } from "@mui/material";
import "@fontsource/heebo";

export default function Welcome() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <WelcomeContainer>
      <Box
        display={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        textAlign="justify"
        sx={{
          fontFamily: "heebo",
          pl: { xl: 10, md: 8 },
          pr: { xl: 10, md: 8 },
          pt: 0,
          pb: 0,
        }}
      >
        <Typography
          color={"#96010A"}
          textAlign={"center"}
          sx={{
            fontFamily: "heebo",
            fontSize: 30,
            fontWeight: 700,
            marginInline: "auto",
            marginTop: "20px",
          }}
        >
          Assalamu Alaikkum
        </Typography>
        {/* <Typography> _____</Typography> */}
        <Box sx={{ my: 8}}>
          <Typography
            sx={{ fontSize: 16, textAlign: "center" }}
            justifyContent={"center"}
          >
            The Wedding has great significance in Islam. We SG Muslim Matrimony,
            Welcome our Beloved Ummah of Prophet (Peace be Upon him) to embark
            on this beautiful journey to fulfill half of your Deen. We thank
            Allah, for giving us this wonderful opportunity to walk with you in
            your marriage, and to continue our relationship throughout your life
            journey of happy successful family which bear fruits of Wiser &
            Stronger Dynamic Ummah. May Allah Accept all our Duas & Bless all
            our Efforts.
          </Typography>
          <Typography sx={{ textAlign: "center", fontSize: 16 }}>
            May Allah unite us all in Jannah. Ameen.
          </Typography>
        </Box>
      </Box>
    </WelcomeContainer>
  );
}
