import React, { useEffect, useState, useRef } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import api from "../../services/RegisterService";
import {useSelector} from "react-redux";

require("./RoundedBars.js");

function RegistrationStatusChart({ pendingrequest }) {
  const { token } = useSelector((state) => state.userPersistedDetails);
  const [registrationStatusdata, setRegistrationStatusdata] = useState({
    labels: ["New", "Approved", "Rejected"],
    datasets: [
      {
        data: [0, 0, 0],
        backgroundColor: ["#47a753", "#4490fe", "#f6c018"],
        barThickness: 80,
      },
    ],
  });

  const chartRef = useRef(null);
  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        let headers = { "authorization": `${token}`};
        const response = await api.get("/registeruser", { headers: headers });

        const newCount = response.data.filter(
          (obj) => obj.regStatus.toLowerCase() === "n"
        ).length;
        const approved = response.data.filter(
          (obj) => obj.regStatus.toLowerCase() === "a"
        ).length;
        const rejected = response.data.filter(
          (obj) => obj.regStatus.toLowerCase() === "r"
        ).length;

        pendingrequest(newCount);
        setRegistrationStatusdata({
          labels: ["New", "Approved", "Rejected"],
          datasets: [
            {
              data: [newCount, approved, rejected],
              backgroundColor: ["#47a753", "#4490fe", "#f6c018"],
              barThickness: 80,
            },
          ],
        });
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else {
          console.log("Error", err.message);
        }
      }
    };
    fetchProfiles();
    document.getElementById("profile-request-legends").innerHTML =
      chartRef.current.chartInstance.generateLegend();
  }, []);

  const options = {
    responsive: true,
    legendCallback: (chart) => {
      var ul = document.createElement("ul");
      ul.id = "bar-chart-legends-list";
      ul.setAttribute("class", "bar-legends-list");
      chart.config.data.labels.forEach(function (value, index) {
        let backgroundColor = chart.config.data.datasets[0].backgroundColor;
        ul.innerHTML += `
                        <li class="legend-item">
                           <div class="circle-dot" style="background: ${backgroundColor[index]}"></div>
                           <span>${value}</span>
                         </li>
                      `;
      });
      return ul.outerHTML;
    },
    legend: {
      display: false,
    },
    cornerRadius: 8,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
            borderDash: [3, 6],
            zeroLineWidth: 1,
            zeroLineBorderDash: [3, 6],
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        color: "#FFF",
      },
    },
  };

  return (
    <>
      <Bar options={options} data={registrationStatusdata} ref={chartRef} />
    </>
  );
}

export default RegistrationStatusChart;
