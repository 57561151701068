import {
  Alert,
  AppBar,
  Button,
  Drawer,
  IconButton,
  MenuItem,
  Popover,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { MenubarContainer, Menubarheader } from "../../styles/menubar";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Menu } from "@mui/icons-material";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Image from "../Image";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import WarningIcon from "@mui/icons-material/Warning";
import { Box } from "@mui/system";

export default function MenubarMobile() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const handleToggle = (event) => {
    setNavbarOpen((prev) => !prev);
    setAnchorElMenu(event.currentTarget);
  };
  let navigate = useNavigate();
  const onClickRegister = () => {
    let path = "/register";
    const data = {
      fullName: "",
      email: "",
      mobile: "",
      profileFor: "",
    };
    navigate(path, {
      state: { data },
    });
  };

  // const handleCloseMenu = () => {
  //   setAnchorElMenu(null);
  //   setNavbarOpen((prev) => !prev);
  // };
  return (
    <MenubarContainer>
      <IconButton
        onClick={handleToggle}
        aria-describedby="Menu"
        aria-label="open drawer"
        edge="start"
        color="primary"
      >
        {navbarOpen ? <CloseIcon /> : <MenuIcon />}
        {/* <MenuIcon /> */}
      </IconButton>
      <Drawer
        anchor="left" //from which side the drawer slides in
        variant="temporary" //if and how easily the drawer can be closed
        open={navbarOpen} //if open is true, drawer is shown
        onClose={handleToggle} //function that is called when the drawer should close
        // onOpen={handleToggle(true)} //function that is called when the drawer should open
      >
        <Box sx={{ backgroundColor: "#ffebe7", pr: "15px" }}>
          <IconButton onClick={handleToggle}>
            <CloseIcon
              color="primary"
              display="flex"
              sx={{
                alignItems: "right",
                textAlign: "right",
                fontSize: "20px",
                justifyContent: "right",
              }}
            />
          </IconButton>
          <Typography color="secondary" variant="h6" sx={{ fontWeight: 700 }}>
            <ul>Menu</ul>
          </Typography>
          <Typography>
            <Button
              size="small"
              fullWidth
              disableRipple
              component={RouterLink}
              to="/"
              sx={{
                color: "#000000",
                p: 1,
                textTransform: "none",
                fontSize: "14px",
                width: "100%",
                textAlign: "left",
                justifyContent: "flex-start",
                height: "50px",
                "&:hover": {
                  fontWeight: 700,
                  backgroundColor: "#8b0f0e",
                  color: "#FFFFFF",
                },
              }}
            >
              <ul>Home</ul>
            </Button>
          </Typography>
          <Typography>
            <Button
              fullWidth
              disableRipple
              component={RouterLink}
              to="/aboutus"
              sx={{
                color: "#000000",
                p: 1,
                textTransform: "none",
                fontSize: "14px",
                width: "100%",
                textAlign: "left",
                justifyContent: "flex-start",
                height: "50px",
                "&:hover": {
                  fontWeight: 700,
                  backgroundColor: "#8b0f0e",
                  color: "#FFFFFF",
                },
              }}
            >
              <ul>About Us</ul>
            </Button>
          </Typography>
          <Typography>
            <Button
              fullWidth
              disableRipple
              component={RouterLink}
              to="/marriageprep"
              sx={{
                color: "#000000",
                p: 1,
                textTransform: "none",
                fontSize: "14px",
                width: "100%",
                textAlign: "left",
                justifyContent: "flex-start",
                height: "50px",
                "&:hover": {
                  fontWeight: 700,
                  backgroundColor: "#8b0f0e",
                  color: "#FFFFFF",
                },
              }}
            >
              <ul>Marriage Preparation Programme</ul>
            </Button>
          </Typography>
          <Typography>
            <Button
              fullWidth
              disableRipple
              component={RouterLink}
              to="/postmarriage"
              sx={{
                color: "#000000",
                p: 1,
                textTransform: "none",
                fontSize: "14px",
                width: "100%",
                textAlign: "left",
                justifyContent: "flex-start",
                height: "50px",
                "&:hover": {
                  fontWeight: 700,
                  backgroundColor: "#8b0f0e",
                  color: "#FFFFFF",
                },
              }}
            >
              <ul>Post Marriage Guidance</ul>
            </Button>
          </Typography>
          <Typography>
            <Button
              fullWidth
              disableRipple
              component={RouterLink}
              to="/transnational"
              sx={{
                color: "#000000",
                p: 1,
                textTransform: "none",
                fontSize: "14px",
                width: "100%",
                textAlign: "left",
                justifyContent: "flex-start",
                height: "50px",
                "&:hover": {
                  fontWeight: 700,
                  backgroundColor: "#8b0f0e",
                  color: "#FFFFFF",
                },
              }}
            >
              <ul>Transnational Support</ul>
            </Button>
          </Typography>
          <Typography>
            <Button
              fullWidth
              disableRipple
              component={RouterLink}
              to="/contactus"
              sx={{
                color: "#000000",
                p: 1,
                textTransform: "none",
                fontSize: "14px",
                width: "100%",
                textAlign: "left",
                justifyContent: "flex-start",
                height: "50px",
                "&:hover": {
                  fontWeight: 700,
                  backgroundColor: "#8b0f0e",
                  color: "#FFFFFF",
                },
              }}
            >
              <ul>Contact Us</ul>
            </Button>
          </Typography>
          <Typography>
            <Button
              onClick={onClickRegister}
              fullWidth
              disableRipple
              // component={RouterLink}
              // to="/register"
              size="small"
              sx={{
                color: "#000000",
                p: 1,
                textTransform: "none",
                fontSize: "14px",
                width: "100%",
                textAlign: "left",
                justifyContent: "flex-start",
                height: "50px",

                "&:hover": {
                  fontWeight: 700,
                  backgroundColor: "#8b0f0e",
                  color: "#FFFFFF",
                },
              }}
            >
              <ul>Register</ul>
            </Button>
          </Typography>
        </Box>
        <Box sx={{ p: 2, textAlign: "justify", width: "auto" }}>
          <Box
            sx={{
              p: 2,
              backgroundColor: "rgb(229, 246, 253)",
              borderRadius: 2,
            }}
          >
            <Typography sx={{ fontSize: "16px" }}>
              <WarningIcon color="warning" fontSize="medium" /> To login and
              access the full feature of the website, please use bigger screens
              like <strong>Laptop or Desktop</strong>.
            </Typography>
          </Box>
        </Box>
      </Drawer>
      {/* <Popover
        id="Menu"
        open={navbarOpen}
        anchorEl={anchorElMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
          pointerEvents: "none",
          display: "column",
        }}
        sx={{ width: "500px", px: "30px" }}
      >
        <RouterLink to="/">
          <ul className={`menuNav ${navbarOpen ? " showMenu" : ""}`} to="/">
            Home
          </ul>
        </RouterLink>
        <RouterLink to="/aboutus">
          <ul className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>
            About Us
          </ul>
        </RouterLink>
        {/* <RouterLink to="/register">
          <ul className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>
            Register
          </ul>
        </RouterLink> */}
      {/* <RouterLink to="/contactus"></RouterLink>
        <ul className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>
          Contact Us
        </ul>
      </Popover> */}
      <Menubarheader textAlign={"center"} variant="h5">
        {/* <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          This website is accessible in Laptop or Desktop. To continue accessing
          the website in your mobile device please enable "Desktop Site" option
          in your browser
        </Alert> */}
        <RouterLink to="/">
          <Image
            src="../images/logo/Logo.png"
            display="flex"
            alignItems="center"
            sx={{ width: "auto", alignItems: "center" }}
          ></Image>
        </RouterLink>
      </Menubarheader>
    </MenubarContainer>
  );
}
