import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  ListItemText,
  Button,
  Divider,
  Typography,
  Stack,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import Popover from "@mui/material/Popover";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Image from "../Image";
import AccountPopover from "./AccountPopover";
// release 2
import { useAuth } from "../../contexts/AuthContext";

import { MenubarContainer, Menubarheader, MyList } from "../../styles/menubar";

export default function MenubarDesktop({ matches }) {
  const [anchorElServices, setAnchorElServices] = useState(null);
  const open1 = Boolean(anchorElServices);
  const id1 = open1 ? "services-menu-group" : undefined;
  // release 2
  const { currentUser } = useAuth();

  const handleClickServices = (event) => {
    setAnchorElServices(event.currentTarget);
  };

  const handleCloseServices = () => {
    setAnchorElServices(null);
  };

  
  let navigate = useNavigate();
  
  const onClickRegister = () => {
    let path = "/register";
    const data = {
      fullName: "",
      email: "",
      mobile: "",
      profileFor: "",
    };
    navigate(path, {
      state: { data },
    });
  };

  return (
    <MenubarContainer>
      <Menubarheader>
        <RouterLink to="/">
          <Image src="../images/logo/Logo.png" sx={{ width: "300px" }}></Image>
        </RouterLink>
      </Menubarheader>
      <MyList type="row">
        <Stack direction="row" justifyContent="flex-end" sx={{ padding: 0 }}>
          <ListItem
            button
            disableRipple
            component={RouterLink}
            to="/"
            sx={{
              borderRadius: "10px",
              width: "auto",
              px: "50px",
              "&:hover": {
                fontWeight: 700,
                backgroundColor: "#ffebe7",
                // width: "auto",
              },
            }}
          >
            <ListItemText
              disableTypography
              primary={
                <Typography
                  // type="body2"
                  style={{
                    fontWeight: 700,
                    fontFamily: "heebo",
                    width: "auto",
                    display: "flex",
                    fontSize: "14px",
                  }}
                >
                  Home
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            button
            disableRipple
            component={RouterLink}
            to="/aboutus"
            sx={{
              borderRadius: "10px",
              width: "auto",
              px: "30px",
              "&:hover": {
                fontWeight: 700,
                backgroundColor: "#ffebe7",
              },
            }}
          >
            <ListItemText
              disableTypography
              primary={
                <Typography
                  type="body2"
                  style={{
                    fontWeight: 700,
                    fontFamily: "heebo",
                    width: "100%",
                    display: "flex",
                    fontSize: "14px",
                  }}
                >
                  About Us
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            id="services-menu-group"
            button
            disableRipple
            variant="contained"
            aria-describedby={id1}
            onClick={handleClickServices}
            sx={{
              borderRadius: "10px",
              width: "auto",
              px: "30px",
              "&:hover": {
                fontWeight: 700,
                backgroundColor: "#ffebe7",
              },
            }}
          >
            <ClickAwayListener onClickAway={handleCloseServices}>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    type="body2"
                    style={{
                      fontWeight: 700,
                      fontFamily: "heebo",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                    }}
                  >
                    Services
                    <ExpandMoreIcon />
                  </Typography>
                }
              ></ListItemText>
            </ClickAwayListener>

            <Popover
              id={id1}
              open={open1}
              anchorEl={anchorElServices}
              onClose={handleCloseServices}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
                pointerEvents: "none",
                display: "column",
              }}
            >
              <Typography>
                <Button
                  disableRipple
                  component={RouterLink}
                  to="/marriageprep"
                  sx={{
                    color: "#000000",
                    p: 1,
                    textTransform: "none",
                    fontSize: "12px",
                    width: "100%",
                    align: "left",
                    justifyContent: "flex-start",
                    "&:hover": {
                      // border: "1px solid #00FF00",

                      fontWeight: 700,
                      backgroundColor: "#ffebe7",
                    },
                  }}
                >
                  Marriage Preparatory Programme
                </Button>
              </Typography>
              <Typography>
                <Button
                  disableRipple
                  component={RouterLink}
                  to="/postmarriage"
                  sx={{
                    color: "#000000",
                    p: 1,
                    textTransform: "none",
                    fontSize: "12px",
                    width: "100%",
                    textAlign: "left",
                    justifyContent: "flex-start",
                    "&:hover": {
                      fontWeight: 700,
                      backgroundColor: "#ffebe7",
                    },
                  }}
                >
                  Post Marriage Guidance
                </Button>
              </Typography>
              <Typography>
                <Button
                  disableRipple
                  component={RouterLink}
                  to="/transnational"
                  width="100%"
                  sx={{
                    color: "#000000",
                    p: 1,
                    textTransform: "none",
                    fontSize: "12px",
                    width: "100%",
                    textAlign: "left",
                    justifyContent: "flex-start",
                    "&:hover": {
                      fontWeight: 700,
                      backgroundColor: "#ffebe7",
                    },
                  }}
                >
                  Transnational Family Support
                </Button>
              </Typography>
            </Popover>
          </ListItem>

          <ListItem
            button
            disableRipple
            component={RouterLink}
            to="/contactus"
            sx={{
              borderRadius: "10px",
              width: "auto",
              px: "20px",
              "&:hover": {
                fontWeight: 700,
                backgroundColor: "#ffebe7",
              },
            }}
          >
            <ListItemText
              disableTypography
              primary={
                <Typography
                  type="body2"
                  style={{
                    fontWeight: 700,
                    fontFamily: "heebo",
                    width: "auto",
                    display: "flex",
                    fontSize: "14px",
                  }}
                >
                  Contact Us
                </Typography>
              }
            />
          </ListItem>

          {/* release 2 */}
          <ListItem
            sx={{
              borderRadius: "10px",
              display: currentUser ? "none" : "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "auto",
            }}
            // sx={{ display: "none" }}
            onClick={onClickRegister}
          >
            <Button
              variant="contained"
              disableElevation
              component={RouterLink}
              to="/register"
              sx={{
                fontWeight: 700,
                textTransform: "none",
                fontSize: "14px",
                fontFamily: "heebo",
                height: "25px",
                marginLeft: 1,
                marginRight: 1,
                backgroundColor: "primary",
                display: currentUser ? "none" : "flex",
                "&:hover": {
                  backgroundColor: "#8b0f0e",
                  color: "#FFFFFF",
                },
              }}
            >
              Register
            </Button>
          </ListItem>

          <Divider orientation="vertical" flexItem />

          <ListItem
            sx={{
              borderRadius: "10px",
              display: currentUser ? "none" : "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "auto",
            }}
          >
            <Button
              variant="outlined"
              component={RouterLink}
              to="/login"
              sx={{
                fontWeight: 700,
                textTransform: "none",
                fontSize: "14px",
                fontFamily: "heebo",
                height: "25px",
                marginLeft: 1,
                marginRight: 1,
                display: currentUser ? "none" : "column",
                justifyContent: "center",
                alignItems: "center",
                color: "#8b0f0e",
                borderColor: "#8b0f0e",
                "&:hover": {
                  backgroundColor: "#8b0f0e",
                  color: "#FFFFFF",
                  border: "none",
                },
              }}
            >
              Login
            </Button>
          </ListItem>

          {currentUser ? <AccountPopover /> : ""}
        </Stack>
      </MyList>
    </MenubarContainer>
  );
}
