export const CarouselMessages = [
  {
    Reference: " (Surah Ar-Room, 30:21)",
    title: "Tranquility in your spouse",
    Description:
      "”And of His signs is that He created for you from yourselves mates that you may find tranquility in them, and He placed between you affection and mercy. Indeed in that are signs for those who give thought.”",
  },
  {
    Reference: " (Surah Al-Baqarah, 2:187)",
    title: "Your Spouses are garment",
    Description: "”Your Spouses are a garment for you as you are for them.”",
  },
  {
    Reference: " (Sunan At-Tirmidhi)",
    title: "Practices of Messengers",
    Description:
      "”Four are from the practices of the Messengers: Modesty, the use of perfume, the use of tooth stick, Marriage.”",
  },
];
