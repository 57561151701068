import { Box, List, styled, Typography } from "@mui/material";
import "@fontsource/kurale";
import "@fontsource/inter";

import theme from "../theme";

//container

export const MenubarContainer = styled(Box)(() => ({
  display: "flex",
  marginTop: 4,
  marginBottom: 4,
  justifyContent: "center",
  alignItems: "center",
  padding: "2px 8px",
  color: "#3366FF",
  borderBottom: 1,
}));

//header
export const Menubarheader = styled(Box)(() => ({
  padding: "2px",
  //   width: "60px",
  flexGrow: 1,
  //   fontSize: "15px",
  color: "#96010A",
  fontWeight: 400,
}));

export const MyList = styled(List)(({ type }) => ({
  display: type === "row" ? "flex-end" : "block",
  //   marginLeft: "100px",
  flexGrow: 1,
  //   justifyContent: "flex-end",
  alignItems: "center",
  color: "#000000",
  fontFamily: "heebo",
  fontWeight: 700,
  fontSize: "12px",
}));
