import { Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import { Box, Container } from "@mui/system";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { MyProfilePage } from "../../styles/profilesearch";
import theme from "../../styles/theme";
import Footer from "../footer";
import Label from "../Label";
import Menubar from "../menubar";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  yearList,
  monthList,
  educationList,
  residentialStatusList,
  countries,
  languages,
} from "../../_mock/map";
import { useState } from "react";
import Notification from "../Notification";
import axios from "axios";
import { format, parse } from "date-fns";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect } from "react";
import api from "../../services/RegisterService";
import moment from "moment";
import { useSelector } from "react-redux";
import ColorBar from "../ColorBar";

function EditprofilePage() {
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const { selectedEditProfileid } = useSelector((state) => state.myProfile);
  const { name, userId, token } = useSelector(
    (state) => state.userPersistedDetails
  );
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });
  let navigate = useNavigate();
  const [pStatus, setPStatus] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const RegisterSchema = Yup.object().shape({
    profileId: Yup.string().required("Profile Id is required"),
    profilename: Yup.string().required("Full Name is required"),
    gender: Yup.string().required("Select the Gender"),
    marriageStatus: Yup.string().required("Select the Marital Status"),
    monthOfBirth: Yup.string().required("Select the Month of Birth"),
    yearOfBirth: Yup.string().required("Select the Year of Birth"),
    profileCountry: Yup.string().required("Select the Profile Country"),
    citizenShipCountry: Yup.string().required("Select the Citizenship Country"),
    parentName: Yup.string().required("Enter Parent/Guardian Name"),
    heightInCm: Yup.string().required("Height is required"),
    primaryContact: Yup.string()
      .required("Enter Main Contact No.")
      .matches(/^\+[1-9]{1}[0-9]{3,14}$/, "Phone number is not valid"),
    emaiId: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
  });

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(RegisterSchema),
    defaultValues: {
      profileId: "",
      profilename: "",
      gender: "",
      marriageStatus: "",
      dateOfBirth: "",
      heightInCm: "",
      heightInch: "",
      education: "",
      educationLevel: "",
      profession: "",
      profileCountry: "",
      sgResidentialStatus: "",
      residingCountry: "",
      citizenShipCountry: "",
      parentName: "",
      nativePlace: "",
      yearOfBirth: "",
      monthOfBirth: "",
      languagesKnown: "",
      primaryContact: "",
      secondaryContact: "",
      emaiId: "",
      additionalInfo: "",
      registrationDate: "",
      partnerCountryPreference: "",
      partnerProfilePreference: "",
      disclaimAcceptanceStatus: "",
      submissionStatus: "",
      status: "",
      createdUser: "",
    },
  });

  useEffect(() => {
    async function fetchprofile() {
      try {
        let headers = { authorization: token };
        const response = await api.get(
          `/profiledetails/${selectedEditProfileid}?userRole=A`,
          { headers: headers }
        );
        const myProfileData = response.data;
        setPStatus(response.data.submissionStatus);
        const {
          marriageStatus,
          heightInCm,
          heightInch,
          profileId,
          dateOfBirth,
          educationLevel,
          education,
          profession,
          sgResidentStatus,
          parentName,
          partnerCountryPreference,
          partnerProfilePreference,
          additionalInfo,
          secondaryContact,
          primaryContact,
          nativePlace,
          gender,
          citizenShipCountry,
          profilename,
          profileCountry,
          residingCountry,
          emaiId,
          disclaimAcceptanceStatus,
          submissionStatus,
          languagesKnown,
          status,
          createdUser,
          registrationDate: dob,
        } = myProfileData;

        if (typeof languagesKnown === "string") {
          const languageArray = languagesKnown.split(",");
          setSelectedLanguages(languageArray);
        }
        const defaultValues = {
          profileId: profileId !== "" && profileId !== null ? profileId : "-",
          profilename:
            profilename !== "" && profilename !== null ? profilename : "-",
          gender: gender !== "" && gender !== null ? gender : "-",
          marriageStatus:
            marriageStatus !== "" && marriageStatus !== null
              ? marriageStatus
              : "-",
          dateOfBirth:
            dateOfBirth !== "" && dateOfBirth !== null ? dateOfBirth : "-",
          heightInCm:
            heightInCm !== "" && heightInCm !== null ? heightInCm : "-",
          heightInch:
            heightInch !== "" && heightInch !== null ? heightInch : "-",
          education: education !== "" && education !== null ? education : "-",
          educationLevel:
            educationLevel !== "" && educationLevel !== null
              ? educationLevel
              : "-",
          profession:
            profession !== "" && profession !== null ? profession : "-",
          profileCountry:
            profileCountry !== "" && profileCountry !== null
              ? profileCountry
              : "-",
          sgResidentialStatus:
            sgResidentStatus !== "" && sgResidentStatus !== null
              ? sgResidentStatus
              : "",
          residingCountry:
            residingCountry !== "" && residingCountry !== null
              ? residingCountry
              : "-",
          citizenShipCountry:
            citizenShipCountry !== "" && citizenShipCountry !== null
              ? citizenShipCountry
              : "-",
          parentName:
            parentName !== "" && parentName !== null ? parentName : "-",
          nativePlace:
            nativePlace !== "" && nativePlace !== null ? nativePlace : "-",
          languagesKnown:
            languagesKnown !== "" && languagesKnown !== null
              ? languagesKnown
              : "-",
          yearOfBirth: +dateOfBirth.slice(0, 4),
          monthOfBirth: dateOfBirth.slice(5, 7),
          primaryContact: primaryContact.replace(/\s+/g, ""),
          secondaryContact: secondaryContact.replace(/\s+/g, ""),
          emaiId: emaiId !== "" && emaiId !== null ? emaiId : "-",
          additionalInfo:
            additionalInfo !== "" && additionalInfo !== null
              ? additionalInfo
              : "-",
          registrationDate: dob !== "" && dob !== null ? dob : "-",
          partnerCountryPreference:
            partnerCountryPreference !== "" && partnerCountryPreference !== null
              ? partnerCountryPreference
              : "-",
          partnerProfilePreference:
            partnerProfilePreference !== "" && partnerProfilePreference !== null
              ? partnerProfilePreference
              : "-",
          disclaimAcceptanceStatus:
            disclaimAcceptanceStatus !== "" && disclaimAcceptanceStatus !== null
              ? disclaimAcceptanceStatus
              : "-",
          submissionStatus:
            submissionStatus !== "" && submissionStatus !== null
              ? submissionStatus
              : "-",
          status: status !== "" && status !== null ? status : "-",
          createdUser:
            createdUser !== "" && createdUser !== null ? createdUser : "-",
        };
        reset(defaultValues);
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else {
          console.log("Error", err.message);
        }
      }
    }
    fetchprofile();
  }, []);

  const languagesKnownChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLanguages(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  function toFeet(n) {
    var realFeet = (n * 0.3937) / 12;
    var feet = Math.floor(realFeet);
    var inches = Math.round((realFeet - feet) * 12);
    return feet + "'" + " " + inches;
  }

  const onSubmitDraft = (data) => {
    saveProfile(data, "D", "I");
  };

  const onSubmitActivate = (data) => {
    saveProfile(data, "S", "A");
  };
  async function saveProfile(data, flag, pStatus) {
    try {
      let headers = { authorization: `${token}` };
      const response = await api.put(
        "/profiledetails/" + data.profileId,
        {
          profileId: data.profileId,
          profilename: data.profilename,
          gender: data.gender,
          marriageStatus: data.marriageStatus,
          dateOfBirth: data.yearOfBirth + "-" + data.monthOfBirth + "-01",
          heightInCm: data.heightInCm,
          heightInch: toFeet(data.heightInCm),
          education: data.education,
          educationLevel: data.educationLevel,
          profession: data.profession,
          profileCountry: data.profileCountry,
          sgResidentStatus: data.sgResidentialStatus,
          residingCountry: data.residingCountry,
          citizenShipCountry: data.citizenShipCountry,
          parentName: data.parentName,
          nativePlace: data.nativePlace,
          languagesKnown: data.languagesKnown.toString(),
          primaryContact: data.primaryContact,
          secondaryContact: data.secondaryContact,
          emaiId: data.emaiId,
          additionalInfo: data.additionalInfo,
          registrationDate: data.registrationDate,
          partnerCountryPreference: data.partnerCountryPreference,
          partnerProfilePreference: data.partnerProfilePreference,
          disclaimAcceptanceStatus: "Y",
          submissionStatus: flag,
          status: pStatus,
          updatedUser: userId,
        },
        { headers: headers }
      );
      setNotify({
        isOpen: true,
        message:
          flag === "D"
            ? "Profile Saved as Draft"
            : flag === "S"
            ? "Profile Submitted Successfully"
            : "",
        type: "success",
      });
      //   navigate("/profilelist", {
      //     state: { data },
      //   });
      return response.data;
    } catch (err) {
      console.log(err);
      setNotify({
        isOpen: true,
        message: "Profile Submission Failed",
        type: "error",
      });
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          display: "column",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Menubar></Menubar>

        <form>
          <MyProfilePage>
            <Box
              align="justify"
              display={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                lineHeight: 2,
                textAlign: "justify",
                fontSize: 20,
                fontFamily: "heebo",
                pl: 1,
                pr: 5,
                pt: 0,
                pb: "45px",
                mt: 2,
              }}
            >
              <ColorBar />
              <Typography
                sx={{
                  fontFamily: "heebo",
                  fontSize: 30,
                  fontWeight: 700,
                  marginBottom: "40px",
                }}
              >
                Edit Profile
              </Typography>
              {/* profile section start  */}
              <Typography sx={{ mt: 7, fontSize: "25px" }}>
                Profile Details
              </Typography>
              <Grid
                spacing={5}
                container
                item
                sm={12}
                sx={{ marginTop: "-25px" }}
              >
                {/* profile id field  */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>
                      Profile ID <span style={{ color: "#D6396B" }}>*</span>
                    </span>
                  </Label>
                  <TextField
                    inputlabelprops={{ shrink: false }}
                    fullWidth
                    disabled
                    name="profileId"
                    {...register("profileId")}
                    error={!!errors?.profileId}
                    helperText={
                      errors?.profileId ? errors.profileId.message : null
                    }
                  ></TextField>
                </Grid>
                {/* marital status */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>
                      Marital Status <span style={{ color: "#D6396B" }}>*</span>
                    </span>
                  </Label>
                  <Controller
                    name="marriageStatus"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        inputlabelprops={{ shrink: false }}
                        fullWidth
                        name="marriageStatus"
                        select
                        {...register("marriageStatus")}
                        error={!!errors?.marriageStatus}
                        helperText={
                          errors?.marriageStatus
                            ? errors.marriageStatus.message
                            : null
                        }
                        value={value}
                        onChange={onChange}
                      >
                        <MenuItem key="1" value="Single">
                          Single
                        </MenuItem>
                        <MenuItem key="2" value="Divorced/Widowed">
                          Divorced/Widowed
                        </MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid spacing={5} item container>
                  {/* full name field */}
                  <Grid item md={6} lg={6} sm={12}>
                    <Label>
                      <span style={{ fontSize: "15px" }}>
                        Full Name <span style={{ color: "#D6396B" }}>*</span>
                      </span>
                    </Label>

                    <TextField
                      inputlabelprops={{ shrink: false }}
                      fullWidth
                      name="profilename"
                      {...register("profilename")}
                      error={!!errors?.profilename}
                      helperText={
                        errors?.profilename ? errors.profilename.message : null
                      }
                    ></TextField>
                  </Grid>
                  {/* month/year of birth field */}
                  <Grid item md={6} lg={6} sm={12}>
                    <Label>
                      <span style={{ fontSize: "15px" }}>
                        Month/Year of Birth{" "}
                        <span style={{ color: "#D6396B" }}>*</span>
                      </span>
                    </Label>
                    <Grid spacing={2} item container>
                      <Grid item lg={6} md={6}>
                        <Controller
                          name="monthOfBirth"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              inputlabelprops={{ shrink: false }}
                              fullWidth
                              select
                              name="monthOfBirth"
                              {...register("monthOfBirth")}
                              error={!!errors?.monthOfBirth}
                              helperText={
                                errors?.monthOfBirth
                                  ? errors.monthOfBirth.message
                                  : null
                              }
                              value={value}
                              onChange={onChange}
                            >
                              {monthList.map((option, index) => (
                                <MenuItem
                                  sx={{ height: "40px" }}
                                  key={index}
                                  value={option.code}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <Controller
                          name="yearOfBirth"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              inputlabelprops={{ shrink: false }}
                              fullWidth
                              select
                              name="yearOfBirth"
                              {...register("yearOfBirth")}
                              error={!!errors?.yearOfBirth}
                              helperText={
                                errors?.yearOfBirth
                                  ? errors.yearOfBirth.message
                                  : null
                              }
                              value={value}
                              onChange={onChange}
                            >
                              {yearList.map((option, index) => (
                                <MenuItem
                                  sx={{ height: "40px" }}
                                  key={index}
                                  value={option.code}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Gender field */}
                  <Grid spacing={5} container item sm={12}>
                    <Grid item md={6} lg={6} sm={12}>
                      <Label>
                        <span style={{ fontSize: "15px" }}>
                          Gender <span style={{ color: "#D6396B" }}>*</span>
                        </span>
                      </Label>
                      <Controller
                        name="gender"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            inputlabelprops={{ shrink: false }}
                            fullWidth
                            select
                            name="gender"
                            {...register("gender")}
                            error={!!errors?.gender}
                            helperText={
                              errors?.gender ? errors.gender.message : null
                            }
                            value={value}
                            onChange={onChange}
                          >
                            <MenuItem sx={{ height: "40px" }} key="F" value="F">
                              Bride
                            </MenuItem>
                            <MenuItem sx={{ height: "40px" }} key="M" value="M">
                              Bridegroom
                            </MenuItem>
                          </TextField>
                        )}
                      />
                    </Grid>
                    {/* Height (cm) field */}
                    <Grid item md={6} lg={6} sm={12}>
                      <Label>
                        <span style={{ fontSize: "15px" }}>
                          Height (cm){" "}
                          <span style={{ color: "#D6396B" }}>*</span>
                        </span>
                      </Label>

                      <TextField
                        inputlabelprops={{ shrink: false }}
                        fullWidth
                        name="heightInCm"
                        {...register("heightInCm")}
                        error={!!errors?.heightInCm}
                        helperText={
                          errors?.heightInCm ? errors.heightInCm.message : null
                        }
                      ></TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* profile section end  */}
              {/* eduction section start */}
              <Typography sx={{ mt: 7, fontSize: "25px" }}>
                Education and Professional Information
              </Typography>
              <Grid
                spacing={5}
                container
                item
                sm={12}
                sx={{ marginTop: "-25px" }}
              >
                {/* Highest Education field  */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>Highest Education</span>
                  </Label>
                  <Controller
                    name="education"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        inputlabelprops={{ shrink: false }}
                        fullWidth
                        select
                        name="education"
                        {...register("education")}
                        value={value}
                        onChange={onChange}
                      >
                        {educationList.map((option, index) => (
                          <MenuItem
                            sx={{ height: "40px" }}
                            key={index}
                            value={option.code}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                {/* profession field */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>Profession</span>
                  </Label>

                  <TextField
                    inputlabelprops={{ shrink: false }}
                    fullWidth
                    name="profession"
                    {...register("profession")}
                  ></TextField>
                </Grid>
                <Grid spacing={5} item container>
                  {/* Qualification field */}
                  <Grid item md={6} lg={6} sm={12}>
                    <Label>
                      <span style={{ fontSize: "15px" }}>Qualification</span>
                    </Label>

                    <TextField
                      inputlabelprops={{ shrink: false }}
                      name="educationLevel"
                      fullWidth
                      {...register("educationLevel")}
                    ></TextField>
                  </Grid>
                </Grid>
              </Grid>
              {/* eduction section end */}
              {/* Personal Information section start */}
              <Typography sx={{ mt: 7, fontSize: "25px" }}>
                Personal Information
              </Typography>
              <Grid
                spacing={5}
                container
                item
                sm={12}
                sx={{ marginTop: "-25px" }}
              >
                {/* Profile Country field  */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>
                      Profile Country{" "}
                      <span style={{ color: "#D6396B" }}>*</span>
                    </span>
                  </Label>
                  <Controller
                    name="profileCountry"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        inputlabelprops={{ shrink: false }}
                        fullWidth
                        select
                        name="profileCountry"
                        {...register("profileCountry")}
                        error={!!errors?.profileCountry}
                        helperText={
                          errors?.profileCountry
                            ? errors.profileCountry.message
                            : null
                        }
                        value={value}
                        onChange={onChange}
                      >
                        <MenuItem sx={{ height: "40px" }} key="1" value="SGP">
                          Singapore
                        </MenuItem>
                        <MenuItem sx={{ height: "40px" }} key="2" value="G">
                          Global
                        </MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                {/* Parent's/Guardian's Name field */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>
                      Parent's/Guardian's Name{" "}
                      <span style={{ color: "#D6396B" }}>*</span>
                    </span>
                  </Label>

                  <TextField
                    inputlabelprops={{ shrink: false }}
                    fullWidth
                    name="parentName"
                    {...register("parentName")}
                    error={!!errors?.parentName}
                    helperText={
                      errors?.parentName ? errors.parentName.message : null
                    }
                  ></TextField>
                </Grid>
              </Grid>
              <Grid
                spacing={5}
                container
                item
                sm={12}
                sx={{ marginTop: "-25px" }}
              >
                {/* Residential Status (SG) field  */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>
                      Residential Status (SG)
                    </span>
                  </Label>
                  <Controller
                    name="sgResidentialStatus"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        inputlabelprops={{ shrink: false }}
                        fullWidth
                        select
                        name="sgResidentialStatus"
                        {...register("sgResidentialStatus")}
                        value={value}
                        onChange={onChange}
                      >
                        {residentialStatusList.map((option, index) => (
                          <MenuItem
                            sx={{ height: "40px" }}
                            key={index}
                            value={option.code}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                {/* Native Place field */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>Native Place</span>
                  </Label>

                  <TextField
                    inputlabelprops={{ shrink: false }}
                    fullWidth
                    name="nativePlace"
                    {...register("nativePlace")}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid
                spacing={5}
                container
                item
                sm={12}
                sx={{ marginTop: "-25px" }}
              >
                {/* Citizenship Country(SG) field  */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>
                      Citizenship Country{" "}
                      <span style={{ color: "#D6396B" }}>*</span>
                    </span>
                  </Label>
                  <Controller
                    name="citizenShipCountry"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        inputlabelprops={{ shrink: false }}
                        fullWidth
                        name="citizenShipCountry"
                        select
                        value={value}
                        onChange={onChange}
                        {...register("citizenShipCountry")}
                        error={!!errors?.citizenShipCountry}
                        helperText={
                          errors?.citizenShipCountry
                            ? errors.citizenShipCountry.message
                            : null
                        }
                      >
                        {countries.map((option, index) => (
                          <MenuItem
                            sx={{ height: "40px" }}
                            key={index}
                            value={option.code}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                {/* Languages Known field */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>Languages Known</span>
                  </Label>

                  <Select
                    fullWidth
                    multiple
                    name="languagesKnown"
                    {...register("languagesKnown")}
                    onChange={languagesKnownChange}
                    value={selectedLanguages}
                    displayEmpty
                  >
                    {languages.map((option, index) => (
                      <MenuItem
                        sx={{ height: "35px" }}
                        key={index}
                        value={option}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>
                      Residential Country (G)
                    </span>
                  </Label>
                  <Controller
                    name="residingCountry"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        inputlabelprops={{ shrink: false }}
                        fullWidth
                        select
                        name="residingCountry"
                        {...register("residingCountry")}
                        id="residingCountry-dropdown"
                        value={value}
                        onChange={onChange}
                      >
                        {countries.map((option1) => (
                          <MenuItem
                            // sx={{ height: "40px" }}
                            key={option1.code}
                            value={option1.code}
                          >
                            {option1.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
              </Grid>
              {/* Personal Information section end */}
              {/* contact section start */}
              <Typography sx={{ mt: 7, fontSize: "25px" }}>
                Contact Information
              </Typography>
              <Grid
                spacing={5}
                container
                item
                sm={12}
                sx={{ marginTop: "-25px" }}
              >
                {/* Main Contact No. field  */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>
                      Main Contact No.{" "}
                      <span style={{ color: "#D6396B" }}>*</span>
                    </span>
                  </Label>
                  <TextField
                    disabled
                    inputlabelprops={{ shrink: false }}
                    fullWidth
                    name="primaryContact"
                    {...register("primaryContact")}
                    error={!!errors?.primaryContact}
                    helperText={
                      errors?.primaryContact
                        ? errors.primaryContact.message
                        : null
                    }
                  ></TextField>
                </Grid>
                {/* Alt. Contact No. field */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>Alt. Contact No.</span>
                  </Label>

                  <TextField
                    inputlabelprops={{ shrink: false }}
                    fullWidth
                    name="secondaryContact"
                    {...register("secondaryContact")}
                  ></TextField>
                </Grid>
                <Grid spacing={5} item container>
                  {/* Email Id field */}
                  <Grid item md={6} lg={6} sm={12}>
                    <Label>
                      <span style={{ fontSize: "15px" }}>
                        Email Id <span style={{ color: "#D6396B" }}>*</span>
                      </span>
                    </Label>

                    <TextField
                      disabled
                      inputlabelprops={{ shrink: false }}
                      fullWidth
                      name="emaiId"
                      {...register("emaiId")}
                      error={!!errors?.emailId}
                      helperText={
                        errors?.emailId ? errors.emailId.message : null
                      }
                    ></TextField>
                  </Grid>
                </Grid>
              </Grid>
              {/* contact section end */}
              {/* contact section start */}
              <Typography sx={{ mt: 7, fontSize: "25px" }}>
                Additional Information
              </Typography>
              <Grid
                spacing={5}
                container
                item
                sm={12}
                sx={{ marginTop: "-25px" }}
              >
                {/* Additional Information field  */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>
                      Additional Information
                    </span>
                  </Label>
                  <TextField
                    inputlabelprops={{ shrink: false }}
                    multiline
                    rows={4}
                    fullWidth
                    name="additionalInfo"
                    {...register("additionalInfo")}
                  ></TextField>
                </Grid>
                {/* Alt. Contact No. field */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>
                      Expected Partner Profile
                    </span>
                  </Label>

                  <TextField
                    inputlabelprops={{ shrink: false }}
                    multiline
                    rows={4}
                    fullWidth
                    name="partnerProfilePreference"
                    {...register("partnerProfilePreference")}
                  ></TextField>
                </Grid>
                <Grid spacing={5} item container>
                  {/* Registration Date field */}
                  <Grid item md={6} lg={6} sm={12}>
                    <Label>
                      <span style={{ fontSize: "15px" }}>
                        Registration Date
                      </span>
                    </Label>

                    <Box>
                      <Controller
                        control={control}
                        name="registrationDate"
                        render={({ field: { onChange, value } }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              inputlabelprops={{ shrink: false }}
                              fullWidth
                              name="registrationDate"
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              value={moment(value)} // DatePicker accepts a moment object
                              onChange={(dateObject, dateString) => {
                                onChange(format(dateObject.$d, "yyyy-MM-dd")); // No need of a state
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={6} lg={6} sm={12}>
                    <Label>
                      <span style={{ fontSize: "15px" }}>
                        Expected Partner Country
                      </span>
                    </Label>

                    <TextField
                      inputlabelprops={{ shrink: false }}
                      fullWidth
                      name="partnerCountryPreference"
                      {...register("partnerCountryPreference")}
                    ></TextField>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="10vh"
            >
              <LoadingButton
                onClick={handleSubmit(onSubmitDraft)}
                className="submit-button"
                size="large"
                type="submit"
                variant="contained"
                id="submitButton"
                disabled={pStatus === "D" ? false : true}
                sx={{
                  color: "#000000",
                  backgroundColor: "#f9c6bc",
                  m: 2,
                  "&:hover": {
                    color: "#000000",
                    backgroundColor: "#f9c6bc",
                  },
                }}
              >
                Save As Draft
              </LoadingButton>
              <LoadingButton
                onClick={handleSubmit(onSubmitActivate)}
                className="submit-button"
                size="large"
                type="submit"
                variant="contained"
                id="submitButton"
                sx={{
                  color: "#000000",
                  backgroundColor: "#f9c6bc",
                  m: 2,
                  "&:hover": {
                    color: "#000000",
                    backgroundColor: "#f9c6bc",
                  },
                }}
              >
                {pStatus === "D" ? "Submit and Activate" : "Save"}
              </LoadingButton>
            </Box>
          </MyProfilePage>
        </form>
        <Footer></Footer>
      </Container>
      <Notification notify={notify} setNotify={setNotify} />
    </ThemeProvider>
  );
}

export default EditprofilePage;
