import { Box, Hidden, List, styled, Typography } from "@mui/material";
import "@fontsource/heebo";

export const WelcomeContainer = styled(Box)(({ theme }) => ({
  display: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "auto",
  height: "100%",
  padding: "20px 10px",
  background: "#FFFFFF",
  backgroundSize: "cover",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
  },
}));

export const ServiceContainer = styled(Box)(({ theme }) => ({
  display: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "auto",
  height: "auto",
  padding: "50px 10px",
  background: "#F7F7F7",
  backgroundSize: "cover",
  fontFamily: "heebo",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 20px",
    justifyContent: "center",
  },
}));

export const ServiceWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "heebo",
  width: "auto",
  height: "auto",
  padding: "10px 50px",
  // background: '#FFFFFF',
  backgroundSize: "cover",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
  },
}));

export const ServiceColumn = styled(Box)(({ theme }) => ({
  display: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "auto",
  // background: '#00DE34',
  fontFamily: "heebo",
  backgroundSize: "cover",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
  },
}));

export const ServiceTxtBlock = styled(Box)(({ theme }) => ({
  display: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "auto",
  height: "300px",
  padding: "10px 20px",
  fontFamily: "heebo",
  // background: '#FFFFFF',
  backgroundSize: "cover",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
  },
}));

export const ServiceThumbnailSmall = styled(Box)(({ theme }) => ({
  display: "flex",
  // justifyContent: 'center',
  alignItems: "center",
  marginTop: "10px",
  marginBottom: "20px",
  marginLeft: "20px",
  marginRight: "20px",
  fontFamily: "heebo",
  width: "auto",
  height: "300px",
  padding: "20px 20px",
  borderRadius: 10,
  background: "#FFFFFF",
  backgroundSize: "cover",
  backgroundImage: "url(../images/services/laptopbg.jpg)",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
  },
}));

export const HowItWorksColumn = styled(Box)(({ theme }) => ({
  display: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  width: "200px",
  height: "250px",
  margin: "40px",
  // background: '#00DE34',
  fontFamily: "heebo",
  backgroundSize: "cover",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
  },
}));

export const HowItWorksWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "heebo",
  width: "auto",
  height: "100%",
  padding: "10px 50px",
  // background: '#FFFFFF',
  backgroundSize: "cover",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 5px",
    justifyContent: "center",
  },
}));
