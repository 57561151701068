export const yearList = [
  { code: "1970", label: "1970" },
  { code: "1971", label: "1971" },
  { code: "1972", label: "1972" },
  { code: "1973", label: "1973" },
  { code: "1974", label: "1974" },
  { code: "1975", label: "1975" },
  { code: "1976", label: "1976" },
  { code: "1977", label: "1977" },
  { code: "1978", label: "1978" },
  { code: "1979", label: "1979" },
  { code: "1980", label: "1980" },
  { code: "1981", label: "1981" },
  { code: "1982", label: "1982" },
  { code: "1983", label: "1983" },
  { code: "1984", label: "1984" },
  { code: "1985", label: "1985" },
  { code: "1986", label: "1986" },
  { code: "1987", label: "1987" },
  { code: "1988", label: "1988" },
  { code: "1989", label: "1989" },
  { code: "1990", label: "1990" },
  { code: "1991", label: "1991" },
  { code: "1992", label: "1992" },
  { code: "1993", label: "1993" },
  { code: "1994", label: "1994" },
  { code: "1995", label: "1995" },
  { code: "1996", label: "1996" },
  { code: "1997", label: "1997" },
  { code: "1998", label: "1998" },
  { code: "1999", label: "1999" },
  { code: "2000", label: "2000" },
  { code: "2001", label: "2001" },
  { code: "2002", label: "2002" },
  { code: "2003", label: "2003" },
  { code: "2004", label: "2004" },
  { code: "2005", label: "2005" },
  { code: "2006", label: "2006" },
  { code: "2007", label: "2007" },
  { code: "2008", label: "2008" },
  { code: "2009", label: "2009" },
  { code: "2010", label: "2010" },
  { code: "2011", label: "2011" },
  { code: "2012", label: "2012" },
  { code: "2013", label: "2013" },
  { code: "2014", label: "2014" },
  { code: "2015", label: "2015" },
  { code: "2016", label: "2016" },
  { code: "2017", label: "2017" },
  { code: "2018", label: "2018" },
  { code: "2019", label: "2019" },
  { code: "2020", label: "2020" },
  { code: "2021", label: "2021" },
  { code: "2022", label: "2022" },
  { code: "2023", label: "2023" },
  { code: "2024", label: "2024" },
  { code: "2025", label: "2025" },
  { code: "2026", label: "2026" },
  { code: "2027", label: "2027" },
  { code: "2028", label: "2028" },
  { code: "2029", label: "2029" },
  { code: "2030", label: "2030" },
  { code: "2031", label: "2031" },
  { code: "2032", label: "2032" },
  { code: "2033", label: "2033" },
  { code: "2034", label: "2034" },
  { code: "2035", label: "2035" },
  { code: "2036", label: "2036" },
  { code: "2037", label: "2037" },
  { code: "2038", label: "2038" },
  { code: "2039", label: "2039" },
  { code: "2040", label: "2040" },
];
