import "@fontsource/heebo";
import {
  Box,
  Typography,
  useMediaQuery,
  styled,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  AdminContainer,
} from "../../styles/profilesearch";
import * as React from "react";
import { useState } from "react";
import UserList from "./UserList";
import ColorBar from "../ColorBar";

export default function UserListBody() {
  const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down("md"));
  // const [query, setQuery] = useState("");
  // const MyComponent = styled("div")({
  //   color: "darkslategray",
  //   padding: 8,
  //   borderRadius: 4,
  //   fontSize: "20px",
  //   alignmentBaseline: "true",
  //   display: "flex",
  //   alignItems: "center",
  // });

  return (
    <AdminContainer>
      <Box
        align="justify"
        display={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          lineHeight: 2,
          textAlign: "justify",
          fontSize: 20,
          fontFamily: "heebo",
          pl: 1,
          pr: 5,
          pt: 0,
          pb: 0,
        }}
      >
        <ColorBar />
        <Typography
          sx={{
            fontFamily: "heebo",
            fontSize: 30,
            fontWeight: 700,
            marginBottom: "40px",
          }}
        >
          User List
        </Typography>
        <UserList></UserList>
      </Box>
    </AdminContainer>
  );
}
