import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// release 2
import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { persistor, store } from "./app/store";
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // release2
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AuthProvider>
        <React.StrictMode>
          <BrowserRouter>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </BrowserRouter>
        </React.StrictMode>
      </AuthProvider>
    </PersistGate>
  </Provider>

  // <React.StrictMode>
  //   <BrowserRouter>
  //     <Routes>
  //       <Route path="/*" element={<App />} />
  //     </Routes>
  //   </BrowserRouter>
  // </React.StrictMode>
);
