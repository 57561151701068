import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// import { WelcomeContainer } from "../../styles/welcome";
// import { RegisterForm } from "../../sections/auth/register";
import { Box } from "@mui/material";
import "@fontsource/heebo";
import { Typography } from "@mui/material";
import { LandingColumn } from "../../styles/landingpage";
// import Image from "../Image";
import { useEffect } from "react";
// import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
// import CardActions from "@mui/material/CardActions";
import { Circle } from "../../styles/landingpage";
import Paper from "@mui/material/Paper";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import { useAuth } from "../../contexts/AuthContext";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function LandingPageContainer() {
  const theme = useTheme();
  const { currentUser } = useAuth();
  const { name, shortCode, profileId, userId } = useSelector(
    (state) => state.userPersistedDetails
  );
  const { pathname } = useLocation();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const paperstyles = {
    paperContainer: {
      display: "flex",
      justifyContent: "center",
      width: "auto",
      height: "auto",
      padding: "50px 10px",
      margin: "10px 10px",
      borderRadius: "20px",
      // [theme.breakpoints.down('md')]: {
      //     flexDirection: 'column',
      //     alignItems: 'center',
      //     padding: '5px 5px',
      //     justifyContent: 'center'

      // }
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Paper elevation={1} style={paperstyles.paperContainer}>
      <LandingColumn sx={{ width: "200%" }}>
        <LandingColumn display="flex" sx={{ margin: "0px" }}>
          <Box>
            <Circle>{shortCode}</Circle>
          </Box>
          <Box display="column">
            <Box display="block" sx={{ height: "40px" }}>
              <Typography variant="h5">{name}</Typography>
            </Box>
            <Box display="block" sx={{ py: "4px" }}>
              <Typography variant="h7">{currentUser.email}</Typography>
            </Box>
            <Box display="block" sx={{ py: "4px" }}>
              <Typography variant="h7">
                {/* {role === "U" ? "User" : role === "A" ? "Admin" : ""} */}
                {userId}
              </Typography>
            </Box>
          </Box>
        </LandingColumn>
      </LandingColumn>
      <LandingColumn>
        <Box display="block" sx={{ height: "40px" }}>
          <ManageAccountsIcon sx={{ fontSize: "40px" }} />
        </Box>
        <Box display="block" sx={{ py: "4px" }}>
          <Typography variant="h6">My Profile</Typography>
        </Box>
        <Box display="block">
          <Button
            component={RouterLink}
            to="/myprofile"
            variant="contained"
            disabled={profileId ? false : true}
            sx={{
              fontWeight: 700,
              textTransform: "none",
              fontFamily: "heebo",
              borderRadius: "8px",
            }}
          >
            View Details
          </Button>
        </Box>
      </LandingColumn>
      <LandingColumn>
        <Box display="block" sx={{ height: "40px" }}>
          <PersonSearchIcon sx={{ fontSize: "40px" }} />
        </Box>
        <Box display="block" sx={{ py: "4px" }}>
          <Typography variant="h6">Profile Search</Typography>
        </Box>
        <Box display="block">
          <Button
            component={RouterLink}
            to="/profilesearch"
            variant="contained"
            sx={{
              fontWeight: 700,
              textTransform: "none",
              fontFamily: "heebo",
              borderRadius: "8px",
            }}
          >
            Search Matchings
          </Button>
        </Box>
      </LandingColumn>
      <LandingColumn>
        <Box display="block" sx={{ height: "40px" }}>
          <ConnectWithoutContactIcon sx={{ fontSize: "40px" }} />
        </Box>
        <Box display="block" sx={{ py: "4px" }}>
          <Typography variant="h6">Profile Request</Typography>
        </Box>
        <Box display="block">
          <Button
            disabled
            variant="contained"
            sx={{
              fontWeight: 700,
              textTransform: "none",
              fontFamily: "heebo",
              borderRadius: "8px",
            }}
          >
            Coming Soon
          </Button>
        </Box>
      </LandingColumn>
    </Paper>
  );
}
