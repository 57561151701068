import { Grid, Stack, Box, Paper, Typography, Divider } from "@mui/material";
import { CardHeader } from "../../styles/profilesearch/Cardstyle";
import Image from "../Image";
import Button from "@mui/material/Button";
import Label from "../Label";
import { useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import api from "../../services/RegisterService";
import { useDispatch, useSelector } from "react-redux";
import { applyClearFilter, totalRecords } from "../../features/filterSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { applySelectedProfileId } from "../../features/myProfileSlice";
import { countries } from "../../_mock/map/countries";

export default function ProfileCards({ query }) {
  const {
    genderFilter,
    country,
    languages,
    rangeYearOfBirth,
    rangeHeight,
    education,
    marriageStatus,
    clearFilter,
    residenceChange,
  } = useSelector((state) => state.filter);
  const theme = useTheme();
  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { token } = useSelector((state) => state.userPersistedDetails);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        let headers = { authorization: `${token}` };
        const response = await api.get(
          "/profiledetails?profileStatus=A&userRole=U",
          { headers: headers }
        );
        setProfiles(response.data);
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else {
          console.log("Error", err.message);
        }
      }
    };
    fetchProfiles();
  }, []);

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        let headers = { authorization: `${token}` };
        const response = await api.get(
          "/profiledetails?profileStatus=A&userRole=U",
          { headers: headers }
        );
        setFilteredProfiles(response.data);
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else {
          console.log("Error", err.message);
        }
      }
    };
    fetchProfiles();
  }, [clearFilter]);

  useEffect(() => {
    if (filteredProfiles.length === 0) {
      const fetchProfiles = async () => {
        try {
          let headers = { authorization: `${token}` };
          const response = await api.get(
            "/profiledetails?profileStatus=A&userRole=U",
            { headers: headers }
          );
          setFilteredProfiles(response.data);
          dispatch(totalRecords(response.data.length));
        } catch (err) {
          if (err.response) {
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else {
            console.log("Error", err.message);
          }
        }
      };
      fetchProfiles();
    } else {
      // gender filter
      let filteredResult = profiles.filter(
        (value) => genderFilter === value.gender || genderFilter === "all"
      );

      // height filter
      let [min, max] = rangeHeight;
      filteredResult = filteredResult.filter((value) => {
        let height = value.heightInCm;
        if ((height >= min && height <= max) || min == 0) {
          return true;
        }
      });

      // countery filter
      filteredResult = filteredResult.filter(
        (value) =>
          country === value.profileCountry ||
          country === "all" ||
          country === null
      );

      // residance filter
      filteredResult = filteredResult.filter((value) => {
        return (
          value.sgResidentStatus === residenceChange ||
          residenceChange === "all"
        );
      });

      /* Language Filter */
      let languageFiltered = [];
      if (languages.length > 0) {
        languages.forEach((item, index) => {
          filteredResult.filter((value) => {
            if (item === "Any") {
              if (!languageFiltered.includes(value)) {
                languageFiltered.push(value);
              }
            } else {
              switch (typeof value.languagesKnown) {
                case "string":
                  if (
                    !languageFiltered.includes(value) &&
                    value.languagesKnown.includes(item)
                  ) {
                    languageFiltered.push(value);
                  }
                  break;
                case "object":
                  // code here
                  break;
                case "undefined":
                  // code here
                  break;
              }
            }
          });
        });
        filteredResult = languageFiltered;
      }

      // yob filter
      let [start, end] = rangeYearOfBirth;
      filteredResult = filteredResult.filter((value) => {
        let birthyear = value.dateOfBirth.slice(0, 4);
        if (birthyear >= start && birthyear <= end) {
          return true;
        }
      });

      // education filter
      filteredResult = filteredResult.filter((value) => {
        return (
          education.toLowerCase() === value.education.toLowerCase() ||
          education === null ||
          education === "all"
        );
      });

      // material status
      filteredResult = filteredResult.filter((value) => {
        return (
          value.marriageStatus === marriageStatus || marriageStatus === "all"
        );
      });
      dispatch(totalRecords(filteredResult.length));
      /* Language Filter */
      setFilteredProfiles(filteredResult);
    }
  }, [
    genderFilter,
    country,
    rangeHeight,
    rangeYearOfBirth,
    languages,
    education,
    marriageStatus,
    residenceChange,
  ]);

  const calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    return age_now;
  };

  const getMonthYear = (dob2) => {
    var birthDate = new Date(dob2); // create a date object directly from `dob2` argument
    var year = birthDate.getFullYear();
    var month = birthDate.getMonth() + 1;

    const date = new Date();
    date.setMonth(month - 1);
    var monthyear =
      date.toLocaleString("en-SG", { month: "short" }) + " " + year;
    return monthyear;
  };

  const viewProfile = (e, profileId) => {
    e.preventDefault();
    dispatch(applySelectedProfileId(profileId));
    navigate("/viewprofile");
  };

  const nullPageStyle = {
    display: "flex",
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  };

  const handleRefresh = () => {
    dispatch(applyClearFilter(true));
  };
  return (
    <Grid
      className="profile-card-container"
      container
      spacing={3}
      sx={{
        p: 1,
        mb: "2px",
        height: "auto",
        height: "900px",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      {filteredProfiles.length > 0 ? (
        filteredProfiles
          .filter(
            (profile) =>
              profile.profilename.toLowerCase().includes(query) ||
              profile.nativePlace.toLowerCase().includes(query) ||
              profile.profileId.toLowerCase().includes(query)
          )
          .map((profile, index) => (
            <Grid
              key={index}
              display="column"
              item
              xs={12}
              md={4}
              sx={{
                mb: "2px",
              }}
            >
              <Paper
                elevation={3}
                sx={{ borderRadius: "20px", backgroundColor: "F6F6F6" }}
              >
                <Stack spacing={3}>
                  <Box sx={{ height: "auto", color: "#FFFFFF", mb: "10px" }}>
                    <CardHeader
                      sx={{
                        background:
                          profile.gender === "M" ? "#424B7F" : "#ED819B",
                      }}
                    >
                      <Typography
                        display="block"
                        align="center"
                        sx={{ mt: "35px", fontSize: "25px" }}
                      >
                        {profile.profilename.split(" ").slice(0, 2).join(" ")}
                      </Typography>
                      <Typography
                        display="block"
                        align="center"
                        sx={{ mt: "10px", fontSize: "15px" }}
                      >
                        {countries
                          .filter(
                            (value) =>
                              value.code.toLowerCase() ===
                              profile.citizenShipCountry.toLowerCase()
                          )[0]
                          ?.label.toUpperCase()}
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent={"center"}
                        sx={{ mt: "15px" }}
                      >
                        <Image
                          src="../../../images/profilesearch/shape-avatar.svg"
                          sx={{ width: "150px" }}
                        ></Image>
                      </Box>
                    </CardHeader>
                  </Box>
                  <Box
                    display="column"
                    justifyContent={"center"}
                    sx={{ height: "auto", color: "#FFFFFF" }}
                  >
                    <Box display="flex" justifyContent={"center"}>
                      <Box
                        display="flex"
                        sx={{
                          backgroundColor:
                            profile.gender === "M" ? "#424B7F" : "#ED819B",
                          borderRadius: "50%",
                          width: "70px",
                          height: "70px",
                          justifyContent: "center",
                          alignItems: "center",
                          mt: "-60px",
                        }}
                      >
                        <Typography>
                          {calculate_age(profile.dateOfBirth)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display="grid"
                      justifyContent={"center"}
                      sx={{ mt: "20px", width: "auto" }}
                    >
                      <Label
                        variant={
                          theme.palette.mode === "light" ? "ghost" : "filled"
                        }
                        color={profile.gender === "M" ? "info" : "primary"}
                      >
                        {profile.profileCountry === "SGP"
                          ? "Singapore"
                          : profile.profileCountry === "G"
                          ? "Global"
                          : ""}
                      </Label>
                      <Button
                        variant="contained"
                        onClick={(e) => viewProfile(e, profile.profileId)}
                        sx={{
                          backgroundColor:
                            profile.gender === "M" ? "#424B7F" : "#ED819B",
                          color: "#FFFFFF",
                          mt: 2,
                          "&:hover": {
                            backgroundColor: "#FFFFFF",
                            color:
                              profile.gender === "M" ? "#424B7F" : "#ED819B",
                            border: "none",
                          },
                        }}
                      >
                        View Profile
                      </Button>
                    </Box>

                    <Divider sx={{ mt: "15px", borderStyle: "solide" }} />
                  </Box>
                  <Box
                    sx={{
                      gap: 3,
                      pb: 2,
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                    }}
                  >
                    <div>
                      <Typography
                        component="div"
                        sx={{
                          textAlign: "center",
                          mb: 0.75,
                          color: "#454545",
                          fontSize: "12px",
                        }}
                      >
                        Height
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: "center",
                          mb: 0.75,
                          color: "#000000",
                          fontWeight: 700,
                          fontSize: "14px",
                        }}
                      >
                        {profile.heightInCm} cm {"("}
                        {profile.heightInch}
                        {")"}
                      </Typography>
                    </div>

                    <div>
                      <Typography
                        variant="caption"
                        component="div"
                        sx={{
                          textAlign: "center",
                          mb: 0.75,
                          color: "#454545",
                        }}
                      >
                        Month/Year of Birth
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          textAlign: "center",
                          mb: 0.75,
                          color: "#000000",
                          fontWeight: 700,
                          fontSize: "14px",
                        }}
                      >
                        {/* {format(Date(), "dd-MM-yyyy")} */}
                        {getMonthYear(profile.dateOfBirth)}
                      </Typography>
                    </div>
                  </Box>
                </Stack>
              </Paper>
            </Grid>
          ))
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              p: 2,
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ p: 2, display: "flex", color: "#EB5D41" }}>
              <Typography>No records found for the search criteria</Typography>
            </Box>
            <Button variant="contained" onClick={handleRefresh}>
              Refresh profiles
            </Button>
          </Box>
        </>
      )}
    </Grid>
  );
}
