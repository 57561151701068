import * as Yup from "yup";
import { useState } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  MenuItem,
  Typography,
  Checkbox,
  Link,
  Box,
  Modal,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// hooks
import useIsMountedRef from "../../../hooks/useIsMountedRef";
// components
import Iconify from "../../../components/Iconify";
import ReCAPTCHA from "react-google-recaptcha";
import { RecaptchaContainer } from "../../../styles/login";
import { profileFor } from "../../../data/_profileFor";
import { countries } from "../../../_mock/map";
import { useLocation } from "react-router-dom";
import Notification from "../../../components/Notification";
import api from "../../../services/RegisterService";
import axios from "axios";
// ----------------------------------------------------------------------
const RegisterSchema = Yup.object().shape({
  userId: Yup.string().required("User Id is required"),
  email: Yup.string()
    .email("Email must be a valid email address")
    .required("Email is required"),
  mobile: Yup.string().required("Mobile No. is required"),
  newPassword: Yup.string()
    .required("Password is required")
    .min(4, "Password should have minimum 4 characters.")
    .max(14, "Password can have upto 14 characters."),
  confirmNewPassword: Yup.string().oneOf(
    [Yup.ref("newPassword"), null],
    "Passwords does not match"
  ),
});

export default function ChangePasswordForm() {
  const location = useLocation();
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues: {
      fullName: "",
      countryPrefix: 65,
      email: "",
      mobile: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  // console.log("Received Data", location);

  const isMountedRef = useIsMountedRef();
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITEKEY;
  // const [data, setData] = useState([]);
  // const [formData, setFormData] = useState(
  //   location.state.data
  //     ? {
  //         fullName: location.state.data.fullName,
  //         countryPrefix: 65,
  //         mobile: location.state.data.mobile,
  //         email: location.state.data.email,
  //         profileFor: location.state.data.profileFor,
  //       }
  //     : {
  //         fullName: "",
  //         // countryPrefix: "",
  //         mobile: "",
  //         email: "",
  //         profileFor: "",
  //       }
  //   // password: "",
  //   // confirmPassword: "",
  // );

  async function onSubmit(data, e) {
    e.preventDefault();

    //Email VIa API

    try {
      const response = await api
        .post("userdetails/changepassword", {
          userId: data.userId,
          userEmail: data.email,
          phoneNumber: data.mobile,
          password: data.newPassword,
        })
        .then((response) => {
          setNotify({ isOpen: true, message: response.data, type: "info" });
        });
      reset();

      try {
        const body =
          "<html xmlns='http://www.w3.org/1999/xhtml'><head><meta http-equiv='Content-Type' content='text/html;charset=utf-8' /><meta http-equiv='X-UA-Compatible' content='IE=edge' /><meta name='viewport' content='width=device-width, initial-scale=1.0'><style type='text/css'> body {Margin: 0;padding: 0;background-color: #f7f9fc} table {border - spacing: 0;}td {padding: 0;}img {border: 0;}.wrapper{width: 100%; table-layout: fixed;background-color: #f7f9fc;padding-bottom: 40px;}.webkit {max - width: 600px;background-color: #ffffff;} .outer {Margin: 0 auto;width: 100%;max-width: 600px;border-spacing: 0;font-family: sans-serif;color:#4a4a4a;.theadstyle {text - align:left;background-color: #F9C6BC;height: 50px;} .tdata {padding - left: 20px;padding-right: 20px;width: 200px;height: 50px;} .datatable {margin - left: auto;margin-right: auto;border-collapse:separate;border:solid #F9C6BC 3px;border-radius:6px;}@media screen and (max-width: 600px){ } @media screen and (max-width: 400px) { } </style></head> <body> <center class='wrapper'><div class='webkit><table class=' outer' align='center'><tr><td><table width='100%' style='border-spacing:0;'><tr><td style='background-color: #F9C6BC; padding: 10px; text-align: center;'></td></tr><tr><td style='background-color: #ffffff; padding: 10px; text-align: center;'><a href=><img src='https://cloud1.email2go.io/8171ac2c5544a5cb54ac0f38bf477af4/c65c58dc81dd10e1fc57803f2f91186f658e8d226e587ecf6adb3eceb5b0df42.png' width='250' alt=''></a></td></tr><tr><td style='background-color: #ffebe7; padding: 10px; text-align: justify;'><p>Assalamu Alaikkum !</p><p> Dear User:" +
          data.userId +
          ",</p><p>Your password has been changed successfully.</p> <p>If you have not requested the password change, please contact our administrators immediately.</p> <p>Our administrators are available on call @ (+65)85011436 </p><p>" +
          "<tr><p>This email is autogenerated from the system. Please do not reply to this email.</p></td></tr><tr><td style='background-color: #F9C6BC; padding: 10px; text-align: center;'></td></tr><tr></table></td></tr></table></div></center></body></html>";
        const emailAPI = "/sendemail/contactus";
        const sendEmail = await api.post(emailAPI, {
          emailto: data.email,
          subject: "Password Change Notification",
          body: body,
          // emailcc: "sgmuslimmatrimony@gmail.com", //PROD
          emailcc: process.env.REACT_APP_ADMIN_EMAILID, //DEV
        });
      } catch (err) {
        console.log("Password Changed. Email notification failed", err);
      }
      // console.log("Response", response);
    } catch (error) {
      // console.log("Error", error.response.data);
      setError("afterSubmit", { ...error, message: error.response.data });
    }
  }

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  //   fetch("http://localhost:8090/api/users", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //
  //     }),
  //   })
  //     .then((res) => res.json())
  //     .then((result) => setData(result.rows))
  //     .catch((err) => console.log("error"));
  // };
  // function createUser(data) {
  //   // e.preventDefault();
  //   console.log(data);
  //   // signup(data.email,data.password)
  // }
  function onChange() {
    // // console.log("Captcha value:", value);
    //console.log("Captcha Verified?", captchaVerified);
    setCaptchaVerified(!captchaVerified);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} background="#FFFFF">
      {/* // <form onSubmit={createUser} background="#FFFFF"> */}
      <Stack spacing={2}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        <TextField
          name="userId"
          label="User Id"
          defaultValue=""
          {...register("userId")}
          error={!!errors?.userId}
          helperText={errors?.userId ? errors.userId.message : null}
        />
        <TextField
          name="email"
          label="Email address"
          defaultValue=""
          {...register("email")}
          error={!!errors?.email}
          helperText={errors?.email ? errors.email.message : null}
        />
        {/* <TextField
          select
          name="countryPrefix"
          label="Country Code"
          fontFamily="heebo"
          defaultValue=""
          {...register("countryPrefix")}
          error={!!errors?.countryPrefix}
          helperText={
            errors?.countryPrefix ? errors.countryPrefix.message : null
          }
        >
          {countries.map((option) => (
            <MenuItem
              sx={{ height: "40px" }}
              key={option.phone}
              value={option.phone}
            >
              {option.label}
              {"(" + option.phone + ")"}
            </MenuItem>
          ))}
        </TextField> */}
        <TextField
          name="mobile"
          label="Mobile No."
          defaultValue=""
          {...register("mobile")}
          error={!!errors?.mobile}
          helperText={errors?.mobile ? errors.mobile.message : null}
        />

        <TextField
          name="newPassword"
          label="New Password"
          type={showPassword ? "text" : "password"}
          {...register("newPassword")}
          error={!!errors?.newPassword}
          helperText={errors?.newPassword ? errors.newPassword.message : null}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          name="confirmNewPassword"
          label="Confirm New Password"
          {...register("confirmNewPassword")}
          error={!!errors?.confirmNewPassword}
          helperText={
            errors?.confirmNewPassword
              ? errors.confirmNewPassword.message
              : null
          }
          type={showConfirmPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  <Iconify
                    icon={
                      showConfirmPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RecaptchaContainer>
          <ReCAPTCHA sitekey={recaptchaKey} onChange={onChange} />
        </RecaptchaContainer>
        <LoadingButton
          sx={{ fontFamily: "heebo", fontWeight: 700 }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={!captchaVerified}
          id="submitButton"
        >
          Request Password Change
        </LoadingButton>
      </Stack>
      <Notification notify={notify} setNotify={setNotify} />
    </form>
  );
}
