import "@fontsource/heebo";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Paper,
  Typography,
  useMediaQuery,
  Button,
  RadioGroup,
  Radio,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import { useTheme } from "@mui/material/styles";
import {
  ProfileSearchContainer,
  SearchBodyContainer,
  SearchCardContainer,
  SearchFilterContainer,
  SearchHeader,
} from "../../styles/profilesearch";
import TuneIcon from "@mui/icons-material/Tune";
import Slider from "@mui/material/Slider";
import * as React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ProfileCards from "./profilecard";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  applyGenderFilter,
  applylanguageFilter,
  applyClearFilter,
  applyCountryFilter,
  applyEducationFilter,
  applyHeightFilter,
  applymarriageStatusFilter,
  applyResidenceChange,
  applyYearFilter,
} from "../../features/filterSlice";
import { useRef } from "react";
import { useEffect } from "react";
import ColorBar from "../ColorBar";
import { languages } from "../../_mock/map";

function valuetext(value) {
  return `${value}cm`;
}

export default function ProfileSearchBody() {
  const { totalRecords, clearFilter } = useSelector((state) => state.filter);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [heightvalue, setHeightValue] = React.useState([100, 200]);
  const [country, setCountry] = React.useState("");
  const [residence, setResidence] = React.useState("");
  const [language, setLanguage] = React.useState([]);
  const [yobvalue, setYobValue] = React.useState([1985, 2000]);
  const [education, setEducation] = React.useState("");
  const [maritalstatus, setMaritalstatus] = React.useState("");
  const [selectedlanguage, setSelectedlanguage] = useState([]);
  const [query, setQuery] = useState("");
  const [genderSelected, setGenderSelected] = useState({
    male: false,
    female: false,
    all: true,
  });
  const handleHeightSlideChange = (event, newValue) => {
    dispatch(applyHeightFilter(newValue));
    setHeightValue(newValue);
  };

  const allGenderRadio = useRef("");

  const [languageCheckStates, setLanguageCheckStates] = useState({
    any: false,
    tamil: false,
    english: false,
    urdu: false,
    malay: false,
  });

  useEffect(() => {
    setHeightValue([100, 200]);
    setCountry("");
    setResidence("");
    setLanguage("");
    setYobValue([1985, 2000]);
    setMaritalstatus("");
    setEducation("");
    setSelectedlanguage([]);
    setGenderSelected({ male: false, female: false, all: true });
    setLanguageCheckStates({
      any: false,
      tamil: false,
      english: false,
      urdu: false,
      malay: false,
    });
  }, [clearFilter]);

  const handleCountryChange = (event) => {
    dispatch(applyCountryFilter(event.target.value));
    setCountry(event.target.value);
  };

  const handleResidenceChange = (event) => {
    dispatch(applyResidenceChange(event.target.value));
    setResidence(event.target.value);
  };

  const handleYobSlideChange = (event, newValue) => {
    setYobValue(newValue);
    dispatch(applyYearFilter(newValue));
  };

  const handleEduChange = (event) => {
    dispatch(applyEducationFilter(event.target.value));
    setEducation(event.target.value);
  };

  const handleMaritalStatusChange = (event) => {
    dispatch(applymarriageStatusFilter(event.target.value));
    setMaritalstatus(event.target.value);
  };

  const ClickSubmit = (e) => {
    e.preventDefault();
    dispatch(applyClearFilter(true));
    // alert("hi");
  };
  const convertedCentoFeet = (values) => {
    var realFeet = (values * 0.3937) / 12;
    var feet = Math.floor(realFeet);
    var inches = Math.round((realFeet - feet) * 12);
    return feet + "'" + inches;
  };
  const handleLanguageChange = (event, name) => {
    if (event.target.name !== undefined) {
      languageCheckStates[event.target.name.toLowerCase()] =
        event.target.checked;
      setLanguageCheckStates(languageCheckStates);
    }
    const {
      target: { value },
    } = event;
    setLanguage(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    if (event.target.checked) {
      // if check interest add in selectedValue
      setSelectedlanguage((oldValue) => [...oldValue, value]);
    } else {
      // if uncheck interest remove from selectedValue
      setSelectedlanguage(selectedlanguage.filter((item) => item !== value));
    }
  };
  useEffect(() => {
    dispatch(applylanguageFilter(selectedlanguage));
  }, [selectedlanguage]);

  const handleRadioChange = (e) => {
    if (e.target.value === "bridegroom") {
      dispatch(applyGenderFilter("M"));
      setGenderSelected({ male: true, female: false, all: false });
    } else if (e.target.value === "bride") {
      dispatch(applyGenderFilter("F"));
      setGenderSelected({ male: false, female: true, all: false });
    } else {
      dispatch(applyGenderFilter("all"));
      setGenderSelected({ male: false, female: false, all: true });
    }
  };

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <ProfileSearchContainer>
      <Box
        align="justify"
        display={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          lineHeight: 2,
          textAlign: "justify",
          fontSize: 20,
          fontFamily: "heebo",
          pl: 1,
          pr: 5,
          pt: 2,
          pb: "15px",
        }}
      >
        <ColorBar />
        <Typography
          sx={{
            fontFamily: "heebo",
            fontSize: 30,
            fontWeight: 700,
          }}
        >
          Profile Search
        </Typography>
      </Box>
      <SearchHeader>
        <Box
          align="justify"
          display={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            lineHeight: 2,
            textAlign: "justify",
            fontSize: 10,
            fontFamily: "heebo",
          }}
        >
          <Paper
            component="form"
            sx={{
              p: "2px 0px",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1, fontSize: "15px" }}
              placeholder="Search Profiles"
              inputProps={{ "aria-label": "search google maps" }}
              id="searchprofileid"
              onChange={(e) => setQuery(e.target.value.toLowerCase())}
            />
            <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Box>
      </SearchHeader>
      <SearchBodyContainer sx={{ alignItems: "start" }}>
        <SearchFilterContainer
          sx={{
            display: "column",
            width: "30%",
            backgroundColor: "#F7F7F7",
            borderRadius: "15px",
          }}
        >
          <Box sx={{ p: 2, display: "flex", color: "#EB5D41" }}>
            <Typography variant="h6">Total Records: {totalRecords}</Typography>{" "}
          </Box>
          <Box sx={{ p: 2, display: "flex", color: "#EB5D41" }}>
            <TuneIcon sx={{ fontSize: "25px", mr: 2 }} />{" "}
            <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
              Filter
            </Typography>{" "}
          </Box>
          <form sx={{ width: "100%" }} onSubmit={ClickSubmit}>
            <Box sx={{ px: 2, display: "column" }}>
              <FormLabel id="radio-buttons-group-label">
                <Typography sx={{ fontWeight: 700 }}>
                  Bride/Bridegroom
                </Typography>
              </FormLabel>
              <RadioGroup
                onChange={handleRadioChange}
                aria-labelledby="radio-buttons-group-label"
                /* defaultValue="female" */
                name="radio-buttons-group"
              >
                <FormControlLabel
                  id="radio-bride"
                  value="bride"
                  control={
                    <Radio size="small" checked={genderSelected.female} />
                  }
                  label="Bride"
                  className="genderRadio"
                />
                <FormControlLabel
                  id="radio-bridegroom"
                  value="bridegroom"
                  control={<Radio size="small" checked={genderSelected.male} />}
                  label="Bridegroom"
                  className="genderRadio"
                />
                <FormControlLabel
                  id="radio-all"
                  value="all"
                  control={<Radio size="small" checked={genderSelected.all} />}
                  label="All"
                  className="genderRadio"
                  ref={allGenderRadio}
                />
              </RadioGroup>
            </Box>
            <Box sx={{ p: 2, display: "column" }}>
              <FormLabel id="heightLabel">
                <Typography sx={{ fontWeight: 700 }}>Height</Typography>
              </FormLabel>
            </Box>
            <Box sx={{ px: 2, display: "column" }}>
              <Slider
                size="small"
                getAriaLabel={() => "Height"}
                value={heightvalue}
                onChange={handleHeightSlideChange}
                min={0}
                max={300}
                valueLabelDisplay="on"
                getAriaValueText={valuetext}
                color="secondary"
              />
            </Box>
            <Box sx={{ p: 2, display: "column" }}>
              <FormLabel id="countryLabel">
                <Typography sx={{ fontWeight: 700 }}>Country</Typography>
              </FormLabel>
              <Select
                size="small"
                labelId="countrylabel"
                id="dropdownCountryLabel"
                value={country}
                onChange={handleCountryChange}
                sx={{ px: 2, width: "100%" }}
              >
                <MenuItem value={"SGP"} key="SGP">
                  Singapore
                </MenuItem>
                <MenuItem value={"G"} key="G">
                  Global
                </MenuItem>
                <MenuItem value={"all"} key="all">
                  All
                </MenuItem>
              </Select>
            </Box>
            {/* remaining */}
            <Box sx={{ p: 2, display: "column" }}>
              <FormLabel id="residence">
                <Typography sx={{ fontWeight: 700 }}>
                  Residential Status (SG)
                </Typography>
              </FormLabel>
              <Select
                size="small"
                labelId="dropdownResidence"
                id="dropdownResidence"
                value={residence}
                onChange={handleResidenceChange}
                sx={{ px: 2, width: "100%" }}
              >
                <MenuItem value={"all"} key="1">
                  All
                </MenuItem>
                <MenuItem value={"Singapore Citizen (SC)"} key="2">
                  Singapore Citizen (SC)
                </MenuItem>
                <MenuItem value={"Permanent Resident (PR)"} key="3">
                  Permanent Resident (PR)
                </MenuItem>
                <MenuItem value={"Employment Pass (EP)"} key="4">
                  Employment Pass (EP)
                </MenuItem>
                <MenuItem value={"S-Pass"} key="5">
                  S-Pass
                </MenuItem>
                <MenuItem value={"Dependent Pass (DP)"} key="6">
                  Dependent Pass (DP)
                </MenuItem>
                <MenuItem value={"LTVP"} key="7">
                  LTVP
                </MenuItem>
                <MenuItem value={"Dependent Pass (DP)"} key="8">
                  Dependent Pass (DP)
                </MenuItem>
                <MenuItem value={"LTVP"} key="9">
                  LTVP
                </MenuItem>
                <MenuItem value={"Entrepreneur Pass"} key="10">
                  Entrepreneur Pass
                </MenuItem>
              </Select>
            </Box>

            <Box sx={{ p: 2, display: "column" }}>
              <FormLabel id="languageLabel">
                <Typography sx={{ fontWeight: 700 }}>Language (SG)</Typography>
              </FormLabel>
              {languages.map((name, index) => {
                return (
                  <Box sx={{ px: 2 }} key={index}>
                    <FormLabel onClick={handleLanguageChange}>
                      <input
                        type="checkbox"
                        id={`language-checkbox-${index}`}
                        name={name}
                        value={name}
                        key={name}
                        checked={languageCheckStates[name.toLowerCase()]}
                        onChange={() => {}}
                      />
                      <label htmlFor={`language-checkbox-${index}`}>
                        {name}
                      </label>
                    </FormLabel>
                  </Box>
                );
              })}
            </Box>
            <Box sx={{ p: 2, display: "column" }}>
              <FormLabel id="yobLabel">
                <Typography sx={{ fontWeight: 700 }}>Year of Birth</Typography>
              </FormLabel>
            </Box>
            <Box sx={{ p: 2, display: "column" }}>
              <Slider
                size="small"
                getAriaLabel={() => "YearofBirth"}
                value={yobvalue}
                onChange={handleYobSlideChange}
                min={1980}
                max={new Date().getFullYear() - 18}
                valueLabelDisplay="on"
                getAriaValueText={valuetext}
                color="secondary"
              />
            </Box>

            <Box sx={{ p: 2, display: "column" }}>
              <FormLabel id="eduLabel">
                <Typography sx={{ fontWeight: 700 }}>
                  Highlest Level of Education{" "}
                </Typography>
              </FormLabel>
              <Select
                size="small"
                labelId="edudropdownlabel"
                id="edudropdownlabel"
                value={education}
                onChange={handleEduChange}
                sx={{ px: 2, width: "100%" }}
              >
                <MenuItem value={"Doctoral(PhD)"} key="1">
                  Doctoral (PhD)
                </MenuItem>
                <MenuItem value={"Master"} key="2">
                  Master
                </MenuItem>
                <MenuItem value={"Bachelor"} key="3">
                  Bachelor
                </MenuItem>
                <MenuItem value={"Under Graduate"} key="4">
                  Under Graduate
                </MenuItem>
                <MenuItem value={"Diploma"} key="9">
                  Diploma
                </MenuItem>
                <MenuItem value={"Vocational"} key="5">
                  Vocational
                </MenuItem>
                <MenuItem value={"Secondary (A-Level)"} key="6">
                  Secondary (A-Level)
                </MenuItem>
                <MenuItem value={"Secondary (O Level)"} key="7">
                  Secondary (O Level)
                </MenuItem>
                <MenuItem value={"Primary"} key="8">
                  Primary
                </MenuItem>
              </Select>
            </Box>
            <Box sx={{ p: 2, display: "column" }}>
              <FormLabel id="maritalStatusLabel">
                <Typography sx={{ fontWeight: 700 }}>Marital Status</Typography>
              </FormLabel>
              <Select
                size="small"
                labelId="maritalstatusdropdownlabel"
                id="maritalstatusdropdownlabel"
                value={maritalstatus}
                onChange={handleMaritalStatusChange}
                sx={{ px: 2, width: "100%" }}
              >
                <MenuItem value="Single" key="1">
                  Single
                </MenuItem>
                <MenuItem value="Divorced/Widowed" key="2">
                  Divorced/Widowed
                </MenuItem>
              </Select>
            </Box>
            <Box display="flex" justifyContent="center" sx={{ p: 2 }}>
              {" "}
              <Button
                variant="contained"
                type="submit"
                sx={{ width: "80%", borderRadius: "20px" }}
              >
                {" "}
                Remove Filter{" "}
              </Button>{" "}
            </Box>
          </form>
        </SearchFilterContainer>
        <SearchCardContainer sx={{ height: "100%", ml: "50px" }}>
          <ProfileCards
            query={query}
            alignItems="start"
            sx={{ mt: "0px" }}
          ></ProfileCards>
        </SearchCardContainer>
      </SearchBodyContainer>
    </ProfileSearchContainer>
  );
}
