import "@fontsource/heebo";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AdminContainer } from "../../styles/profilesearch";
import * as React from "react";
import ColorBar from "../ColorBar";
import Messages from "./Messages";

export default function MessagesBody() {
  const theme = useTheme();
  return (
    <AdminContainer>
      <Box
        align="justify"
        display={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          lineHeight: 2,
          textAlign: "justify",
          fontSize: 20,
          fontFamily: "heebo",
          pl: 1,
          pr: 5,
          pt: 0,
          pb: 0,
        }}
      >
        <ColorBar />
        <Typography
          sx={{
            fontFamily: "heebo",
            fontSize: 30,
            fontWeight: 700,
            marginBottom: "20px",
          }}
        >
          Messages
        </Typography>
        <Messages></Messages>
      </Box>
    </AdminContainer>
  );
}
