export const educationList = [
  { code: "DR", label: "Doctoral (PhD)" },
  { code: "MASTER", label: "Master" },
  { code: "BACHELOR", label: "Bachelor" },
  { code: "DIPLOMA", label: "Diploma" },
  { code: "VOCATIONAL", label: "Vocational" },
  { code: "ALEVEL", label: "Secondary (A-Level)" },
  { code: "OLEVEL", label: "Secondary (O Level)" },
  { code: "PRIMARY", label: "Primary" },
];
