import { Box, Hidden, List, styled, Typography } from "@mui/material";
import "@fontsource/heebo";

export const LoginContainer = styled(Box)(({ theme }) => ({
  display: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  padding: "50px 10px",
  background: "#FFFFFF",
  backgroundImage: "url(../images/login/PetalsBG.png)",
  backgroundSize: "cover",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
  },
}));

export const RecaptchaContainer = styled(Box)(({ theme }) => ({
  display: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "auto",
  overflow: "visible",
  height: "auto",
  padding: "10px 0px",
  backgroundSize: "cover",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    padding: "10px 10px",
  },
}));
