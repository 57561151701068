import { Divider, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenubarDesktop from "./menubarDesktop";
import MenubarMobile from "./menubarMobile";

export default function Menubar() {
  // Give Mobile Menubar for Mobile and desktop Menubar for Desktop

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {matches ? <MenubarMobile /> : <MenubarDesktop matches={matches} />}
      <Divider></Divider>
    </>
  );
}
