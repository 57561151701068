import { ThemeProvider } from "@mui/material";
import { Container } from "@mui/system";
import Menubar from "./components/menubar";
import theme from "./styles/theme";
import Welcome from "./components/welcome";
import OurServices from "./components/ourservices";
import HowItWorks from "./components/howitworks";
import Footer from "./components/footer";
import LandingPageContainer from "./components/landingpage";
import { useState } from "react";

function LandingPage() {
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          display: "column",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Menubar></Menubar>
        <LandingPageContainer></LandingPageContainer>
        <Welcome></Welcome>
        <OurServices></OurServices>
        <HowItWorks></HowItWorks>

        <Footer></Footer>
      </Container>
    </ThemeProvider>
  );
}
export default LandingPage;
