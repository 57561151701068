import { ThemeProvider, Box, Typography, Button } from "@mui/material";
import { Container } from "@mui/system";
import Menubar from "./components/menubar";
import theme from "./styles/theme";

import Footer from "./components/footer";

function PageNotFound() {
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          display: "column",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Menubar></Menubar>
        <Box
          display="block"
          height="400px"
          justifyContent={"center"}
          alignItems="center"
          alignContent="center"
          marginY={"100px"}
          alignText="center"
        >
          <Typography variant="h2">Page Not Found</Typography>

          <Button variant="contained" href="/">
            <Typography variant="h5">Home</Typography>
          </Button>
        </Box>

        <Footer></Footer>
      </Container>
    </ThemeProvider>
  );
}
export default PageNotFound;
