import { ThemeProvider } from "@mui/material";
import { Container } from "@mui/system";
import Menubar from "./components/menubar";
import theme from "./styles/theme";
import Footer from "./components/footer";
import UserListBody from "./components/Users";

function AdminUserList() {
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          display: "column",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Menubar></Menubar>
        <UserListBody></UserListBody>
        <Footer></Footer>
      </Container>
    </ThemeProvider>
  );
}
export default AdminUserList;
