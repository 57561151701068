import {
  FooterContainer,
  FooterWrapper,
  FooterColumn,
  FooterHeading,
  FooterDetails,
  FooterNotification,
} from "../../styles/footer";
import { Box, Typography, Divider } from "@mui/material";
import Link from "@mui/material/Link";
import "@fontsource/heebo";
import Image from "../Image";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { SG } from "country-flag-icons/react/3x2";

export default function Footer() {
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterColumn>
          <FooterHeading>
            <Image src="../images/logo/Logo.png"></Image>
          </FooterHeading>
        </FooterColumn>
        <FooterColumn>
          <FooterHeading sx={{ pt: 5, pl: 5, pr: 5, pb: 0 }}>
            <Typography sx={{ fontWeight: 700, fontSize: 18 }}>
              Service Locations
            </Typography>
            <Divider sx={{ mt: 3 }} />
          </FooterHeading>
          <FooterDetails sx={{ display: "flex" }}>
            <Box width="23px">
              <SG title="Singapore" />
            </Box>
            <Typography sx={{ ml: "5px" }}>Singapore</Typography>
          </FooterDetails>
        </FooterColumn>
        <FooterColumn>
          <FooterHeading sx={{ p: 5, pl: 5, pr: 5, pb: 0 }}>
            <Typography sx={{ fontWeight: 700, fontSize: 18 }}>
              Information
            </Typography>
            <Divider sx={{ mt: 3 }} />
          </FooterHeading>
          <FooterDetails>
            <Link
              href="/aboutus"
              sx={{
                color: "black",
                textDecoration: "none",
                "&:hover": {
                  fontWeight: 700,
                  color: "#EB5D41",
                },
              }}
            >
              <Typography lineHeight={2}>About Us</Typography>
            </Link>
            <Link
              href="/marriageprep"
              sx={{
                color: "black",
                textDecoration: "none",
                "&:hover": {
                  fontWeight: 700,
                  color: "#EB5D41",
                },
              }}
            >
              <Typography lineHeight={2}>
                Marriage Preparatory Programme{" "}
              </Typography>
            </Link>
            <Link
              href="/postmarriage"
              sx={{
                color: "black",
                textDecoration: "none",
                "&:hover": {
                  fontWeight: 700,
                  color: "#EB5D41",
                },
              }}
            >
              <Typography lineHeight={2}>Post Marriage Guidance </Typography>
            </Link>
            <Link
              href="/transnational"
              sx={{
                color: "black",
                textDecoration: "none",
                "&:hover": {
                  fontWeight: 700,
                  color: "#EB5D41",
                },
              }}
            >
              <Typography lineHeight={2}>Transnational Support </Typography>
            </Link>
            <Link
              href="/terms"
              sx={{
                color: "black",
                textDecoration: "none",
                "&:hover": {
                  fontWeight: 700,
                  color: "#EB5D41",
                },
              }}
            >
              <Typography lineHeight={2}>Terms and Conditions </Typography>
            </Link>
          </FooterDetails>
        </FooterColumn>
        <FooterColumn>
          <FooterHeading sx={{ p: 5, pl: 5, pr: 5, pb: 0 }}>
            <Typography sx={{ fontWeight: 700, fontSize: 18 }}>
              Help and Support
            </Typography>
            <Divider sx={{ mt: 3 }} />
          </FooterHeading>
          <FooterDetails>
            <Link
              href="/contactus"
              sx={{
                color: "black",
                textDecoration: "none",
                "&:hover": {
                  fontWeight: 700,
                  color: "#EB5D41",
                },
              }}
            >
              <Typography lineHeight={2}>Contact Us</Typography>
            </Link>
            <Typography lineHeight={2} sx={{ fontSize: "16px" }}>
              sgmuslimmatrimony@gmail.com
            </Typography>
            <Typography display="flex" lineHeight={2} sx={{textAlign:{md:"center"}}}>
              <WhatsAppIcon sx={{ pr: "5px" }} />
              (+65)85011436
            </Typography>
          </FooterDetails>
        </FooterColumn>
      </FooterWrapper>
      <FooterNotification>
        <Typography variant="subtitle">
          This website is strictly for matrimonial purpose only and not a dating
          website.
        </Typography>
        <Typography>Copyright © 2022. All rights reserved.</Typography>
      </FooterNotification>
    </FooterContainer>
  );
}
