import * as Yup from "yup";
import { useState } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, IconButton, InputAdornment, Alert, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// hooks
import useIsMountedRef from "../../../hooks/useIsMountedRef";

// components
import Iconify from "../../../components/Iconify";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { RecaptchaContainer } from "../../../styles/login";
import { useAuth } from "../../../contexts/AuthContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from.pathname || "/";
  const [isVerified, setIsVerified] = useState(false);
  const isMountedRef = useIsMountedRef(false);
  const [showPassword, setShowPassword] = useState(false);
  const { role } = useSelector((state) => state.userPersistedDetails);
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITEKEY;
  const LoginSchema = Yup.object().shape({
    email: Yup.string().required("Username required"),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    email: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await login(data.email, data.password);
      if (role === "A") navigate("/dashboard");
      else navigate("/landingpage");
    } catch (error) {
      console.log(error);
      reset();

      setError("afterSubmit", {
        ...error,
        message: formatError(error.message),
      });
    }
  };

  function formatError(error) {
    const string = error;
    const substring = "no user";
    if (string.includes(substring)) {
      return "User Not Found";
    } else if (error.includes("password is invalid")) {
      return "You have entered incorrect Email or Password";
    } else if (error.includes("disabled")) {
      return "Your account is Inactive. Please contact administrator.";
    }
  }
  function onChangeCaptcha(value) {
    // console.log("Captcha value:", value);
    setIsVerified(true);
    //console.log("Verified?", isVerified);
  }

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      background="#FFFFF"
    >
      <Stack spacing={2}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        {/* afterSubmit
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label="First name" />
          <RHFTextField name="lastName" label="Last name" />
        </Stack> */}
        <RHFTextField name="email" label="Username" type="text" />
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RecaptchaContainer>
          <ReCAPTCHA sitekey={recaptchaKey} onChange={onChangeCaptcha} />
        </RecaptchaContainer>
        <LoadingButton
          disabled={!isVerified}
          sx={{ fontFamily: "heebo", fontWeight: 700 }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
        <Box
          display="flex"
          sx={{
            my: 4,
            mx: 2,
            color: "darkred",
            alignItems: "center",
            backgroundColor: "rgba(249, 198, 188, 0.4)",
            borderRadius: "20px",
            justifyContent: "center",
            fontSize: "15px",
          }}
        >
          Forgot Password?
          <Box sx={{ mx: 2 }}>
            <Link
              to="/changepassword"
              sx={{
                color: "white",
              }}
            >
              Click here
            </Link>
          </Box>
        </Box>
      </Stack>
    </FormProvider>
  );
}
