import {
  BannerContainer,
  BannerContent,
  CarouselContent,
  CarouselInner,
  BannerContentGroup,
} from "../../styles/banner";
import { RegisterForm } from "../../sections/auth/register";
import Carousel from "../carousel/carousel";

export default function Banner() {
  return (
    <BannerContainer>
      <BannerContentGroup>
        <CarouselContent>
          <CarouselInner>
            <Carousel></Carousel>
          </CarouselInner>
        </CarouselContent>
        <BannerContent>
          <RegisterForm />
        </BannerContent>
      </BannerContentGroup>
    </BannerContainer>
  );
}
