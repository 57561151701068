import "@fontsource/heebo";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  Button,
  styled,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ProfileSearchContainer } from "../../styles/profilesearch";
import Slider from "@mui/material/Slider";
import * as React from "react";
import { TextField } from "@mui/material";
import { Circle } from "../../styles/landingpage";
import Label from "../Label";

export default function ProfileDetailsBody() {
  const [age, setAge] = React.useState("");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const MyComponent = styled("div")({
    color: "darkslategray",
    padding: 8,
    borderRadius: 4,
    fontSize: "20px",
    alignmentBaseline: "true",
    display: "flex",
    alignItems: "center",
  });

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <ProfileSearchContainer>
      <Box
        align="justify"
        display={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          lineHeight: 2,
          textAlign: "justify",
          fontSize: 20,
          fontFamily: "heebo",
          pl: 1,
          pr: 5,
          pt: 0,
          pb: 0,
        }}
      >
        <Box
          height={12}
          width={70}
          sx={{ mt: 10, alignItems: "center" }}
          bgcolor={"#F9C6BC"}
          marginBottom={"40px"}
        ></Box>
        <Typography
          sx={{
            fontFamily: "heebo",
            fontSize: 40,
            fontWeight: 700,
            marginBottom: "40px",
          }}
        >
          Profile Details
        </Typography>
        {/* Grid 1 - Container */}
        <Grid
          container
          spacing={2}
          display="flex"
          md={12}
          sx={{ mx: 2, my: 5 }}
        >
          <Grid item md={2}>
            <Circle>JA</Circle>
          </Grid>
          <Grid item md={8} display="column">
            <Box>
              <Label
                variant="filled"
                color="error"
                sx={{
                  textTransform: "uppercase",
                  fontSize: "20px",
                  py: 1,
                  borderRadius: 3,
                  mr: 2,
                }}
              >
                Singapore Profile
              </Label>
              <Label
                variant="filled"
                color="error"
                sx={{
                  textTransform: "uppercase",
                  fontSize: "20px",
                  py: 1,
                  borderRadius: 3,
                }}
              >
                Birdegroom
              </Label>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="h3">Javed Aktar </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box
          display={"grid"}
          rowGap={1}
          columnGap={4}
          sx={{ gridTemplateColumns: "repeat(2, 1fr)" }}
        >
          <Grid
            container
            spacing={2}
            display="flex"
            md={12}
            sx={{ mx: 2, my: 5 }}
          >
            {/*  Profile ID */}
            <Grid item md={4}>
              <Label fullWidth>
                <MyComponent>Profile ID</MyComponent>
              </Label>
            </Grid>
            <Grid item md={8}>
              <TextField
                InputLabelProps={{ shrink: false }}
                disabled
                fullWidth
                selectname="displayName"
                defaultValue={"M31"}
              ></TextField>
            </Grid>
            <Grid item md={4}>
              <Label fullWidth>
                <MyComponent>Marital Status</MyComponent>
              </Label>
            </Grid>
            <Grid item md={8}>
              <TextField
                InputLabelProps={{ shrink: false }}
                disabled
                fullWidth
                defaultValue={"Single"}
              ></TextField>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            display="flex"
            md={12}
            sx={{ mx: 2, my: 5 }}
          >
            {/*  Profile ID */}
            <Grid item md={4}>
              <Label fullWidth>
                <MyComponent>Month/Year of Birth</MyComponent>
              </Label>
            </Grid>
            <Grid item md={8}>
              <TextField
                InputLabelProps={{ shrink: false }}
                disabled
                fullWidth
                defaultValue={"MMM YYYY"}
              ></TextField>
            </Grid>
            <Grid item md={4}>
              <Label fullWidth>
                <MyComponent>Height (cm)</MyComponent>
              </Label>
            </Grid>
            <Grid item md={8}>
              <TextField
                InputLabelProps={{ shrink: false }}
                disabled
                fullWidth
                defaultValue={"height in cm"}
              ></TextField>
            </Grid>
          </Grid>
        </Box>

        {/* Education and professional info */}

        <Typography variant="h4" sx={{ mt: 5 }}>
          Education and Professional Information
        </Typography>

        {/* Grid 2 - Container */}
        <Box
          align="justify"
          display={"grid"}
          rowGap={1}
          columnGap={4}
          sx={{
            gridTemplateColumns: "repeat(2, 1fr)",
            pr: 5,
            wordWrap: "break-word",
          }}
        >
          <Grid
            container
            spacing={2}
            display="flex"
            md={12}
            sx={{ mx: 2, my: 5, wordWrap: "break-word" }}
          >
            {/* Row 2 - Full Name */}
            <Grid item md={4} sx={{ wordWrap: "break-word" }}>
              <Label>
                <MyComponent>Highest Education</MyComponent>
              </Label>
            </Grid>
            <Grid item md={8}>
              <TextField
                InputLabelProps={{ shrink: false }}
                disabled
                fullWidth
                defaultValue={"Highest Level of Education"}
              ></TextField>
            </Grid>
            <Grid item md={4}>
              <Label>
                <MyComponent>Qualification</MyComponent>
              </Label>
            </Grid>
            <Grid item md={8}>
              <TextField
                InputLabelProps={{ shrink: false }}
                disabled
                fullWidth
                defaultValue={"Qualification"}
              ></TextField>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            display="flex"
            md={12}
            sx={{ mx: 2, my: 5 }}
          >
            {/* Column 2 ; Row 1 - Gender */}
            <Grid item md={4}>
              <Label fullWidth>
                <MyComponent>Profession</MyComponent>
              </Label>
            </Grid>
            <Grid item md={8}>
              <TextField
                InputLabelProps={{ shrink: false }}
                disabled
                fullWidth
                defaultValue={"Profession"}
              ></TextField>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Personal info */}

      <Typography variant="h4" sx={{ mt: 5 }}>
        Personal Information
      </Typography>
      <Box
        align="justify"
        display={"grid"}
        rowGap={1}
        columnGap={4}
        sx={{
          gridTemplateColumns: "repeat(2, 1fr)",
          pr: 5,
          wordWrap: "break-word",
        }}
      >
        <Grid
          container
          spacing={2}
          display="flex"
          md={12}
          sx={{ mx: 2, my: 5, wordWrap: "break-word" }}
        >
          {/* Column 1 - Location Info */}
          <Grid item md={4} sx={{ wordWrap: "break-word" }}>
            <Label>
              <MyComponent>Residential Status (SG)</MyComponent>
            </Label>
          </Grid>
          <Grid item md={8}>
            <TextField
              InputLabelProps={{ shrink: false }}
              disabled
              fullWidth
              defaultValue={"PR/Citizen/EP/DP/LTVP/etc.."}
            ></TextField>
          </Grid>
          <Grid item md={4}>
            <Label>
              <MyComponent>Residential Country (G)</MyComponent>
            </Label>
          </Grid>
          <Grid item md={8}>
            <TextField
              InputLabelProps={{ shrink: false }}
              disabled
              fullWidth
              defaultValue={"Residing Country"}
            ></TextField>
          </Grid>
          <Grid item md={4}>
            <Label>
              <MyComponent>Nationality</MyComponent>
            </Label>
          </Grid>
          <Grid item md={8}>
            <TextField
              InputLabelProps={{ shrink: false }}
              disabled
              fullWidth
              defaultValue={"Nationality"}
            ></TextField>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          display="flex"
          md={12}
          sx={{ mx: 2, my: 5 }}
        >
          {/* Column 2 ; Personal info */}
          <Grid item md={4}>
            <Label fullWidth>
              <MyComponent>Parent's/Guardian's Name</MyComponent>
            </Label>
          </Grid>
          <Grid item md={8}>
            <TextField
              InputLabelProps={{ shrink: false }}
              disabled
              fullWidth
              defaultValue={"Name of Parent/Guardian"}
            ></TextField>
          </Grid>
          <Grid item md={4}>
            <Label fullWidth>
              <MyComponent>Native Place</MyComponent>
            </Label>
          </Grid>
          <Grid item md={8}>
            <TextField
              InputLabelProps={{ shrink: false }}
              disabled
              fullWidth
              defaultValue={"Native Place"}
            ></TextField>
          </Grid>
          <Grid item md={4} sx={{ height: "90px" }}>
            <Label fullWidth>
              <MyComponent>Languages Known</MyComponent>
            </Label>
          </Grid>
          <Grid item md={8}>
            <TextField
              InputLabelProps={{ shrink: false }}
              disabled
              fullWidth
              selectname="displayName"
              defaultValue={"Languages Known"}
            ></TextField>
          </Grid>
        </Grid>
      </Box>
      <Typography variant="h4" sx={{ mt: 5 }}>
        Additional Information
      </Typography>
      <Box
        align="justify"
        display={"grid"}
        rowGap={1}
        columnGap={4}
        sx={{
          gridTemplateColumns: "repeat(2, 1fr)",
          pr: 5,
          wordWrap: "break-word",
        }}
      >
        <Grid
          container
          spacing={2}
          display="flex"
          md={12}
          sx={{ mx: 2, my: 5, wordWrap: "break-word" }}
        >
          {/* Column 1 - Location Info */}
          <Grid item md={4}>
            <Label>
              <MyComponent>Additional Information</MyComponent>
            </Label>
          </Grid>
          <Grid item md={8}>
            <TextField
              InputLabelProps={{ shrink: false }}
              disabled
              multiline
              rows={4}
              fullWidth
              defaultValue={"Addiitonal Information"}
            ></TextField>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          display="flex"
          md={12}
          sx={{ mx: 2, my: 5 }}
        >
          {/* Column 2 ; Contact info */}
          <Grid item md={4}>
            <Label fullWidth>
              <MyComponent>Expected Partner Country</MyComponent>
            </Label>
          </Grid>
          <Grid item md={8}>
            <TextField
              InputLabelProps={{ shrink: false }}
              disabled
              fullWidth
              defaultValue={"Country"}
            ></TextField>
          </Grid>
          <Grid item md={4}>
            <Label fullWidth>
              <MyComponent>Expected Partner Profile</MyComponent>
            </Label>
          </Grid>
          <Grid item md={8}>
            <TextField
              InputLabelProps={{ shrink: false }}
              disabled
              multiline
              rows={4}
              fullWidth
              defaultValue={"Profile details of expected partner"}
            ></TextField>
          </Grid>
        </Grid>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="10vh"
      >
        <Button
          variant="contained"
          href="/profilesearch"
          sx={{ textTransform: "none", fontWeight: 700 }}
        >
          Back to Search
        </Button>
      </Box>
    </ProfileSearchContainer>
  );
}
