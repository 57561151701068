import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { WelcomeContainer } from "../../styles/welcome";
import { RegisterForm } from "../../sections/auth/register";
import { Box } from "@mui/material";
import "@fontsource/heebo";
import { Typography } from "@mui/material";
import { HowItWorksColumn, HowItWorksWrapper } from "../../styles/welcome";
import Image from "../Image";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

export default function HowItWorks() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const styles = {
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9,
      marginTop: "30",
    },
  };
  const imgurl = "../images/howitworks/Search.png";
  return (
    <WelcomeContainer>
      <Box
        display={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          fontFamily: "heebo",
          pl: { xl:20,md:10 },
          pr: { xl:20,md:10 },
          pt: 0,
          pb: 0,
          justifyContent: "center",
        }}
      >
        <Typography
          align="center"
          sx={{
            mt: 5,
            mb: 5,
            fontFamily: "heebo",
            fontSize: 30,
            fontWeight: 700,
          }}
        >
          How it works?
        </Typography>
        {/* <Box 
                    height={12}
                    width={70}
                    sx={{ mt:10, justifyContent: 'center'}}
                    bgcolor={'#F9C6BC'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    display={'column'}
                ></Box> */}
        <Typography align="center" sx={{ fontSize: 16 }}>
          We keep it simple. You register with us and share required details.
          You will be able to browse through the profiles and request us for
          contact details. Then you proceed to start your conversation through
          your journey of marriage. In Sha Allah.
        </Typography>
      </Box>
      <HowItWorksWrapper>
        <HowItWorksColumn>
          <Image src="../images/howitworks/Register.png"></Image>
        </HowItWorksColumn>
        <HowItWorksColumn>
          <Image src="../images/howitworks/Search.png"></Image>
        </HowItWorksColumn>
        <HowItWorksColumn>
          <Image src="../images/howitworks/Request.png"></Image>
        </HowItWorksColumn>
        <HowItWorksColumn>
          <Image src="../images/howitworks/Converse.png"></Image>
        </HowItWorksColumn>
      </HowItWorksWrapper>
    </WelcomeContainer>
  );
}
