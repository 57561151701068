import { Typography, useMediaQuery, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { WelcomeContainer } from "../../styles/welcome";
// import { RegisterForm } from "../../sections/auth/register";
import "@fontsource/heebo";
import Image from "../Image";
import ReactPlayer from "react-player";

export default function AboutUsBody() {
  const theme = useTheme();

  return (
    <WelcomeContainer>
      <Box
        align="justify"
        display={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          lineHeight: 2,
          textAlign: "justify",
          fontSize: 16,
          fontFamily: "heebo",
          pl: { md: 8, xl: 10 },
          pr: { md: 8, xl: 10 },
          pt: 0,
          pb: 0,
        }}
      >
        <Box width="100px">
          <Image src="../images/aboutus/RosePetalsTop.png"></Image>
        </Box>
        <Box
          height={8}
          width={50}
          sx={{ mt: "10px" }}
          bgcolor={"#F9C6BC"}
          marginX={"45%"}
          marginBottom={"20px"}
        ></Box>
        <Typography
          textAlign={"center"}
          sx={{
            fontFamily: "heebo",
            fontSize: 30,
            fontWeight: 700,
            marginBottom: "40px",
          }}
        >
          About Us
        </Typography>

        <Typography
          variant="subtitle"
          textAlign={"justify"}
          justifyContent={"center"}
        >
          <p>
            Marriage is a blessing bestowed upon us by Allah (SWT) to mankind.
            Quran emphasis the importance of marriage in several verses. It acts
            as a barrier between Halal & haram. Al Quran (51: 49), Allah says, “
            And of Everything we created a Pair, That perhaps you may Remember”{" "}
          </p>
        </Typography>
        <Typography variant="subtitle">
          <p>
            It is said that When a person get married, he/she completes half of
            his/her Deen.
          </p>
        </Typography>
        <Typography variant="subtitle">
          <p>
            By the blessings of Allah, Our humble Journey in Matrimony started
            since 6 yrs ago with the best interest to connect people & to
            introduce available Bride & BrideGroom in our community. When the
            pandamic strike, the necessity grew for us to share information only
            through Zoom Session in the most accepted Islamic way as possible.
            May Allah guide us.
          </p>
        </Typography>
        <Typography variant="subtitle">
          <p>
            In Sha Allah, as per the demand our network is able to connect Bride
            & Groom globally.
          </p>
        </Typography>

        {/* <Box display="flex" justifyContent="center" sx={{ mt: "100px" }}>
          <ReactPlayer
            controls
            url="" //To update the actual video
          ></ReactPlayer>
        </Box> */}
        <Box display="flex" sx={{ justifyContent: "flex-end", mt: 10 }}>
          <Image src="../images/aboutus/RosePetalsBottom.png"></Image>
        </Box>
      </Box>
    </WelcomeContainer>
  );
}
