import { Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
// import { ThemeProvider } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import { useForm } from "react-hook-form";
import {
  MyProfilePage,
  ProfileSearchContainer,
} from "../../styles/profilesearch";
// import theme from "../../styles/theme";
import Footer from "../footer";
import Label from "../Label";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  yearList,
  monthList,
  educationList,
  residentialStatusList,
  countries,
  languages,
} from "../../_mock/map";
import { useState, useEffect } from "react";
import Notification from "../Notification";
import axios from "axios";
import { format } from "date-fns";
import { useNavigate, useLocation } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { Dayjs } from "dayjs";
import ColorBar from "../ColorBar";
import { useSelector } from "react-redux";
import api from "../../services/RegisterService";

function CreateNewProfile() {
  const [isprofilesg, setIsprofilesg] = useState(true);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [registrationDate, setRegistrationDate] = useState(null);
  const location = useLocation();
  const { pathname } = useLocation();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });
  let navigate = useNavigate();
  const { name, userId, token } = useSelector(
    (state) => state.userPersistedDetails
  );

  const RegisterSchema = Yup.object().shape({
    userId: Yup.string().required("Profile Id is required"),
    profilename: Yup.string()
      .required("Full Name is required")
      .max(250, "Maximum 250 characters"),
    gender: Yup.string().required("Select the Gender"),
    marriageStatus: Yup.string().required("Select the Marital Status"),
    monthOfBirth: Yup.string().required("Select the Month of Birth"),
    yearOfBirth: Yup.string().required("Select the Year of Birht"),
    profileCountry: Yup.string().required("Select the Profile Country"),
    citizenShipCountry: Yup.string().required("Select the Citizenship Country"),
    parentName: Yup.string().required("Enter Parent/Guardian Name"),
    heightInCm: Yup.string().required("Height is required"),
    primaryContact: Yup.string()
      .required("Enter Main Contact No.")
      .matches(/^\+[1-9]{1}[0-9]{3,14}$/, "Phone number is not valid"),
    emailId: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues: {
      userId: location.state.selectedUserId.userId,
      profilename: "",
      gender: "",
      marriageStatus: "",
      dateOfBirth: "",
      heightInCm: "",
      heightInch: "",
      education: "",
      educationLevel: "",
      profession: "",
      profileCountry: "",
      sgResidentialStatus: "",
      residingCountry: "",
      citizenShipCountry: "",
      parentName: location.state.selectedUserId.userName,
      nativePlace: "",
      //languagesKnown: "",
      primaryContact: location.state.selectedUserId.phoneNumber,
      secondaryContact: "",
      emailId: location.state.selectedUserId.userEmail,
      additionalInfo: "",
      registrationDate: "",
      partnerCountryPreference: "",
      partnerProfilePreference: "",
      disclaimAcceptanceStatus: "",
      submissionStatus: "",
      status: "",
      createdUser: "",
    },
  });

  const profileCountryChange = (event) => {
    if (event.target.value === "SGP") {
      setIsprofilesg(true);
    } else if (event.target.value === "G") {
      setIsprofilesg(false);
    }
  };

  const languagesKnownChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLanguages(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  function toFeet(n) {
    var realFeet = (n * 0.3937) / 12;
    var feet = Math.floor(realFeet);
    var inches = Math.round((realFeet - feet) * 12);
    return feet + "'" + " " + inches;
  }

  const onSubmitDraft = (data) => {
    saveProfile(data, "D", "I");
  };

  const onSubmitActivate = (data) => {
    saveProfile(data, "S", "A");
  };
  async function saveProfile(data, flag, pStatus) {
    try {
      let headers = { authorization: `${token}` };
      const response = await api.post(
        "/profiledetails",
        {
          userId: data.userId,
          profilename: data.profilename,
          gender: data.gender,
          marriageStatus: data.marriageStatus,
          dateOfBirth: data.yearOfBirth + "-" + data.monthOfBirth + "-01",
          heightInCm: data.heightInCm,
          heightInch: toFeet(data.heightInCm),
          education: data.education,
          educationLevel: data.educationLevel,
          profession: data.profession,
          profileCountry: data.profileCountry,
          sgResidentStatus: data.sgResidentialStatus,
          residingCountry: data.residingCountry,
          citizenShipCountry: data.citizenShipCountry,
          parentName: data.parentName,
          nativePlace: data.nativePlace,
          languagesKnown: data.languagesKnown.toString(),
          primaryContact: data.primaryContact,
          secondaryContact: data.secondaryContact,
          emaiId: data.emailId,
          additionalInfo: data.additionalInfo,
          registrationDate: format(registrationDate.$d, "yyyy-MM-dd"),
          partnerCountryPreference: data.partnerCountryPreference,
          partnerProfilePreference: data.partnerProfilePreference,
          disclaimAcceptanceStatus: "Y",
          submissionStatus: flag,
          status: pStatus,
          createdUser: userId,
        },
        { headers: headers }
      );
      setNotify({
        isOpen: true,
        message:
          flag === "D"
            ? "Profile Saved as Draft"
            : flag === "S"
            ? "Profile Submitted Successfully"
            : "",
        type: "success",
      });
      navigate("/userlist", {
        state: { data },
      });
      return response.data;
    } catch (err) {
      console.log(err);
      setNotify({
        isOpen: true,
        message: "Profile Submission Failed. " + err.response.data,
        type: "error",
      });
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <ProfileSearchContainer>
      <form>
        <MyProfilePage>
          <Box
            align="justify"
            display={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              lineHeight: 2,
              textAlign: "justify",
              fontSize: 20,
              fontFamily: "heebo",
              pl: 1,
              pr: 5,
              pt: 0,
              pb: "45px",
              marginTop: 2,
            }}
          >
            <ColorBar> </ColorBar>
            <Typography
              sx={{
                fontFamily: "heebo",
                fontSize: 30,
                fontWeight: 700,
                marginBottom: "10px",
              }}
            >
              New Profile
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              For Parent/Guardian: {location.state.selectedUserId.userName}
            </Typography>
            {/* profile section start  */}
            <Typography
              typography
              variant="h4"
              sx={{ mt: 5, fontSize: "25px" }}
            >
              Profile Details
            </Typography>
            <Grid
              spacing={5}
              container
              item
              sm={12}
              sx={{ marginTop: "-25px" }}
            >
              {/* profile id field  */}
              <Grid rowSpacing={2} columnSpacing={5} item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>
                    User ID <span style={{ color: "#D6396B" }}>*</span>
                  </span>
                </Label>
                <TextField
                  inputlabelprops={{ shrink: false }}
                  disabled
                  fullWidth
                  name="userId"
                  defaultValue=""
                  {...register("userId")}
                  error={!!errors?.userId}
                  helperText={errors?.userId ? errors.userId.message : null}
                ></TextField>
              </Grid>
              {/* marital status */}
              <Grid rowSpacing={2} columnSpacing={5} item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>
                    Marital Status <span style={{ color: "#D6396B" }}>*</span>
                  </span>
                </Label>

                <TextField
                  inputlabelprops={{ shrink: false }}
                  fullWidth
                  select
                  name="marriageStatus"
                  defaultValue=""
                  {...register("marriageStatus")}
                  error={!!errors?.marriageStatus}
                  helperText={
                    errors?.marriageStatus
                      ? errors.marriageStatus.message
                      : null
                  }
                >
                  <MenuItem key="1" value="Single">
                    Single
                  </MenuItem>
                  <MenuItem key="2" value="Divorced/Widowed">
                    Divorced/Widowed
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid rowSpacing={2} columnSpacing={5} item container>
                {/* full name field */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>
                      Full Name <span style={{ color: "#D6396B" }}>*</span>
                    </span>
                  </Label>

                  <TextField
                    inputlabelprops={{ shrink: false }}
                    fullWidth
                    name="profilename"
                    defaultValue=""
                    {...register("profilename")}
                    error={!!errors?.profilename}
                    helperText={
                      errors?.profilename ? errors.profilename.message : null
                    }
                  ></TextField>
                </Grid>
                {/* month/year of birth field */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>
                      Month/Year of Birth{" "}
                      <span style={{ color: "#D6396B" }}>*</span>
                    </span>
                  </Label>
                  <Grid columnSpacing={3} container>
                    <Grid item lg={6} md={6}>
                      <TextField
                        inputlabelprops={{ shrink: false }}
                        fullWidth
                        select
                        name="monthOfBirth"
                        defaultValue=""
                        {...register("monthOfBirth")}
                        error={!!errors?.monthOfBirth}
                        helperText={
                          errors?.monthOfBirth
                            ? errors.monthOfBirth.message
                            : null
                        }
                      >
                        {monthList.map((option, index) => (
                          <MenuItem
                            sx={{ height: "40px" }}
                            key={index}
                            value={option.code}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <TextField
                        inputlabelprops={{ shrink: false }}
                        fullWidth
                        select
                        name="yearOfBirth"
                        defaultValue=""
                        {...register("yearOfBirth")}
                        error={!!errors?.yearOfBirth}
                        helperText={
                          errors?.yearOfBirth
                            ? errors.yearOfBirth.message
                            : null
                        }
                      >
                        {yearList.map((option, index) => (
                          <MenuItem
                            sx={{ height: "40px" }}
                            key={index}
                            value={option.code}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Gender field */}
                <Grid rowSpacing={2} columnSpacing={5} container item sm={12}>
                  <Grid item md={6} lg={6} sm={12}>
                    <Label>
                      <span style={{ fontSize: "15px" }}>
                        Gender <span style={{ color: "#D6396B" }}>*</span>
                      </span>
                    </Label>
                    <TextField
                      inputlabelprops={{ shrink: false }}
                      fullWidth
                      select
                      name="gender"
                      defaultValue=""
                      {...register("gender")}
                      error={!!errors?.gender}
                      helperText={errors?.gender ? errors.gender.message : null}
                    >
                      <MenuItem sx={{ height: "40px" }} key="F" value="F">
                        Bride
                      </MenuItem>
                      <MenuItem sx={{ height: "40px" }} key="M" value="M">
                        Bridegroom
                      </MenuItem>
                    </TextField>
                  </Grid>
                  {/* Height (cm) field */}
                  <Grid item md={6} lg={6} sm={12}>
                    <Label>
                      <span style={{ fontSize: "15px" }}>
                        Height (cm) <span style={{ color: "#D6396B" }}>*</span>
                      </span>
                    </Label>

                    <TextField
                      inputlabelprops={{ shrink: false }}
                      fullWidth
                      name="heightInCm"
                      defaultValue=""
                      {...register("heightInCm")}
                      error={!!errors?.heightInCm}
                      helperText={
                        errors?.heightInCm ? errors.heightInCm.message : null
                      }
                    ></TextField>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* profile section end  */}
            {/* eduction section start */}
            <Typography sx={{ mt: 7, fontSize: "25px" }}>
              Education and Professional Information
            </Typography>
            <Grid
              spacing={5}
              container
              item
              sm={12}
              sx={{ marginTop: "-25px" }}
            >
              {/* Highest Education field  */}
              <Grid rowSpacing={2} columnSpacing={5} item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>Highest Education</span>
                </Label>
                <TextField
                  inputlabelprops={{ shrink: false }}
                  fullWidth
                  select
                  name="education"
                  defaultValue=""
                  {...register("education")}
                >
                  {educationList.map((option, index) => (
                    <MenuItem
                      sx={{ height: "40px" }}
                      key={index}
                      value={option.code}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* profession field */}
              <Grid rowSpacing={2} columnSpacing={5} item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>Profession</span>
                </Label>

                <TextField
                  inputlabelprops={{ shrink: false }}
                  fullWidth
                  name="profession"
                  defaultValue=""
                  {...register("profession")}
                ></TextField>
              </Grid>
              <Grid rowSpacing={2} columnSpacing={5} item container>
                {/* Qualification field */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>Qualification</span>
                  </Label>

                  <TextField
                    inputlabelprops={{ shrink: false }}
                    name="educationLevel"
                    fullWidth
                    {...register("educationLevel")}
                    defaultValue=""
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
            {/* eduction section end */}
            {/* Personal Information section start */}
            <Typography sx={{ mt: 7, fontSize: "25px" }}>
              Personal Information
            </Typography>
            <Grid
              spacing={5}
              container
              item
              sm={12}
              sx={{ marginTop: "-25px" }}
            >
              {/* Profile Country field  */}
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>
                    Profile Country <span style={{ color: "#D6396B" }}>*</span>
                  </span>
                </Label>
                <TextField
                  inputlabelprops={{ shrink: false }}
                  fullWidth
                  select
                  name="profileCountry"
                  {...register("profileCountry")}
                  onChange={profileCountryChange}
                  defaultValue=""
                  error={!!errors?.profileCountry}
                  helperText={
                    errors?.profileCountry
                      ? errors.profileCountry.message
                      : null
                  }
                >
                  <MenuItem sx={{ height: "40px" }} key="1" value="SGP">
                    Singapore
                  </MenuItem>
                  <MenuItem sx={{ height: "40px" }} key="2" value="G">
                    Global
                  </MenuItem>
                </TextField>
              </Grid>
              {/* Parent's/Guardian's Name field */}
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>
                    Parent's/Guardian's Name{" "}
                    <span style={{ color: "#D6396B" }}>*</span>
                  </span>
                </Label>

                <TextField
                  inputlabelprops={{ shrink: false }}
                  fullWidth
                  name="parentName"
                  {...register("parentName")}
                  error={!!errors?.parentName}
                  helperText={
                    errors?.parentName ? errors.parentName.message : null
                  }
                ></TextField>
              </Grid>
            </Grid>
            <Grid
              spacing={5}
              container
              item
              sm={12}
              sx={{ marginTop: "-25px" }}
            >
              {/* Residential Status (SG) field  */}
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>
                    Residential Status (SG)
                  </span>
                </Label>
                <TextField
                  inputlabelprops={{ shrink: false }}
                  fullWidth
                  select
                  name="sgResidentialStatus"
                  hidden={!isprofilesg}
                  {...register("sgResidentialStatus")}
                  error={!!errors?.sgResidentialStatus}
                  helperText={
                    errors?.sgResidentialStatus
                      ? errors.sgResidentialStatus.message
                      : null
                  }
                >
                  {residentialStatusList.map((option, index) => (
                    <MenuItem
                      sx={{ height: "40px" }}
                      key={index}
                      value={option.code}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* Native Place field */}
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>Native Place</span>
                </Label>

                <TextField
                  inputlabelprops={{ shrink: false }}
                  fullWidth
                  name="nativePlace"
                  defaultValue=""
                  {...register("nativePlace")}
                ></TextField>
              </Grid>
            </Grid>
            <Grid
              spacing={5}
              container
              item
              sm={12}
              sx={{ marginTop: "-25px" }}
            >
              {/* Citizenship Country(SG) field  */}
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>
                    Citizenship Country{" "}
                    <span style={{ color: "#D6396B" }}>*</span>
                  </span>
                </Label>
                <TextField
                  inputlabelprops={{ shrink: false }}
                  fullWidth
                  name="citizenShipCountry"
                  select
                  defaultValue=""
                  {...register("citizenShipCountry")}
                  error={!!errors?.citizenShipCountry}
                  helperText={
                    errors?.citizenShipCountry
                      ? errors.citizenShipCountry.message
                      : null
                  }
                >
                  {countries.map((option, index) => (
                    <MenuItem
                      sx={{ height: "40px" }}
                      key={index}
                      value={option.code}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* Languages Known field */}
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>Languages Known</span>
                </Label>

                <Select
                  // inputlabelprops={{ shrink: false }}
                  fullWidth
                  multiple
                  name="languagesKnown"
                  {...register("languagesKnown")}
                  onChange={languagesKnownChange}
                  value={selectedLanguages}
                  displayEmpty
                >
                  {languages.map((option, index) => (
                    <MenuItem
                      sx={{ height: "35px" }}
                      key={index}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>
                    Residential Country (G)
                  </span>
                </Label>

                <TextField
                  // inputlabelprops={{ shrink: false }}
                  inputlabelprops={{ shrink: false }}
                  fullWidth
                  select
                  name="residingCountry"
                  {...register("residingCountry")}
                  defaultValue=""
                  id="residingCountry-dropdown"
                >
                  {countries.map((option1) => (
                    <MenuItem
                      // sx={{ height: "40px" }}
                      key={option1.code}
                      value={option1.code}
                    >
                      {option1.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            {/* Personal Information section end */}
            {/* contact section start */}
            <Typography sx={{ mt: 7, fontSize: "25px" }}>
              Contact Information
            </Typography>
            <Grid
              spacing={5}
              container
              item
              sm={12}
              sx={{ marginTop: "-25px" }}
            >
              {/* Main Contact No. field  */}
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>
                    Main Contact No. <span style={{ color: "#D6396B" }}>*</span>
                  </span>
                </Label>
                <TextField
                  disabled
                  inputlabelprops={{ shrink: false }}
                  fullWidth
                  name="primaryContact"
                  defaultValue=""
                  {...register("primaryContact")}
                  error={!!errors?.primaryContact}
                  helperText={
                    errors?.primaryContact
                      ? errors.primaryContact.message
                      : null
                  }
                ></TextField>
              </Grid>
              {/* Alt. Contact No. field */}
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>Alt. Contact No.</span>
                </Label>

                <TextField
                  inputlabelprops={{ shrink: false }}
                  fullWidth
                  name="secondaryContact"
                  defaultValue=""
                  {...register("secondaryContact")}
                ></TextField>
              </Grid>
              <Grid spacing={5} item container>
                {/* Email Id field */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>
                      Email Id <span style={{ color: "#D6396B" }}>*</span>
                    </span>
                  </Label>

                  <TextField
                    disabled
                    inputlabelprops={{ shrink: false }}
                    fullWidth
                    name="emailId"
                    defaultValue=""
                    {...register("emailId")}
                    error={!!errors?.emailId}
                    helperText={errors?.emailId ? errors.emailId.message : null}
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
            {/* contact section end */}
            {/* contact section start */}
            <Typography sx={{ mt: 7, fontSize: "25px" }}>
              Additional Information
            </Typography>
            <Grid
              spacing={5}
              container
              item
              sm={12}
              sx={{ marginTop: "-25px" }}
            >
              {/* Additional Information field  */}
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>
                    Additional Information
                  </span>
                </Label>
                <TextField
                  inputlabelprops={{ shrink: false }}
                  multiline
                  rows={4}
                  fullWidth
                  name="additionalInfo"
                  defaultValue="Additional Information should be here"
                  {...register("additionalInfo")}
                ></TextField>
              </Grid>
              {/* Alt. Contact No. field */}
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>
                    Expected Partner Profile
                  </span>
                </Label>

                <TextField
                  inputlabelprops={{ shrink: false }}
                  multiline
                  rows={4}
                  fullWidth
                  name="partnerProfilePreference"
                  defaultValue="Additional information about the partner should be here"
                  {...register("partnerProfilePreference")}
                ></TextField>
              </Grid>
              <Grid spacing={5} item container>
                {/* Registration Date field */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>
                      Registration Date{" "}
                      <span style={{ color: "#D6396B" }}>*</span>
                    </span>
                  </Label>

                  {/* <TextField
                                            inputlabelprops={{ shrink: false }}
                                            fullWidth
                                            name="registrationDate"
                                            defaultValue="2022-08-13"
                                            {...register("registrationDate")}
                                        ></TextField> */}
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputlabelprops={{ shrink: false }}
                        fullWidth
                        name="registrationDate"
                        value={registrationDate}
                        onChange={(newValue) => {
                          setRegistrationDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>
                      Expected Partner Country
                    </span>
                  </Label>

                  <TextField
                    inputlabelprops={{ shrink: false }}
                    fullWidth
                    name="partnerCountryPreference"
                    defaultValue="Singapore"
                    {...register("partnerCountryPreference")}
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="10vh"
          >
            <LoadingButton
              onClick={handleSubmit(onSubmitDraft)}
              className="submit-button"
              size="large"
              type="submit"
              variant="contained"
              id="submitButton1"
              sx={{
                color: "#000000",
                backgroundColor: "#f9c6bc",
                mx: 2,
                "&:hover": {
                  color: "#000000",
                  backgroundColor: "#f9c6bc",
                },
              }}
            >
              Save as Draft
            </LoadingButton>
            <LoadingButton
              onClick={handleSubmit(onSubmitActivate)}
              className="submit-button"
              size="large"
              type="submit"
              variant="contained"
              id="submitButton2"
              sx={{
                color: "#000000",
                backgroundColor: "#f9c6bc",
                mx: 2,
                "&:hover": {
                  color: "#000000",
                  backgroundColor: "#f9c6bc",
                },
              }}
            >
              Save and Activate
            </LoadingButton>
          </Box>
        </MyProfilePage>
      </form>
      <Footer></Footer>

      <Notification notify={notify} setNotify={setNotify} />
    </ProfileSearchContainer>
  );
}

export default CreateNewProfile;
