import { Paper, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  RegisterContainer,
  RegisterInnerContainer,
} from "../../styles/register";
import { RegisterFullForm } from "../../sections/auth/register";
import { Box } from "@mui/material";
import "@fontsource/heebo";
import Image from "../Image";
import Container from "@mui/material/Container";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";
import ColorBar from "../ColorBar";
import ChangePasswordForm from "../../sections/auth/changepassword/ChangePasswordForm";

export default function ChangePasswordBody(data) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <RegisterContainer>
      <RegisterInnerContainer>
        <Box
          align="justify"
          display={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            lineHeight: 2,
            textAlign: "justify",
            fontSize: 20,
            fontFamily: "heebo",
            pl: 20,
            pr: 10,
            pt: 0,
            pb: 0,
          }}
        >
          <ColorBar />
          <Typography
            sx={{
              fontFamily: "heebo",
              fontSize: 30,
              fontWeight: 700,
              marginBottom: "40px",
            }}
          >
            Change Password
          </Typography>

          <Box sx={{ backgroundColor: "none" }}>
            <ChangePasswordForm />
          </Box>

          {/* <Box display="flex" sx={{ justifyContent: "flex-end", mt: 10 }}></Box> */}
        </Box>
      </RegisterInnerContainer>
      <RegisterInnerContainer
        display={"grid"}
        grid-template-columns="repeat(4, auto)"
        justify-content="start"
      >
        {/* <Image src="../images/register/roses.png"></Image> */}
      </RegisterInnerContainer>
    </RegisterContainer>
  );
}
