import { useEffect } from "react";
import Home from "./Home";
// release 1
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AboutUs from "./AboutUs";
import Login from "./Login";
import ContactUs from "./contactus";
import TransNational from "./TransNational";
import MarriagePrep from "./MarriagePrep";
import PageNotFound from "./PageNotFound";
import PostMarriage from "./PostMarriage";
// release 2
import PrivateRoute from "./components/PrivateRoute";
import AdminPrivateRoute from "./components/AdminPrivateRoute";
import LandingPage from "./LandingPage";
import ProfileSearch from "./ProfileSearch";
import MyProfile from "./MyProfile";
import ProfileDetails from "./ProfileDetails";
import AdminUserList from "./AdminUserList";
import AdminProfileList from "./AdminProfileList";
import Register from "./Register";
import AdminRegistrationList from "./AdminRegistrationList";
import AdminNewProfile from "./AdminNewProfile";
import Layout from "./components/Layout";
import Dashboard from "./Dashboard";
import ViewProfile from "./ViewProfile";
import EditprofilePage from "./components/profiledetails/EditProfilePage";
import AdminEditUser from "./AdminEditUser";
import AdminMessages from "./AdminMessages";
import ChangePassword from "./ChangePassword";
import Terms from "./Terms";
// import ChangePassword from "./ChangePassword";

function App() {
  useEffect(() => {
    document.title = "SG Muslim Matrimony - Home";
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Public Routes */}
        <Route path="/" element={<Home />}></Route>
        <Route path="/aboutus" element={<AboutUs />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/contactus" element={<ContactUs />}></Route>
        <Route path="/transnational" element={<TransNational />}></Route>
        <Route path="/marriageprep" element={<MarriagePrep />}></Route>
        <Route path="/postmarriage" element={<PostMarriage />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/terms" element={<Terms />}></Route>
        <Route path="/changepassword" element={<ChangePassword />}></Route>

        {/* release2 */}
        {/* Protected Routes for Users with role ="U" */}
        <Route element={<PrivateRoute />}>
          <Route path="/landingpage" element={<LandingPage />} />
          <Route path="/profilesearch" element={<ProfileSearch />}></Route>
          <Route path="/viewprofile" element={<ViewProfile />}></Route>
          <Route path="/profiledetails" element={<ProfileDetails />}></Route>
          <Route path="/myprofile" element={<MyProfile />}></Route>
        </Route>

        {/* release2 */}
        {/* Protected Routes for Admin with role = "A" */}
        <Route element={<AdminPrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/userlist" element={<AdminUserList />}></Route>
          <Route path="/profilelist" element={<AdminProfileList />}></Route>
          <Route path="/reglist" element={<AdminRegistrationList />}></Route>
          <Route path="/messages" element={<AdminMessages />}></Route>
          <Route path="/newprofile" element={<AdminNewProfile />}></Route>
          <Route path="/editprofile" element={<EditprofilePage />}></Route>
          <Route path="/edituser" element={<AdminEditUser />}></Route>
        </Route>

        {/* Page Not Found */}
        <Route path="*" element={<PageNotFound />}></Route>
      </Route>
    </Routes>
  );
}

export default App;
