import { Typography, useMediaQuery, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { WelcomeContainer } from "../../styles/welcome";
import "@fontsource/heebo";
import Image from "../Image";
import Link from "@mui/material/Link";

export default function MarriagePrepBody() {
  const theme = useTheme();

  return (
    <WelcomeContainer>
      <Box
        align="justify"
        display={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          lineHeight: 2,
          textAlign: "justify",
          fontSize: 16,
          fontFamily: "heebo",
          pl: { md: 8, xl: 10 },
          pr: { md: 8, xl: 10 },
          pt: 0,
          pb: 0,
        }}
      >
        <Box width="100px">
          <Image src="../images/marriageprep/RosePetals_top2.png"></Image>
        </Box>
        <Box
          height={8}
          width={50}
          sx={{ mt: "10px" }}
          bgcolor={"#F9C6BC"}
          marginX={"45%"}
          marginBottom={"20px"}
        ></Box>
        <Typography
          textAlign={"center"}
          sx={{
            fontFamily: "heebo",
            fontSize: 30,
            fontWeight: 700,
            marginBottom: "40px",
          }}
        >
          Marriage Preparation Programme in Singapore
        </Typography>

        <Typography
          variant="subtitle"
          textAlign={"justify"}
          justifyContent={"center"}
        >
          Preparing for a wedding is merely the beginning of preparing for a
          marriage. As much as wedding preparations take time and effort, so
          does marriage. A good marriage needs a good foundation and it takes
          commitment, effort and skills.
          <Typography variant="subtitle" display="block" sx={{ mt: 4, mb: 4 }}>
            Marriage preparation programmes will help you understand issues
            relating to marriage and learn skills that help prepare for life
            together as husband and wife
          </Typography>
          <Typography variant="subtitle" display="block">
            For more Info, visit the link below:
          </Typography>
          <Typography variant="subtitle" display="block">
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://www.marriage.gov.sg/"
              color="secondary"
            >
              https://www.marriage.gov.sg/
            </Link>
          </Typography>
          <Typography variant="subtitle" display="block">
            <Link
              color="secondary"
              target="_blank"
              rel="noreferrer"
              href="https://www.msf.gov.sg/policies/Marriages/Pages/Marriage-Counselling.aspx"
            >
              https://www.msf.gov.sg/policies/Marriages/Pages/Marriage-Counselling.aspx
            </Link>
          </Typography>
          <Typography variant="subtitle" display="block">
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://www.artofmarriage.sg/"
              color="secondary"
            >
              https://www.artofmarriage.sg/
            </Link>
          </Typography>
          <Typography variant="subtitle" display="block">
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://www.msf.gov.sg/policies/Marriages/Preparing-for-Marriage/cinta-abadi/Pages/default.aspx"
              color="secondary"
            >
              https://www.msf.gov.sg/policies/Marriages/Preparing-for-Marriage/cinta-abadi/Pages/default.aspx{" "}
            </Link>{" "}
          </Typography>
        </Typography>
        <Box display="flex" sx={{ justifyContent: "flex-end", mt: 10 }}>
          <Image src="../images/marriageprep/RosePetals_bottom2.png"></Image>
        </Box>
      </Box>
    </WelcomeContainer>
  );
}
