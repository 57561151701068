import { configureStore } from "@reduxjs/toolkit";
import filterSlice from "../features/filterSlice";
import userSlice from "../features/userSlice";
import myProfileSlice from "../features/myProfileSlice";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'userDetails-store',
  storage,
}

const persistedReducer = persistReducer(persistConfig, userSlice)

export const store = configureStore({
  reducer: {
    filter: filterSlice,
    myProfile: myProfileSlice,
    userProfile: userSlice,
    userPersistedDetails: persistedReducer
  },
  middleware: [thunk]
});

export const persistor = persistStore(store);
