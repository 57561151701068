import { Typography, useMediaQuery, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { WelcomeContainer } from "../../styles/welcome";
import "@fontsource/heebo";
import Image from "../Image";
import Link from "@mui/material/Link";

export default function PostMarriageBody() {
  const theme = useTheme();

  return (
    <WelcomeContainer>
      <Box
        align="justify"
        display={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          lineHeight: 2,
          textAlign: "justify",
          fontSize: 16,
          fontFamily: "heebo",
          pl: { md: 8, xl: 10 },
          pr: { md: 8, xl: 10 },
          pt: 0,
          pb: 0,
        }}
      >
        <Box width="100px">
          <Image src="../images/marriageprep/RosePetals_top2.png"></Image>
        </Box>
        <Box
          height={8}
          width={50}
          sx={{ mt: "10px" }}
          bgcolor={"#F9C6BC"}
          marginX={"45%"}
          marginBottom={"20px"}
        ></Box>
        <Typography
          textAlign={"center"}
          sx={{
            fontFamily: "heebo",
            fontSize: 30,
            fontWeight: 700,
            marginBottom: "40px",
          }}
        >
          Post Marriage Guidance
        </Typography>

        <Typography
          variant="subtitle"
          textAlign={"justify"}
          justifyContent={"center"}
        >
          Marriage is not an easy process. It is a lifetime journey which may
          have Marital problems due to various reasons like communication
          breakdown, differing expectations, financial pressure, work stress,
          third party involvement or parental issues. Relationship do not fall
          apart overnight. The longer the problem prolongs, the more the
          relationship becomes strained. If there are children in the marriage,
          they can be impacted if the parents are not able to solve their
          marital issues.
          <Typography variant="subtitle" display="block" sx={{ mt: 4, mb: 4 }}>
            We have provided links & articles which may provide additional
            support for your relationship, whether you are adjusting to the life
            of newlyweds or tackling long winding problems in your marriage.
          </Typography>
        </Typography>
        <Typography variant="subtitle" display="block">
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.syariahcourt.gov.sg/Who-We-Are/Vision-Mission-and-Philosophy"
            color="secondary"
          >
            Syariah Court Singapore
          </Link>
        </Typography>
        <Typography variant="subtitle" display="block">
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.amp.org.sg/counselling/"
            color="secondary"
          >
            AMP Singapore
          </Link>
        </Typography>
        <Typography variant="subtitle" display="block">
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.msf.gov.sg/FAMatFSC/Pages/Strengthening-Families-Programme.aspx"
            color="secondary"
          >
            Family support & Service Providers
          </Link>
        </Typography>
        <Typography variant="subtitle" display="block">
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://ppis.sg/family-services/"
            color="secondary"
          >
            PPIS (The Singapore Muslim Women's Association)
          </Link>
        </Typography>
        <Typography variant="subtitle" display="block">
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.cphonlinecounselling.sg/hc/en-us"
            color="secondary"
          >
            Community Psychology Hub
          </Link>
        </Typography>
        <Typography variant="subtitle" display="block">
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.ncss.gov.sg/"
            color="secondary"
          >
            National Council for Social Services
          </Link>
        </Typography>
        <Typography variant="subtitle" display="block">
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.darul-arqam.org.sg/"
            color="secondary"
          >
            Darul Arqam Singapore
          </Link>
        </Typography>
        <Typography variant="subtitle" display="block">
          <Link
            target="_blank"
            rel="noreferrer"
            href="http://www.muis.gov.sg/"
            color="secondary"
          >
            MUIS
          </Link>
        </Typography>
        <Typography variant="subtitle" display="block">
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://muhammadiyah.org.sg/"
            color="secondary"
          >
            Muhammadiyah Association
          </Link>
        </Typography>
        <p></p>
        <Typography variant="subtitle" display="block">
          Other Links:
        </Typography>
        <Typography variant="subtitle" display="block">
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.madeforfamilies.gov.sg/about-us/made-for-families"
            color="secondary"
          >
            Made for Families
          </Link>
        </Typography>
        <Typography variant="subtitle" display="block">
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.family-central.sg/"
            color="secondary"
          >
            Family Central
          </Link>
        </Typography>

        <Typography variant="subtitle" display="block">
          Articles on Post Marriage Guidance:
        </Typography>
        <Typography variant="subtitle" display="block">
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.yasminmogahed.com/category/relationships/marriage-family/"
            color="secondary"
          >
            Yasmin Mogahed Articles
          </Link>
        </Typography>
        <Typography variant="subtitle" display="block">
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.marriage.com/advice/relationship/best-advice-for-newlyweds/"
            color="secondary"
          >
            Advice for Newlyweds
          </Link>
        </Typography>
        <Box display="flex" sx={{ justifyContent: "flex-end", mt: 10 }}>
          <Image src="../images/marriageprep/RosePetals_bottom2.png"></Image>
        </Box>
      </Box>
    </WelcomeContainer>
  );
}
