import { createContext, useEffect, useState, useContext } from "react";
import { auth } from "../firebase";
import api from "../services/RegisterService";
import { useDispatch } from "react-redux";
import { updateUser } from "../features/userSlice";
import { updateToken } from "../features/userSlice";
import axios from "axios";
import firebase from "firebase/compat/app";
// ----------------------------------------------------------------------
const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState({});
  const [accessTkn, setAccessTkn] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [accessToken, setAccessToken] = useState(null);
  const dispatch = useDispatch();

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    try {
      const response = auth
        .signInWithEmailAndPassword(email, password)
        .then((response) => {
          setCurrentUser(response.user);
          setIsAuthenticated(true);
          return response.user;
        });
      // console.log(response.user.getIdToken());
      return response;
    } catch (err) {
      console.log(err);
    }
  }

  function logout() {
    return auth.signOut();
  }

  const value = {
    currentUser,
    isAuthenticated,
    accessTkn,
    signup,
    login,
    logout,
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user || null);
      if (user) {
        const token = await user.getIdToken();
        // setAccessToseken(token);
        setAccessTkn(token);
        // console.log("Access Token", token);
        getUserData(user?.uid, token);
        dispatch(updateToken(token));

        // axios.interceptors.request.use(
        //   async (config) => {
        //     config.headers.token = await firebase.auth().user.getIdToken();
        //     return config;
        //   },
        //   (error) => {
        //     return Promise.reject(error);
        //   }
        // );
      }
    });
    return unsubscribe;
  }, []);

  async function getUserData(uid, token) {
    try {
      let headers = { authorization: token };
      // console.log("Access Token", accessTkn);
      const response = await api
        .get("/userdetails/" + uid, { headers: headers })
        .then((response) => {
          dispatch(updateUser(response.data));
        });
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else {
        console.log("Error", err.message);
      }
    }
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
