import { Box, Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";

export default function ServiceThumbnail({ Title, LearnMore }) {
  return (
    <Box
      display={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ pl: 2, pr: 2, pt: 0, pb: 0 }}
    >
      <Typography
        variant="h5"
        sx={{
          mt: 5,
          mb: 2,
          color: "#FFFFFF",
          fontWeight: 700,
          fontFamily: "heebo",
        }}
      >
        {Title}
      </Typography>
      <Button
        href={LearnMore}
        variant="contained"
        sx={{
          fontWeight: 700,
          borderRadius: "8px",
          fontFamily: "heebo",
          textTransform: "none",
        }}
      >
        Learn More
      </Button>
    </Box>
  );
}
