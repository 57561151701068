export const monthList = [
  { code: "01", label: "January" },
  { code: "02", label: "February" },
  { code: "03", label: "March" },
  { code: "04", label: "April" },
  { code: "05", label: "May" },
  { code: "06", label: "June" },
  { code: "07", label: "July" },
  { code: "08", label: "August" },
  { code: "09", label: "September" },
  { code: "10", label: "October" },
  { code: "11", label: "November" },
  { code: "12", label: "December" },
];
