import React from "react";
import { useLocation, Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

import { useSelector } from "react-redux";

const PrivateRoute = () => {
  const { currentUser, userData, isAuthenticated } = useAuth();
  const location = useLocation();
  const { role } = useSelector((state) => state.userPersistedDetails);

  // rolebased
  return currentUser && role === "U" ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
