import { Stack, Box, MenuItem, TextField, Button } from "@mui/material";
// import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { profileFor } from "../../../data/_profileFor";
import { useNavigate } from "react-router-dom";
// Yup Validation hook
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// ----------------------------------------------------------------------
const RegisterSchema = Yup.object().shape({
  fullName: Yup.string().required("First name required"),
  email: Yup.string()
    .email("Email must be a valid email address")
    .required("Email is required"),
  mobile: Yup.string().required("Phone No. is required"),
  profileFor: Yup.string().required("Profile For is required"),
});

export default function RegisterForm() {
  // const isMountedRef = useIsMountedRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues: {
      fullName: "",
      email: "",
      mobile: "",
      profileFor: "",
    },
  });

  let navigate = useNavigate();

  const onSubmit = (data) => {
    let path = "/register";
    navigate(path, {
      state: { data },
    });

    // console.log("Data", data);
  };

  return (
    <Box>
      {/* release 2 */}
      <form onSubmit={handleSubmit(onSubmit)} background="#FFFFF">
        {/* release 1 */}
        {/* <form background="#FFFFF"> */}
        <Stack spacing={2}>
          <TextField
            name="fullName"
            label="Full Name"
            id="fullName"
            {...register("fullName")}
            error={!!errors?.fullName}
            helperText={errors?.fullName ? errors.fullName.message : null}
          />
          <TextField
            name="email"
            label="Email Address"
            id="email"
            {...register("email")}
            error={!!errors?.email}
            helperText={errors?.email ? errors.email.message : null}
          />
          <TextField
            name="mobile"
            label="Mobile No."
            id="mobile"
            {...register("mobile")}
            error={!!errors?.mobile}
            helperText={errors?.mobile ? errors.mobile.message : null}
          />

          <TextField
            name="profileFor"
            label="Profile For"
            select
            defaultValue={""}
            {...register("profileFor")}
            error={!!errors?.profileFor}
            helperText={errors?.profileFor ? errors.profileFor.message : null}
          >
            {profileFor.map((option) => (
              <MenuItem
                sx={{ height: "40px" }}
                key={option.code}
                value={option.code}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Button
            sx={{
              fontFamily: "heebo",
              fontWeight: 700,
              "&:hover": {
                backgroundColor: "#8b0f0e",
                color: "#FFFFFF",
              },
            }}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Proceed to Register
          </Button>
        </Stack>
      </form>
    </Box>
  );
}
