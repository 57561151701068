import { ThemeProvider, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import Menubar from "./components/menubar";
import theme from "./styles/theme";
import Footer from "./components/footer";
import RegisterBody from "./components/register";

function Register() {
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          display: "column",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Menubar></Menubar>
        <RegisterBody></RegisterBody>
        <Footer></Footer>
      </Container>
    </ThemeProvider>
  );
}

export default Register;
