import React from "react";
import { Box } from "@mui/material";

function ColorBar() {
  return (
    <Box
      height={8}
      width={50}
      sx={{ mt: 0 }}
      bgcolor={"#F9C6BC"}
      marginBottom={"10px"}
    ></Box>
  );
}

export default ColorBar;
