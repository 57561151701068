import { Box, Hidden, List, styled, Typography } from "@mui/material";
import "@fontsource/heebo";

export const FooterContainer = styled(Box)(({ theme }) => ({
  display: "column",
  justifyContent: "center",
  // alignItems: 'center',
  width: "auto",
  height: "auto",
  padding: "50px 10px",
  background: "#F7F7F7",
  backgroundSize: "cover",
  // overflow: 'hidden',
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
  },
}));

export const FooterWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  // alignItems: 'center',
  fontFamily: "heebo",
  width: "auto",
  height: "auto",
  padding: "0px 50px",
  background: "#F7F7F7",
  backgroundSize: "cover",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
  },
}));

export const FooterColumn = styled(Box)(({ theme }) => ({
  display: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "350px",
  // background: '#00DE34',
  fontFamily: "heebo",
  backgroundSize: "cover",
  // overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
    height: "auto",
  },
}));

export const FooterHeading = styled(Box)(({ theme }) => ({
  display: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "20px",
  width: "auto",
  height: "auto",
  padding: "20px 20px",
  fontFamily: "heebo",
  // background: '#FFFFFF',
  backgroundSize: "cover",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
    textAlign: "center",
  },
}));

export const FooterDetails = styled(Box)(({ theme }) => ({
  display: "column",
  // justifyContent: 'center',
  alignItems: "center",
  marginTop: "0px",
  marginBottom: "10px",
  marginLeft: "20px",
  marginRight: "20px",
  fontFamily: "heebo",
  width: "auto",
  height: "auto",
  padding: "20px 20px",
  borderRadius: 10,
  backgroundSize: "cover",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
    textAlign: "center",
  },
}));

export const FooterNotification = styled(Box)(({ theme }) => ({
  display: "column",
  justifyContent: "center",
  marginTop: "30px",
  textAlign: "center",
  fontFamily: "heebo",
  background: "#F7F7F7",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
    textAlign: "center",
  },
}));
