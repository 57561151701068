import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { CarouselMessages } from "../../helpers/carouseldata";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as HomeCarousel } from 'react-responsive-carousel';

export default function Carousel() {
  const [currMsg, setCurrMsg] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const next = () => {
    if (currentIndex === (CarouselMessages.length - 1)) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  }
  const prev = () => {
    if (currentIndex === 0) {
      setCurrentIndex(CarouselMessages.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  }
  const handleChange = (index) => {
    setCurrentIndex(index)
  }
  return (
    <Box display="column" justifyContent={"center"}>
      <HomeCarousel
        selectedItem={currentIndex}
        onChange={handleChange}
        autoPlay={true}
        interval={3000}
        showStatus={false}
        showArrows={false}
        showIndicators={false}
        renderIndicator={false}
        showThumbs={false}
        infiniteLoop={true}
        swipeable={true}
      >
        {CarouselMessages.map((item, index) => {
          return <Box height={"132px"} key={index}>
            <Typography
              align="center"
              sx={{ fontWeight: 700, marginBottom: 2, fontSize: "18px" }}
            >
              {item.title}
            </Typography>
            <Box height={"100px"} sx={{ cursor: "pointer" }}>
              <Typography align="center" sx={{ fontSize: "12px" }}>
                {item.Description}
              </Typography>
              <Typography align="center" sx={{ fontSize: "12px" }}>
                {item.Reference}
              </Typography>
            </Box>
          </Box>
        })}
      </HomeCarousel>
      <Box display="flex" justifyContent={"left"} sx={{ marginTop: 4 }}>
        <ArrowCircleLeftIcon
          sx={{ cursor: "pointer", ml: "30%" }}
          onClick={prev}
        />
        <ArrowCircleRightIcon
          sx={{ cursor: "pointer", ml: "30%" }}
          onClick={next}
        />
      </Box>
    </Box >
  );
}
