export const residentialStatusList = [
  { code: "Singapore Citizen (SC)", label: "Singapore Citizen (SC)" },
  { code: "Permanent Resident (PR)", label: "Permanent Resident (PR)" },
  { code: "Employment Pass (EP)", label: "Employment Pass (EP)" },
  { code: "S-Pass", label: "S-Pass" },
  { code: "Dependent Pass (DP)", label: "Dependent Pass (DP)" },
  { code: "LTVP", label: "LTVP" },
  { code: "Entrepreneur Pass", label: "Entrepreneur Pass" },
  { code: "Not Applicable", label: "Not Applicable" },
];
