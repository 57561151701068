import {
  Button,
  Container,
  FormLabel,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import ProfileDataGraph from "./components/Graphs/ProfileDataGraph";
import UsersDataGraph from "./components/Graphs/UsersDataGraph";
import "./components/Graphs/dashboard.css";
import theme from "./styles/theme";
import Menubar from "./components/menubar";
import Footer from "./components/footer";
import RegistrationStatusChart from "./components/Graphs/RegistrationStatusChart";
import { useNavigate } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";

function Dashboard() {
  const navigate = useNavigate();
  const [numberOfPendingrequest, setNumberOfPendingrequest] = useState(0);
  const handlePendingRequest = (pendingRequest) => {
    setNumberOfPendingrequest(pendingRequest);
  };

  const [isRefresh, setIsRefresh] = useState(false);
  // const handleRefresh = () => {
  //   window.location.reload();
  // };
  const handleNewRegistration = () => {
    navigate("/reglist");
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container
          maxWidth="xl"
          sx={{
            background: "#fff",
            display: "column",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Menubar></Menubar>
          <Container maxWidth="xl">
            <Box className="dashboard-header">
              <FormLabel id="maritalStatusLabel">
                <Typography
                  sx={{ fontSize: "30px", color: "#000000", padding: "17px" }}
                >
                  Dashboard
                </Typography>
              </FormLabel>
              <IconButton
                type="submit"
                color="secondary"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={() => {
                  setIsRefresh(!isRefresh);
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Box>
            <Box key={String(isRefresh)} sx={{ margin: "0 -24px" }}>
              <Box>
                <Grid container className="chart-container">
                  <Grid className="single-chart" item={true} xs={12} md={6}>
                    <Box className="profile-chart custom-pie-chart">
                      <Box className="chart-title">
                        <FormLabel className="black">
                          <Typography sx={{ fontWeight: 700 }}>
                            No. of Profiles
                          </Typography>
                        </FormLabel>
                      </Box>
                      <Box className="graph-wrapper">
                        <Box className="graph-container">
                          <ProfileDataGraph />
                        </Box>
                      </Box>
                      <Box className="chart-legends" xs={12} md={6}>
                        <div id="legend-profiles" className="legends" />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid className="single-chart" item={true} xs={12} md={6}>
                    <Box className="users-chart custom-pie-chart">
                      <Box className="chart-title">
                        <FormLabel className="black">
                          <Typography sx={{ fontWeight: 700 }}>
                            Profiles By Residing Country
                          </Typography>
                        </FormLabel>
                      </Box>
                      <Box className="graph-wrapper">
                        <Box className="graph-container">
                          <UsersDataGraph />
                        </Box>
                      </Box>
                      <Box className="chart-legends">
                        <div id="legend-users" className="legends" />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid className="single-chart" item={true} xs={12} md={6}>
                    <Box className="users-chart blank-chart-container">
                      <Box className="chart-title">
                        <FormLabel className="black">
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: 700,
                            }}
                          >
                            Coming soon...
                          </Typography>
                        </FormLabel>
                        <FormLabel className="sub-title"></FormLabel>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid className="single-chart" item={true} xs={12} md={6}>
                    <Box className="users-chart custom-bar-chart">
                      <Box className="bar-chart-title">
                        <FormLabel className="black">
                          <Typography sx={{ fontWeight: 700 }}>
                            Registration Status
                          </Typography>
                        </FormLabel>
                        <FormLabel className="sub-title">
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: 15,
                              color: "#ccc",
                            }}
                          >
                            <span id="pending-count">
                              {numberOfPendingrequest}
                            </span>{" "}
                            Pending Approvals
                          </Typography>
                        </FormLabel>
                        <Button
                          sx={{
                            border: "1px solid #46a857",
                            borderRadius: "12px",
                            color: "#46a857",
                            fontFamily: "'Lato', sans-serif",
                            textTransform: "capitalize",
                            marginTop: "20px",
                          }}
                          onClick={handleNewRegistration}
                        >
                          New Registrations
                        </Button>
                      </Box>
                      <Box className="bar-chart-legends">
                        <div id="profile-request-legends" className="legends" />
                      </Box>
                      <Box className="graph-wrapper bar-chart-wrapper">
                        <Box className="bar-graph-container">
                          <RegistrationStatusChart
                            pendingrequest={handlePendingRequest}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
          <Footer></Footer>
        </Container>
      </ThemeProvider>
    </>
  );
}

export default Dashboard;
