import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  ContactUsContainer,
  ContactUsInnerContainer,
} from "../../styles/contactus";
import { ContactUsForm } from "../../sections/auth/contactus";
import { Box } from "@mui/material";
import "@fontsource/heebo";
import Image from "../Image";
import Container from "@mui/material/Container";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ColorBar from "../ColorBar";

export default function ContactUsBody() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <ContactUsContainer>
      <ContactUsInnerContainer>
        <Box
          align="justify"
          display={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            lineHeight: 2,
            textAlign: "justify",
            fontSize: 20,
            fontFamily: "heebo",
            pl: 20,
            pr: 10,
            pt: 0,
            pb: 0,
          }}
        >
          <ColorBar />
          <Typography
            sx={{
              fontFamily: "heebo",
              fontSize: 30,
              fontWeight: 700,
              marginBottom: "40px",
            }}
          >
            Contact Us
          </Typography>
          <Box sx={{ pb: 20, backgroundColor: "none" }}>
            <ContactUsForm />
          </Box>
        </Box>
      </ContactUsInnerContainer>
      <ContactUsInnerContainer
        display="column"
        // grid-template-columns="repeat(4, auto)"
        justify-content="center"
      >
        <Box
          display="flex"
          sx={{
            alignItems: "center",
            justifyContent: "center",
            mt: 10,
            height: "30%",
            width: "50%",
          }}
        >
          <Image src="../images/contactus/ContactUs.png"></Image>
        </Box>
        <Box
          display="column"
          sx={{
            justifyContent: "center",
            // mt: 10,
            height: "50%",
            width: "90%",
          }}
        >
          <Container
            sx={{
              p: 3,
              backgroundColor: "#FFF1EE",
              borderRadius: "20px",
              overflow: "visible",
            }}
          >
            <Typography
              justifyContent="center"
              margin={"10px"}
              display="flex"
              sx={{ fontSize: "15px" }}
            >
              <WhatsAppIcon sx={{ mx: 2, fontSize: "20px" }} />
              (+65) 80854828
              <EmailIcon sx={{ mx: 2, fontSize: "20px" }} />
              sgmuslimmatrimony@gmail.com
            </Typography>
          </Container>
        </Box>
      </ContactUsInnerContainer>
    </ContactUsContainer>
  );
}
