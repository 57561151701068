import * as Yup from "yup";
import { useState } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Alert,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// hooks
import { useAuth } from "../../../contexts/AuthContext";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
// components
import Iconify from "../../../components/Iconify";
import {
  FormProvider,
  RHFTextField,
  RHFEditor,
} from "../../../components/hook-form";
import { TextareaAutosize } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { RecaptchaContainer } from "../../../styles/login";
import axios from "axios";
import ContactUsBody from "../../../components/contactus";
import api from "../../../services/RegisterService";
import emailjs from "@emailjs/browser";
import Notification from "../../../components/Notification";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

export default function ContactUsForm() {
  const { currentUser } = useAuth();
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const isMountedRef = useIsMountedRef();
  const state = { isVerified: false };
  const [showPassword, setShowPassword] = useState(false);
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITEKEY;
  // const { token } = useSelector((state) => state.userPersistedDetails);
  // const SERVICE_ID = "service_qhq25u5";
  // const TEMPLATE_ID = "template_lxn1x4v";
  // const PUBLIC_KEY = "SWUUqpaW5AKKAxvHc";

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });

  const ContactUsSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    mobile: Yup.string().required("Mobile No. is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  });

  const defaultValues = {
    fullName: "",
    email: "",
    mobile: "",
    subject: "",
    message: "",
  };

  const methods = useForm({
    resolver: yupResolver(ContactUsSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data, e) => {
    e.preventDefault();
    // console.log("Access Token to send", currentUser?._delegate.accessToken);
    // const token = currentUser?._delegate.accessToken;

    const emailAPI = "/sendemail/contactus";
    const dbAPI = "/contactusdetails";
    try {
      //Email VIa API
      const body =
        "<html xmlns='http://www.w3.org/1999/xhtml'><head><meta http-equiv='Content-Type' content='text/html;charset=utf-8' /><meta http-equiv='X-UA-Compatible' content='IE=edge' /><meta name='viewport' content='width=device-width, initial-scale=1.0'><style type='text/css'> body {Margin: 0;padding: 0;background-color: #f7f9fc} table {border - spacing: 0;}td {padding: 0;}img {border: 0;}.wrapper{width: 100%; table-layout: fixed;background-color: #f7f9fc;padding-bottom: 40px;}.webkit {max - width: 600px;background-color: #ffffff;} .outer {Margin: 0 auto;width: 100%;max-width: 600px;border-spacing: 0;font-family: sans-serif;color:#4a4a4a;.theadstyle {text - align:left;background-color: #F9C6BC;height: 50px;} .tdata {padding - left: 20px;padding-right: 20px;width: 200px;height: 50px;} .datatable {margin - left: auto;margin-right: auto;border-collapse:separate;border:solid #F9C6BC 3px;border-radius:6px;}@media screen and (max-width: 600px){ } @media screen and (max-width: 400px) { } </style></head> <body> <center class='wrapper'><div class='webkit><table class=' outer' align='center'><tr><td><table width='100%' style='border-spacing:0;'><tr><td style='background-color: #F9C6BC; padding: 10px; text-align: center;'></td></tr><tr><td style='background-color: #ffffff; padding: 10px; text-align: center;'><a href=><img src='https://cloud1.email2go.io/8171ac2c5544a5cb54ac0f38bf477af4/c65c58dc81dd10e1fc57803f2f91186f658e8d226e587ecf6adb3eceb5b0df42.png' width='250' alt=''></a></td></tr><tr><td style='background-color: #ffebe7; padding: 10px; text-align: justify;'><p>Assalamu Alaikkum !</p><p> Dear " +
        data.fullName +
        ",</p><p>Thank you for contacting us.</p> <p>This is an acknowledgment for your submission.</p> <p>Our administrators will contact you within 5 working days.</p><p> Below are the information submitted.</p></td></tr><tr><td style='background-color: #ffebe7; padding: 10px; text-align: left;'><table class='datatable'><tr class='theadstyle'><th class='tdata'>Title</th><th class='tdata'>Description</th></tr><tr ><td class='tdata'>Full Name</td><td class='tdata'>" +
        data.fullName +
        "</td></tr><tr><td class='tdata'> Email Address </td><td class='tdata'>" +
        data.email +
        "</td></tr><tr><td class='tdata'> Mobile No.</td><td class='tdata'>" +
        data.mobile +
        "</td></tr><tr><td class='tdata'>Subject</td><td class='tdata'>" +
        data.subject +
        "</td></tr><tr><td class='tdata'>Message</td><td class='tdata'><p style='text-align: justify;'>" +
        data.message +
        "</p></td></tr></table></td></tr><tr><p>This email is autogenerated from the system. Please do not reply to this email.</p></td></tr><tr><td style='background-color: #F9C6BC; padding: 10px; text-align: center;'></td></tr><tr></table></td></tr></table></div></center></body></html>";

      const sendEmail = api.post(emailAPI, {
        emailto: data.email,
        subject: data.subject,
        body: body,
        // emailcc: "sgmuslimmatrimony@gmail.com", //PROD
        emailcc: process.env.REACT_APP_ADMIN_EMAILID, //DEV
      });

      const saveContactUs = api.post(dbAPI, {
        userName: data.fullName,
        userEmailId: data.email,
        countryPrefix: "+65",
        mobileNo: data.mobile,
        subject: data.subject,
        message: data.message,
        termsAgreement: "Y",
      });

      axios.all([saveContactUs, sendEmail]).then(() => {
        setNotify({
          isOpen: true,
          message: "Submitted Successfully",
          type: "success",
        });
      });
      reset();

      // const response = await api.post("/sendemail/contactus", {
      //   emailto: data.email,
      //   subject: data.subject,
      //   body: body,
      //   emailcc: "sgmuslimmatrimony@gmail.com", //PROD
      //   // emailcc: "sgmatrimonialdev@gmail.com", //DEV
      // });
      // reset();
      // setNotify({
      //   isOpen: true,
      //   message: "Submitted Successfully",
      //   type: "success",
      // });
      // // Email via emailjs

      // var templateParams = {
      //   subject: data.subject,
      //   message: data.message,
      //   reply_to: "sgmatrimonialdev@gmail.com",
      //   to: data.email,
      //   fullName: data.fullName,
      //   mobile: data.mobile,
      // };

      // emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, PUBLIC_KEY).then(
      //   function (response) {
      //     console.log("SUCCESS!", response.status, response.text);
      //     // e.target.reset();
      //     reset();
      //   },
      //   function (error) {
      //     console.log("FAILED...", error);
      //   }
      // );

      // console.log(
      //   "Full Name:",
      //   data.fullName,
      //   "Email:",
      //   data.email,
      //   "Mobile:",
      //   data.mobile,
      //   "Subject:",
      //   data.subject,
      //   "Message:",
      //   data.message
      // );
    } catch (error) {
      console.error(error);
      reset();
      if (isMountedRef.current) {
        setError("afterSubmit", { ...error, message: error.message });
      }
    }
  };

  function onChange(value) {
    setCaptchaVerified(!captchaVerified);
  }

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      background="#FFFFF"
    >
      <Stack spacing={2}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        <RHFTextField name="fullName" label="Full Name" />
        <RHFTextField name="email" label="Email address" />
        <RHFTextField name="mobile" label="Mobile No." />
        <RHFTextField name="subject" label="Subject" />
        <RHFTextField
          name="message"
          label="Message"
          fullWidth
          multiline
          rows={4}
          placeholder="Write a message..."
        />
        <RecaptchaContainer>
          <ReCAPTCHA
            // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" //local host Key
            // sitekey="6LeardMhAAAAAPtKmXnO6gNJBY_ojI6X0_mn744B" //Prod Key
            // sitekey="6LdG2MkhAAAAAAzbIIf7Jdk5bhfTl5rCTwO-iYnz" //Dev Key
            sitekey={recaptchaKey}
            onChange={onChange}
          />
        </RecaptchaContainer>
        <LoadingButton
          sx={{ fontFamily: "heebo", fontWeight: 700 }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={!captchaVerified}
        >
          Submit
        </LoadingButton>
      </Stack>
      <Notification notify={notify} setNotify={setNotify} />
    </FormProvider>
  );
}
