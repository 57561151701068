import { useSnackbar } from "notistack";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
} from "@mui/material";
// hooks
import { useAuth } from "../../contexts/AuthContext";
import useIsMountedRef from "../../hooks/useIsMountedRef";
// components
// import MyAvatar from "../../components/MyAvatar";
import MenuPopover from "../../components/MenuPopover";
import { IconButtonAnimate } from "../../animate";
import { useSelector, useDispatch } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import { updateUser } from "../../features/userSlice";
import { Fragment } from "react";
import MailIcon from "@mui/icons-material/Mail";
import PieChartIcon from "@mui/icons-material/PieChart";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ContactsIcon from "@mui/icons-material/Contacts";
import HomeIcon from "@mui/icons-material/Home";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";

// ----------------------------------------------------------------------

const USER_MENU_OPTIONS = [
  {
    label: "My Home",
    linkTo: "/landingpage",
    icon: <HomeIcon fontSize="small" sx={{ color: "#9a7879", mr: 1 }} />,
  },
  {
    label: "My Profile",
    linkTo: "/myprofile",
    icon: (
      <ManageAccountsIcon fontSize="small" sx={{ color: "#9a7879", mr: 1 }} />
    ),
  },
  {
    label: "Profile Search",
    linkTo: "/profilesearch",
    icon: (
      <PersonSearchIcon fontSize="small" sx={{ color: "#9a7879", mr: 1 }} />
    ),
  },
];

const ADMIN_MENU_OPTIONS = [
  {
    label: "Dashboard",
    linkTo: "/dashboard",
    icon: <PieChartIcon fontSize="small" sx={{ color: "#5a9143", mr: 1 }} />,
  },
  {
    label: "Registrations",
    linkTo: "/reglist",
    icon: <HowToRegIcon fontSize="small" sx={{ color: "#5a9143", mr: 1 }} />,
  },
  {
    label: "Users",
    linkTo: "/userlist",
    icon: <PeopleAltIcon fontSize="small" sx={{ color: "#5a9143", mr: 1 }} />,
  },
  {
    label: "Profiles",
    linkTo: "/profilelist",
    icon: <ContactsIcon fontSize="small" sx={{ color: "#5a9143", mr: 1 }} />,
  },
  {
    label: "Messages",
    linkTo: "/messages",
    icon: <MailIcon fontSize="small" sx={{ color: "#5a9143", mr: 1 }} />,
  },
];
// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const { currentUser, logout, userData } = useAuth();

  const isMountedRef = useIsMountedRef();
  const { profileId } = useSelector((state) => state.userPersistedDetails);

  //   const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);
  const { role, name, email, shortCode } = useSelector(
    (state) => state.userPersistedDetails
  );
  const dispatch = useDispatch();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      // dispatch(updateUser());
      localStorage.removeItem("persist:userDetails-store");
      navigate("/login", { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      //   enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  return !currentUser ? (
    <></>
  ) : (
    <>
      <IconButtonAnimate onClick={handleOpen}>
        <Avatar
          sx={{
            width: 32,
            height: 32,
            fontSize: "15px",
            backgroundColor: role === "A" ? "#3bab0e" : "#9c1921",
          }}
        >
          {shortCode}
        </Avatar>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          backgroundColor: "#f5f5dc",
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography
            variant="subtitle2"
            noWrap
            sx={{
              fontWeight: "bold",
              color: role === "A" ? "#3bab0e" : "#9c1921",
            }}
          >
            {name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {role === "A"
            ? ADMIN_MENU_OPTIONS.map((option) => (
                <MenuItem
                  key={option.label}
                  to={option.linkTo}
                  component={RouterLink}
                  onClick={handleClose}
                >
                  <Fragment>{option.icon}</Fragment>
                  {option.label}
                </MenuItem>
              ))
            : USER_MENU_OPTIONS.map((option) => (
                <MenuItem
                  key={option.label}
                  to={option.linkTo}
                  component={RouterLink}
                  onClick={handleClose}
                  disabled={
                    option.label === "My Profile" && !profileId ? true : false
                  }
                >
                  <Fragment>{option.icon}</Fragment>
                  {option.label}
                </MenuItem>
              ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
          <LogoutIcon
            sx={{
              px: 2,
              fontSize: "20px",
              color: role === "A" ? "#3bab0e" : "#9c1921",
            }}
          />
        </MenuItem>
      </MenuPopover>
    </>
  );
}
