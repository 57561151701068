import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "userProfile",
  initialState: {
    name: "",
    shortCode: "",
    email: "",
    role: "",
    phoneNumber: "",
    id: "",
    profileId: "",
    regStatus: "",
    userStatus: "",
    usedQuota: 0,
    monthlyQuota: 0,
    expiryDate: "",
    lockStatus: "",
    userId: "",
    token: "",
  },
  reducers: {
    updateUser(state, action) {
      state.name = action.payload.userName;
      state.shortCode = action.payload.userText;
      state.email = action.payload.userEmail;
      state.role = action.payload.userRole;
      // state.phoneNumber= action.payload.;
      state.id = action.payload.userId;
      state.profileId = action.payload.profileId;
      state.regStatus = action.payload.regStatus;
      state.userStatus = action.payload.status;
      state.usedQuota = action.payload.usedQuota;
      state.monthlyQuota = action.payload.monthlyQuota;
      state.expiryDate = action.payload.expiryDate;
      state.lockStatus = action.payload.lockStatus;
      state.userId = action.payload.userId;
    },
    updateToken(state, action) {
      state.token = action.payload;
    },
  },
});

export const { updateUser, updateToken } = userSlice.actions;
export default userSlice.reducer;
