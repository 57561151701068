import React, { useRef, useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import api from "../../services/RegisterService";
import { useAuth } from "../../contexts/AuthContext";
import { useSelector } from "react-redux";

function ProfileDataGraph() {
  const chartRef = useRef(null);
  const { token } = useSelector((state) => state.userPersistedDetails);
  const [profileData, setProfileData] = useState({
    labels: ["Brides", "Bridegrooms"],
    datasets: [
      {
        label: "Profile Data",
        data: [0, 0],
        backgroundColor: ["#db72ca", "#5992fd"],
      },
    ],
  });
  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        let headers = { "authorization": `${token}`};
        const response = await api.get("/profiledetails?userRole=U", { headers: headers });
        const male = response.data.filter(
          (obj) => obj.gender.toLowerCase() === "m"
        ).length;
        const female = response.data.filter(
          (obj) => obj.gender.toLowerCase() === "f"
        ).length;
        setProfileData({
          labels: ["Brides", "Bridegrooms"],
          datasets: [
            {
              label: "Profile Data",
              data: [female, male],
              backgroundColor: ["#e86dca", "#4490fe"],
            },
          ],
        });
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else {
          console.log("Error", err.message);
        }
      }
    };
    fetchProfiles();
    document.getElementById("legend-profiles").innerHTML =
      chartRef.current.chartInstance.generateLegend();
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    legendCallback: (chart) => {
      var ul = document.createElement("ul");
      ul.id = "profile-legends-list";
      ul.setAttribute("class", "legends-list");
      chart.config.data.labels.forEach(function (value, index) {
        let backgroundColor = chart.config.data.datasets[0].backgroundColor;
        ul.innerHTML += `
                        <li class="legend-item">
                           <div class="circle-dot" style="background: ${backgroundColor[index]}"></div>
                           <span>${value}</span>
                         </li>
                      `;
      });
      return ul.outerHTML;
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem, chart) {
          const total = chart.datasets[0].data.reduce((a, b) => a + b, 0);
          let count = chart.datasets[0].data[tooltipItem.index];
          const percentage = (parseInt(count) * 100) / total;
          return (
            chart.labels[tooltipItem.index] + ": " + percentage.toFixed(0) + "%"
          );
        },
      },
    },
    legend: {
      display: false,
      onClick: (item) => {
        // console.log(item);
      },
    },
    plugins: {
      datalabels: {
        color: "#FFF",
        formatter: function (value, context) {
          const total = context.dataset.data.reduce((a, b) => a + b, 0);
          const percentage = (parseInt(value) * 100) / total;
          return percentage.toFixed(0) + "%";
        },
      },
    },
  };
  return <Pie options={options} data={profileData} ref={chartRef} />;
}

export default ProfileDataGraph;
