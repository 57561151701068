import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { WelcomeContainer } from "../../styles/welcome";
import { RegisterForm } from "../../sections/auth/register";
import { Box } from "@mui/material";
import "@fontsource/heebo";
import Image from "../Image";
import Link from "@mui/material/Link";

export default function TransNationalBody() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <WelcomeContainer>
      <Box
        align="justify"
        display={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          lineHeight: 2,
          textAlign: "justify",
          fontSize: 16,
          fontFamily: "heebo",
          pl: { md: 8, xl: 10 },
          pr: { md: 8, xl: 10 },
          pt: 0,
          pb: 0,
        }}
      >
        <Box width="100px">
          <Image src="../images/aboutus/RosePetalsTop.png"></Image>
        </Box>
        <Box
          height={8}
          width={50}
          sx={{ mt: "10px" }}
          bgcolor={"#F9C6BC"}
          marginX={"45%"}
          marginBottom={"20px"}
        ></Box>
        <Typography
          textAlign={"center"}
          sx={{
            fontFamily: "heebo",
            fontSize: 30,
            fontWeight: 700,
            marginBottom: "40px",
          }}
        >
          Transnational Family Support in Singapore
        </Typography>

        <Typography
          variant="subtitle"
          textAlign={"justify"}
          justifyContent={"center"}
        >
          Marriages between Singapore Citizens and Non-Residents has become a
          unique part of Singapore in the recent years which has its own unique
          challenges, such as cross-cultural differences. To help these
          transnational couples build a strong foundation for their marriage &
          to bring up their children in today’s Singapore Lifestyle, in 2014,
          MSF (Ministry of Social & Family Development) has introduced the
          Marriage Preparation Programme (MPP) and Marriage Support Programme
          (MSP).
        </Typography>

        <Typography variant="subtitle" display="block" sx={{ mt: 4, mb: 4 }}>
          Along with MSF, other Community Services has joined hands to provide
          support for this Trans National Families.
        </Typography>
        <Typography variant="subtitle" display="block">
          Please Click on the link below:
        </Typography>

        <Typography variant="subtitle" display="block">
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.fycs.org/our-work/family/transnationalfamily/"
            color="secondary"
          >
            Fei Yue Community Services
          </Link>
        </Typography>
        <Typography variant="subtitle" display="block">
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.family-central.sg/family/"
            color="secondary"
          >
            Family Central
          </Link>
        </Typography>
        <Typography variant="subtitle" display="block">
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.msf.gov.sg/media-room/Pages/Transnational-Marriages-in-Singapore.aspx"
            color="secondary"
          >
            MSF
          </Link>
        </Typography>
        <Typography variant="subtitle" display="block">
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://ppis.sg/family-services/"
            color="secondary"
          >
            PPIS
          </Link>
        </Typography>
        <Box display="flex" sx={{ justifyContent: "flex-end", mt: 10 }}>
          <Image src="../images/aboutus/RosePetalsBottom.png"></Image>
        </Box>
      </Box>
    </WelcomeContainer>
  );
}
