import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        genderFilter: "all",
        country: "all",
        languages: ["Any"],
        rangeYearOfBirth: [1800, 2100],
        rangeHeight: [0, 300],
        education: "all",
        marriageStatus: "all",
        clearFilter: true,
        residenceChange: "all",
        totalRecords: ""
    },
    reducers: {
        applyGenderFilter(state, action) {
            state.genderFilter = action.payload;
        },
        applyCountryFilter(state, action) {
            state.country = action.payload;
        },
        applylanguageFilter(state, action) {
            /* if (action.payload.length > 0) {
            }*/
            state.languages = action.payload;
        },
        applyYearFilter(state, action) {
            state.rangeYearOfBirth = action.payload;
        },
        applyHeightFilter(state, action) {
            state.rangeHeight = action.payload;
        },
        applyEducationFilter(state, action) {
            state.education = action.payload;
        },
        applymarriageStatusFilter(state, action) {
            state.marriageStatus = action.payload;
        },
        applyResidenceChange(state, action) {
            state.residenceChange = action.payload;
        },
        applyClearFilter(state, action) {
            if (state.clearFilter) {
                state.clearFilter = false;
            } else {
                state.clearFilter = true;
            }
            state.genderFilter = "all";
            state.country = "all";
            state.languages = ["Any"];
            state.rangeYearOfBirth = [1800, 2100];
            state.rangeHeight = [0, 300];
            state.education = "all";
            state.marriageStatus = "all";
            state.residenceChange = "all";
            state.totalRecords = "";
        },
        totalRecords(state, action) {
            state.totalRecords = action.payload;
        }
    },
});

export const { applyGenderFilter, applyCountryFilter, applylanguageFilter, applyYearFilter, applyHeightFilter, applyEducationFilter, applymarriageStatusFilter, applyClearFilter, applyResidenceChange, totalRecords } = filterSlice.actions;
export default filterSlice.reducer;