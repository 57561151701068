import "@fontsource/heebo";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  Button,
  styled,
  Container,
  Avatar,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { MyProfilePage } from "../../styles/profilesearch";
import * as React from "react";
import { TextField } from "@mui/material";
import { useState } from "react";
import Label from "../Label";
import api from "../../services/RegisterService";
import { makeStyles, ThemeProvider } from "@mui/styles";
import "./profilePage.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { countries } from "../../_mock/map/countries";
import ColorBar from "../ColorBar";
import Notification from "../Notification";

export default function MyProfileBody() {
  const { id, email, profileId, token } = useSelector(
    (state) => state.userPersistedDetails
  );
  const [headerInfo, setHeaderInfo] = useState({
    myName: "",
    country: "",
    gender: "",
  });
  const [myProfile, setmyProfile] = useState({
    maritalStatus: "",
    yearOfBirth: "",
    height: "",
    profileId: "",
  });
  const [educationSection, seteducationSection] = useState({
    highestEducationLevel: "",
    educationQualification: "",
    profession: "",
  });
  const [contactSection, setcontactSection] = useState({
    contactNumber: "",
    alternameNumber: "",
    emailId: "",
  });
  const [PersonalInformationSecton, setPersonalInformationSecton] = useState({
    residentialStatus: "",
    parentNativity: "",
    additionalInformation: "",
    parentName: "",
    language: "",
    partnerCountry: "",
    expectingPartnerProfile: "",
  });
  const [myAvatarLabel, setMyAvatarLabel] = useState("");
  const [isContactDetailvisible, setisContactDetailvisible] = useState(true);

  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // const matches = useMediaQuery(theme.breakpoints.down("md"));
  // const MyComponent = styled("div")({
  //     color: "darkslategray",
  //     padding: 8,
  //     borderRadius: 4,
  //     fontSize: "20px",
  //     alignmentBaseline: "true",
  //     display: "flex",
  //     alignItems: "center",
  // });

  const monthShortNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const handleBackClick = () => {
    navigate("/landingpage");
  };

  const stringAvatar = () => {
    return {
      sx: {
        bgcolor: "#F9C6BC",
      },
      children: myAvatarLabel,
    };
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "#000000",
    backgroundColor: "#F9C6BC",
    "&:hover": {
      backgroundColor: "#F9C6BC",
    },
  }));

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });

  useEffect(() => {
    async function fetchprofile() {
      try {
        setNotify({
          isOpen: true,
          message: "Loading....",
          type: "info",
        });
        let headers = { authorization: `${token}` };
        const response = await api.get(`profiledetails/${email}/email`, {
          headers: headers,
        });
        const myProfileData = response.data;

        const {
          marriageStatus,
          heightInCm,
          heightInch,
          profileId,
          dateOfBirth,
          educationLevel,
          education,
          profession,
          sgResidentStatus,
          parentName,
          partnerCountryPreference,
          partnerProfilePreference,
          additionalInfo,
          languagesKnown,
          emaiId,
          secondaryContact,
          primaryContact,
          nativePlace,
          gender,
          citizenShipCountry,
          profilename,
          profileText,
        } = myProfileData;
        setMyAvatarLabel(profileText);
        setHeaderInfo({
          myName:
            profilename !== "" && profilename !== null ? profilename : "-",
          country: countries.filter(
            (value) =>
              value.code?.toLowerCase() === citizenShipCountry?.toLowerCase()
          )[0]?.label,
          gender: gender?.toLowerCase() === "m" ? "Bridegroom" : "Bride",
        });

        setmyProfile({
          maritalStatus:
            marriageStatus !== "" && marriageStatus !== null
              ? marriageStatus
              : "-",
          yearOfBirth:
            dateOfBirth !== "" && dateOfBirth !== null
              ? `${
                  monthShortNames[dateOfBirth?.substring(5, 7) - 1]
                } ${dateOfBirth.substring(0, 4)}`
              : "-",
          height:
            heightInCm !== "" && heightInCm !== null
              ? `${heightInCm} cm (${heightInch})`
              : "-",
          profileId: profileId !== "" && profileId !== null ? profileId : "-",
        });

        seteducationSection({
          highestEducationLevel:
            educationLevel !== "" && educationLevel !== null
              ? educationLevel
              : "-",
          educationQualification:
            education !== "" && education !== null ? education : "-",
          profession:
            profession !== "" && profession !== null ? profession : "-",
        });
        setPersonalInformationSecton({
          residentialStatus:
            sgResidentStatus !== "" && sgResidentStatus !== null
              ? sgResidentStatus
              : "-",
          parentNativity:
            nativePlace !== "" && nativePlace !== null ? nativePlace : "-",
          additionalInformation:
            additionalInfo !== "" && additionalInfo !== null
              ? additionalInfo
              : "-",
          parentName:
            parentName !== "" && parentName !== null ? parentName : "-",
          language:
            languagesKnown !== "" && languagesKnown !== null
              ? languagesKnown
              : "-",
          partnerCountry:
            partnerCountryPreference !== "" && partnerCountryPreference !== null
              ? partnerCountryPreference
              : "-",
          expectingPartnerProfile:
            partnerProfilePreference !== "" && partnerProfilePreference !== null
              ? partnerProfilePreference
              : "-",
        });
        setcontactSection({
          contactNumber:
            primaryContact !== "" && primaryContact !== null
              ? primaryContact
              : "-",
          alternameNumber:
            secondaryContact !== "" && secondaryContact !== null
              ? secondaryContact
              : "-",
          emailId: emaiId !== "" && emaiId !== null ? emaiId : "-",
        });
        setNotify({
          isOpen: false,
          message: "",
          type: "info",
        });
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else {
          console.log("Error", err.message);
        }
      }
    }
    fetchprofile();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          display: "column",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <MyProfilePage>
          <Box
            align="justify"
            display={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              lineHeight: 2,
              textAlign: "justify",
              fontSize: 20,
              fontFamily: "heebo",
              pl: 1,
              pr: 5,
              pt: 2,
              pb: "45px",
            }}
          >
            <ColorBar />
            <Typography
              sx={{
                fontFamily: "heebo",
                fontSize: 30,
                fontWeight: 700,
                marginBottom: "10px",
              }}
            >
              My Profile
            </Typography>
            {/* Container */}
            <Grid>
              <Box className="profile-inside-container">
                <Box className="profile-details">
                  <Avatar
                    {...stringAvatar()}
                    sx={{
                      width: 70,
                      height: 70,
                      background:
                        "linear-gradient(to right bottom, #F9C6BC, #FF907A)",
                      marginBottom: "23px",
                      color: "#383434",
                      fontSize: "30px",
                    }}
                  />
                  <Box>
                    <Box className="country-gender-container">
                      <Box className="profile-country-name">
                        {`${headerInfo.country}`}{" "}
                      </Box>
                      <Box className="profile-gender">{headerInfo.gender}</Box>
                    </Box>
                    <Box className="profile-full-name">{headerInfo.myName}</Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid rowSpacing={2} columnSpacing={5} container item sm={12}>
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>Profile ID</span>
                </Label>

                <TextField
                  size="small"
                  InputLabelProps={{ shrink: false }}
                  disabled
                  fullWidth
                  selectname="displayName"
                  // sx={{
                  //   "& .MuiInputBase-input.Mui-disabled": {
                  //     WebkitTextFillColor: "black",
                  //   },
                  // }}
                  value={myProfile.profileId}
                  className="text-color-dark"
                ></TextField>
              </Grid>
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>Marital Status</span>
                </Label>

                <TextField
                  size="small"
                  InputLabelProps={{ shrink: false }}
                  disabled
                  fullWidth
                  selectname="displayName"
                  value={`${myProfile.maritalStatus}`}
                  className="text-color-dark"
                ></TextField>
              </Grid>
              <Grid spacing={5} item container>
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>
                      Month/Year of Birth
                    </span>
                  </Label>

                  <TextField
                    InputLabelProps={{ shrink: false }}
                    size="small"
                    disabled
                    fullWidth
                    selectname="displayName"
                    value={myProfile.yearOfBirth}
                    className="text-color-dark"
                  ></TextField>
                </Grid>
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>Height (cm)</span>
                  </Label>

                  <TextField
                    size="small"
                    InputLabelProps={{ shrink: false }}
                    disabled
                    fullWidth
                    selectname="displayName"
                    value={myProfile.height}
                    className="text-color-dark"
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
            {/* profile section grid */}
            {/* Education and Professional Information section */}
            <Typography
              color="secondary"
              sx={{ mt: 5, mb: 1, fontSize: "20px" }}
            >
              Education and Professional Information
            </Typography>
            <Divider />
            <Grid
              rowSpacing={2}
              columnSpacing={5}
              container
              item
              sm={12}
              sx={{ marginTop: "0px" }}
            >
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>
                    Highest Education Level
                  </span>
                </Label>
                <TextField
                  size="small"
                  InputLabelProps={{ shrink: false }}
                  disabled
                  fullWidth
                  selectname="displayName"
                  value={educationSection.highestEducationLevel}
                  className="text-color-dark"
                ></TextField>
              </Grid>
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>
                    Education Qualification
                  </span>
                </Label>

                <TextField
                  size="small"
                  InputLabelProps={{ shrink: false }}
                  disabled
                  fullWidth
                  selectname="displayName"
                  value={educationSection.educationQualification}
                  className="text-color-dark"
                ></TextField>
              </Grid>
              <Grid spacing={5} item container>
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>Profession</span>
                  </Label>

                  <TextField
                    size="small"
                    InputLabelProps={{ shrink: false }}
                    disabled
                    fullWidth
                    selectname="displayName"
                    value={educationSection.profession}
                    className="text-color-dark"
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
            {/* end Education and Professional Information section */}
            {/* Personal Information section */}
            <Typography
              color="secondary"
              sx={{ mt: 5, mb: 1, fontSize: "20px" }}
            >
              Personal Information
            </Typography>
            <Divider />
            <Grid
              rowSpacing={2}
              columnSpacing={5}
              container
              item
              sm={12}
              sx={{ marginTop: "0px" }}
            >
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>
                    Residential Status (SG)
                  </span>
                </Label>
                <TextField
                  size="small"
                  InputLabelProps={{ shrink: false }}
                  disabled
                  fullWidth
                  selectname="displayName"
                  value={PersonalInformationSecton.residentialStatus}
                  className="text-color-dark"
                ></TextField>
              </Grid>
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>Parent/Gardian Name</span>
                </Label>

                <TextField
                  size="small"
                  InputLabelProps={{ shrink: false }}
                  disabled
                  fullWidth
                  selectname="displayName"
                  value={PersonalInformationSecton.parentName}
                  className="text-color-dark"
                ></TextField>
              </Grid>
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>Parent's Nativity</span>
                </Label>
                <TextField
                  size="small"
                  InputLabelProps={{ shrink: false }}
                  disabled
                  fullWidth
                  selectname="displayName"
                  value={PersonalInformationSecton.parentNativity}
                  className="text-color-dark"
                ></TextField>
              </Grid>
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>Languages Known</span>
                </Label>

                <TextField
                  size="small"
                  InputLabelProps={{ shrink: false }}
                  disabled
                  fullWidth
                  selectname="displayName"
                  value={PersonalInformationSecton.language}
                  className="text-color-dark"
                ></TextField>
              </Grid>
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>
                    Expecting Partner Profile
                  </span>
                </Label>

                <TextField
                  size="small"
                  InputLabelProps={{ shrink: false }}
                  disabled
                  multiline
                  rows={4}
                  fullWidth
                  selectname="displayName"
                  value={PersonalInformationSecton.expectingPartnerProfile}
                  className="text-color-dark"
                ></TextField>
              </Grid>
              <Grid item md={6} lg={6} sm={12}>
                <Label>
                  <span style={{ fontSize: "15px" }}>
                    Expecting Partner Country
                  </span>
                </Label>

                <TextField
                  size="small"
                  InputLabelProps={{ shrink: false }}
                  disabled
                  fullWidth
                  selectname="displayName"
                  value={PersonalInformationSecton.partnerCountry}
                  className="text-color-dark"
                ></TextField>
              </Grid>
              <Grid spacing={5} item container>
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "15px" }}>
                      Additional Information of the Profile
                    </span>
                  </Label>
                  <TextField
                    size="small"
                    InputLabelProps={{ shrink: false }}
                    disabled
                    multiline
                    rows={4}
                    fullWidth
                    selectname="displayName"
                    value={PersonalInformationSecton.additionalInformation}
                    className="text-color-dark"
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
            {/* Personal Information section */}
            {/* contact detail section */}
            {isContactDetailvisible && (
              <>
                <Typography
                  color="secondary"
                  sx={{ mt: 5, mb: 1, fontSize: "20px" }}
                >
                  Contact Information
                </Typography>
                <Divider />
                <Grid
                  rowSpacing={2}
                  columnSpacing={5}
                  container
                  item
                  sm={12}
                  sx={{ marginTop: "0px" }}
                >
                  <Grid item md={6} lg={6} sm={12}>
                    <Label>
                      <span style={{ fontSize: "15px" }}>Contact No.</span>
                    </Label>
                    <TextField
                      size="small"
                      InputLabelProps={{ shrink: false }}
                      disabled
                      fullWidth
                      selectname="displayName"
                      value={contactSection.contactNumber}
                      className="text-color-dark"
                    ></TextField>
                  </Grid>
                  <Grid item md={6} lg={6} sm={12}>
                    <Label>
                      <span style={{ fontSize: "15px" }}>
                        Alternate Contact No.
                      </span>
                    </Label>

                    <TextField
                      size="small"
                      InputLabelProps={{ shrink: false }}
                      disabled
                      fullWidth
                      selectname="displayName"
                      value={contactSection.alternameNumber}
                      className="text-color-dark"
                    ></TextField>
                  </Grid>
                  <Grid spacing={5} item container>
                    <Grid item md={6} lg={6} sm={12}>
                      <Label>
                        <span style={{ fontSize: "15px" }}>Email ID</span>
                      </Label>

                      <TextField
                        size="small"
                        InputLabelProps={{ shrink: false }}
                        disabled
                        fullWidth
                        selectname="displayName"
                        value={contactSection.emailId}
                        className="text-color-dark"
                      ></TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {/* end contact detail section */}
          </Box>
          <Box className="back-button-container">
            <Box className="back-button">
              <ColorButton
                variant="contained"
                size="medium"
                onClick={handleBackClick}
              >
                back to home
              </ColorButton>
            </Box>
          </Box>
        </MyProfilePage>
        <Notification notify={notify} setNotify={setNotify} />
      </Container>
    </ThemeProvider>
  );
}
