import * as Yup from "yup";
import { useState } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  MenuItem,
  Typography,
  Checkbox,
  Link,
  Box,
  Modal,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// hooks
import useIsMountedRef from "../../../hooks/useIsMountedRef";
// components
import Iconify from "../../../components/Iconify";
import ReCAPTCHA from "react-google-recaptcha";
import { RecaptchaContainer } from "../../../styles/login";
import { profileFor } from "../../../data/_profileFor";
import { countries } from "../../../_mock/map";
import { useLocation } from "react-router-dom";
import Notification from "../../../components/Notification";
import api from "../../../services/RegisterService";
import axios from "axios";
import TermsandConditions from "../../../components/Terms/TermsandConditions";
// ----------------------------------------------------------------------
const RegisterSchema = Yup.object().shape({
  fullName: Yup.string().required("First name is required"),
  email: Yup.string()
    .email("Email must be a valid email address")
    .required("Email is required"),
  countryPrefix: Yup.string().required("Select the Country Code"),
  mobile: Yup.string()
    .required("Mobile No. is required")
    .matches(
      /^[1-9]\d{1,14}$/,
      "Phone number is not valid. Enter valid phone number."
    ),
  profileFor: Yup.string().required("Select for whom you are registering."),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password should have minimum 8 characters.")
    .max(14, "Password can have upto 14 characters."),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords does not match"
  ),
});

export default function RegisterFullForm() {
  const location = useLocation();
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues: {
      fullName: location.state.data.fullName,
      countryPrefix: "",
      email: location.state.data.email,
      mobile: location.state.data.mobile,
      profileFor: location.state.data.profileFor,
      termsAgreement: false,
      password: "",
      confirmPassword: "",
    },
  });

  const isMountedRef = useIsMountedRef();
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITEKEY;

  async function onSubmit(data, e) {
    e.preventDefault();
    const emailAPI = "/sendemail/contactus";
    const dbAPI = "/registeruser";
    const countryCode = countries.filter((value) => {
      if (value.code === data.countryPrefix) {
        return value.phone;
      }
    });
    try {
      const saveRegistration = await api.post("/registeruser", {
        name: data.fullName,
        emailId: data.email,
        countryPrefix: countryCode[0].phone,
        mobileNo: data.mobile,
        profileFor: data.profileFor,
        regStatus: "N",
        termsAgreement: data.termsAgreement ? "Y" : "N",
        password: data.password,
      });

      //Email VIa API
      const body =
        "<html xmlns='http://www.w3.org/1999/xhtml'><head><meta http-equiv='Content-Type' content='text/html;charset=utf-8' /><meta http-equiv='X-UA-Compatible' content='IE=edge' /><meta name='viewport' content='width=device-width, initial-scale=1.0'><style type='text/css'> body {Margin: 0;padding: 0;background-color: #f7f9fc} table {border - spacing: 0;}td {padding: 0;}img {border: 0;}.wrapper{width: 100%; table-layout: fixed;background-color: #f7f9fc;padding-bottom: 40px;}.webkit {max - width: 600px;background-color: #ffffff;} .outer {Margin: 0 auto;width: 100%;max-width: 600px;border-spacing: 0;font-family: sans-serif;color:#4a4a4a;.theadstyle {text - align:left;background-color: #F9C6BC;height: 50px;} .tdata {padding - left: 20px;padding-right: 20px;width: 200px;height: 50px;} .datatable {margin - left: auto;margin-right: auto;border-collapse:separate;border:solid #F9C6BC 3px;border-radius:6px;}@media screen and (max-width: 600px){ } @media screen and (max-width: 400px) { } </style></head> <body> <center class='wrapper'><div class='webkit><table class=' outer' align='center'><tr><td><table width='100%' style='border-spacing:0;'><tr><td style='background-color: #F9C6BC; padding: 10px; text-align: center;'></td></tr><tr><td style='background-color: #ffffff; padding: 10px; text-align: center;'><a href=><img src='https://cloud1.email2go.io/8171ac2c5544a5cb54ac0f38bf477af4/c65c58dc81dd10e1fc57803f2f91186f658e8d226e587ecf6adb3eceb5b0df42.png' width='250' alt=''></a></td></tr><tr><td style='background-color: #ffebe7; padding: 10px; text-align: justify;'><p>Assalamu Alaikkum !</p><p> Dear " +
        data.fullName +
        ",</p><p>Thank you for registering with us.</p> <p>This is an acknowledgment for your submission.</p> <p>Our administrators will contact you within 5 working days.</p><p> Below are the information submitted.</p></td></tr><tr><td style='background-color: #ffebe7; padding: 10px; text-align: left;'><table class='datatable'><tr class='theadstyle'><th class='tdata'>Title</th><th class='tdata'>Description</th></tr><tr ><td class='tdata'>Full Name</td><td class='tdata'>" +
        data.fullName +
        "</td></tr><tr><td class='tdata'> Email Address </td><td class='tdata'>" +
        data.email +
        "</td></tr><tr><td class='tdata'> Mobile No.</td><td class='tdata'>+" +
        countryCode[0].phone +
        data.mobile +
        "</td></tr><tr><td class='tdata'>Profile For</td><td class='tdata'>" +
        data.profileFor +
        "</td></tr></table></td></tr><tr><p>This email is autogenerated from the system. Please do not reply to this email.</p></td></tr><tr><td style='background-color: #F9C6BC; padding: 10px; text-align: center;'></td></tr><tr></table></td></tr></table></div></center></body></html>";

      const sendEmail = api.post(emailAPI, {
        emailto: data.email,
        subject: "Acknowledgement for Registration ",
        body: body,
        // emailcc: "sgmuslimmatrimony@gmail.com", //PROD
        emailcc: process.env.REACT_APP_ADMIN_EMAILID, //DEV
      });

      //for get country phone from country code

      setNotify({
        isOpen: true,
        message: "Registration Submitted Successfully",
        type: "success",
      });
      reset();
    } catch (error) {
      console.error(JSON.stringify(error.response.data));
      reset();
      setError("afterSubmit", {
        ...error,
        message: JSON.stringify(error.response.data),
      });
    }
  }

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const style = {
    position: "absolute",
    height: "70%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  function onChange() {
    setCaptchaVerified(!captchaVerified);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} background="#FFFFF">
      {/* // <form onSubmit={createUser} background="#FFFFF"> */}
      <Stack spacing={2}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        <TextField
          name="fullName"
          label="Full Name"
          defaultValue={location.state.data.fullName}
          {...register("fullName")}
          error={!!errors?.fullName}
          helperText={errors?.fullName ? errors.fullName.message : null}
        />
        <TextField
          name="email"
          label="Email address"
          defaultValue={location.state.data.email}
          {...register("email")}
          error={!!errors?.email}
          helperText={errors?.email ? errors.email.message : null}
        />
        <TextField
          select
          name="countryPrefix"
          label="Country Code"
          fontFamily="heebo"
          defaultValue=""
          {...register("countryPrefix")}
          error={!!errors?.countryPrefix}
          helperText={
            errors?.countryPrefix ? errors.countryPrefix.message : null
          }
        >
          {countries.map((option) => (
            <MenuItem
              sx={{ height: "40px" }}
              key={option.code}
              // value={option.phone}
              value={option.code}
            >
              {option.label}
              {"(" + option.phone + ")"}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          name="mobile"
          label="Mobile No. (without country code)"
          defaultValue={location.state.data.mobile}
          {...register("mobile")}
          error={!!errors?.mobile}
          helperText={errors?.mobile ? errors.mobile.message : null}
        />
        <TextField
          select
          name="profileFor"
          label="Profile For"
          fontFamily="heebo"
          defaultValue={location.state.data.profileFor}
          {...register("profileFor")}
          error={!!errors?.profileFor}
          helperText={errors?.profileFor ? errors.profileFor.message : null}
        >
          {profileFor.map((option) => (
            <MenuItem
              sx={{ height: "40px" }}
              key={option.code}
              value={option.code}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          {...register("password")}
          error={!!errors?.password}
          helperText={errors?.password ? errors.password.message : null}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          name="confirmPassword"
          label="Confirm Password"
          {...register("confirmPassword")}
          error={!!errors?.confirmPassword}
          helperText={
            errors?.confirmPassword ? errors.confirmPassword.message : null
          }
          type={showConfirmPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  <Iconify
                    icon={
                      showConfirmPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box display="flex">
          <div>
            <Checkbox
              name="termsAgreement"
              label="Terms"
              {...register("termsAgreement")}
              required
            />
          </div>
          <Box>
            <Typography display="inline-block" sx={{ fontSize: "15px" }}>
              <span> I accept the </span>
              <span>
                <Link
                  onClick={handleOpenModal}
                  component="button"
                  type="button"
                  color="secondary"
                >
                  Terms and Conditions
                </Link>
              </span>
            </Typography>
          </Box>
        </Box>
        <RecaptchaContainer>
          <ReCAPTCHA sitekey={recaptchaKey} onChange={onChange} />
        </RecaptchaContainer>
        <LoadingButton
          sx={{ fontFamily: "heebo", fontWeight: 700 }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={!captchaVerified}
          id="submitButton"
        >
          Submit
        </LoadingButton>
      </Stack>
      <Notification notify={notify} setNotify={setNotify} />
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          overflow="auto"
          flex={1}
          flexDirection="column"
          display="flex"
        >
          <TermsandConditions />
        </Box>
      </Modal>
    </form>
  );
}
