import React, { useEffect, useState, useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import api from "../../services/RegisterService";
import { countries } from "../../_mock/map/countries";
import { useSelector } from "react-redux";

function UsersDataGraph() {
  const chartRef = useRef(null);
  const { token } = useSelector((state) => state.userPersistedDetails);
  const [profiles, setProfilesData] = useState({
    labels: [],
    datasets: [
      {
        label: "Profiles",
        data: [0, 0, 0, 0],
        backgroundColor: ["#45aa55", "#4094ff", "#f4c319", "#ee4c42"],
      },
    ],
  });

  let pieData = [];

  const generateRandomHexColor = () =>
    `#${Math.floor(Math.random() * 0xffffff).toString(16)}`;

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        let headers = { "authorization": `${token}`};
        const response = await api.get("/profiledetails?userRole=U", { headers: headers });
        const countryCodes = [
          ...new Set(
            response.data.map((obj) => obj.residingCountry.toLowerCase())
          ),
        ];
        let counts = [];
        let backgroundColors = [];

        const countryLabels = countryCodes.map((value) => {
          counts.push(
            response.data.filter(
              (obj) => obj.residingCountry.toLowerCase() === value
            ).length
          );
          backgroundColors.push(generateRandomHexColor());
          const res = countries.filter((item) => {
            if (value === item.code.toLowerCase()) {
              return item?.label;
            }
          });
          if (res[0]?.label == undefined) {
            return "others";
          } else {
            return res[0]?.label;
          }
        });
        //create object for label and count
        const labelValueObject = {};
        countryLabels.forEach((element, index) => {
          labelValueObject[element] = counts[index];
        });

        var sortableLabels = [];
        for (var value in labelValueObject) {
          sortableLabels.push([value, labelValueObject[value]]);
        }

        sortableLabels.sort(function (a, b) {
          return b[1] - a[1];
        });

        let topTenCountries = [];
        let topTenCounts = [];
        sortableLabels.map((item, index) => {
          if (index < 10) {
            topTenCountries.push(item[0]);
            topTenCounts.push(item[1]);
          }
        });

        setProfilesData({
          labels: topTenCountries,
          datasets: [
            {
              label: "Profiles",
              data: topTenCounts,
              backgroundColor: [
                "#45aa55",
                "#4094ff",
                "#f4c319",
                "#ee4c42",
                "#d69ef6",
                "#43d1a3",
                "#3c6fbb",
                "#4c4c47",
                "#677653",
                "#270f32",
              ],
            },
          ],
        });
        setLegends(countryLabels).then(function (value) {
          // console.log("enter inside then", chartRef);
          try {
            document.getElementById("legend-users").innerHTML =
              chartRef.current.chartInstance.generateLegend();
            setTimeout(() => {
              // console.log("settimeout", chartRef);
              document.getElementById("legend-users").innerHTML =
                chartRef.current.chartInstance.generateLegend();
            });
          } catch (error) {
            console.log(error);
          }
        });

        // document.getElementById("legend-users").innerHTML =
        //   chartRef.current.chartInstance.generateLegend();
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else {
          console.log("Error", err.message);
        }
      }
    };
    fetchProfiles();
  }, []);

  const setLegends = (countryLabels) => {
    // console.log("inside setlegend");
    return new Promise(function (resolve, reject) {
      if (countryLabels.length > 0) {
        resolve();
      } else {
        reject();
      }
    });
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    legendCallback: (chart) => {
      var ul = document.createElement("ul");
      ul.id = "users-legends-list";
      ul.setAttribute("class", "legends-list");
      chart.config.data.labels.forEach(function (value, index) {
        let backgroundColor = chart.config.data.datasets[0].backgroundColor;
        ul.innerHTML += `
                        <li class="legend-item">
                           <div class="circle-dot" style="background: ${backgroundColor[index]}"></div>
                           <span>${value}</span>
                         </li>
                      `;
      });
      return ul.outerHTML;
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem, chart) {
          const total = chart.datasets[0].data.reduce((a, b) => a + b, 0);
          let count = chart.datasets[0].data[tooltipItem.index];
          const percentage = (parseInt(count) * 100) / total;
          return (
            chart.labels[tooltipItem.index] +
            ": " +
            percentage.toFixed(0) +
            "%; No. of Profiles: " +
            count
          );
        },
      },
    },
    legend: {
      display: false,
      onClick: (item) => {},
    },
    plugins: {
      datalabels: {
        color: "#FFF",
        formatter: function (value, context) {
          const total = context.dataset.data.reduce((a, b) => a + b, 0);
          const percentage = (parseInt(value) * 100) / total;
          return percentage.toFixed(0) + "%";
        },
      },
    },
  };

  return (
    <>
      <Doughnut data={profiles} options={options} ref={chartRef} />
    </>
  );
}

export default UsersDataGraph;
