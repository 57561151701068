import { Box, Hidden, List, styled, Typography } from "@mui/material";
import "@fontsource/heebo";

export const ContactUsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  padding: "10px 10px",
  background: "#FFFFFF",
  // backgroundImage: 'url(../images/login/PetalsBG.png)',
  backgroundSize: "cover",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
    height: "auto",
  },
}));

export const ContactUsInnerContainer = styled(Box)(({ theme }) => ({
  display: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "800px",
  padding: "10px 10px",
  background: "#FFFFFF",
  // backgroundImage: 'url(../images/login/PetalsBG.png)',
  backgroundSize: "cover",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
    height: "auto",
  },
}));

export const RecaptchaContainer = styled(Box)(({ theme }) => ({
  display: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "auto",
  height: "100%",
  padding: "50px 10px",
  backgroundSize: "cover",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
  },
}));
