import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  ServiceContainer,
  ServiceWrapper,
  ServiceColumn,
  ServiceTxtBlock,
  ServiceThumbnailSmall,
} from "../../styles/welcome";
import ServiceText from "./ServicesText";
import ServiceThumbnail from "./ServiceThumbnail";

export default function OurServices() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  // const img = "../images/services/laptopbg.jpg";
  return (
    <ServiceContainer>
      <ServiceWrapper>
        <ServiceColumn>
          <ServiceTxtBlock>
            <ServiceText
              Title={"Our Services"}
              Desc={
                "We are excited to share our support & services that will lay a strong foundations for a sound Family Life, which enhances affection and build a stronger and stable relationship that can endure the trials in life."
              }
            ></ServiceText>
          </ServiceTxtBlock>
          <ServiceThumbnailSmall>
            <ServiceThumbnail
              Title={"Marriage Info"}
              LearnMore={"/aboutus"}
            ></ServiceThumbnail>
          </ServiceThumbnailSmall>
        </ServiceColumn>
        <ServiceColumn>
          <ServiceThumbnailSmall sx={{ height: matches ? "280px" : "620px" }}>
            <ServiceThumbnail
              Title={"Marriage Preparation Programme"}
              LearnMore={"/marriageprep"}
            ></ServiceThumbnail>
          </ServiceThumbnailSmall>
        </ServiceColumn>
        <ServiceColumn>
          <ServiceThumbnailSmall sx={{ height: "280px" }}>
            <ServiceThumbnail
              Title={"Post Marriage Guidance"}
              LearnMore={"/postmarriage"}
            ></ServiceThumbnail>
          </ServiceThumbnailSmall>
          <ServiceThumbnailSmall sx={{ height: "280px" }}>
            <ServiceThumbnail
              Title={"Trans National Family Support"}
              LearnMore={"/transnational"}
            ></ServiceThumbnail>
          </ServiceThumbnailSmall>
        </ServiceColumn>
      </ServiceWrapper>
    </ServiceContainer>
  );
}
