import { Box, Hidden, List, styled, Typography } from "@mui/material";
import "@fontsource/heebo";

export const ProfileSearchContainer = styled(Box)(({ theme }) => ({
  display: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingBottom: "10px",
  background: "#FFFFFF",
  // backgroundImage: 'url(../images/login/PetalsBG.png)',
  backgroundSize: "cover",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
  },
}));

export const AdminContainer = styled(Box)(({ theme }) => ({
    display: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '15px 10px',
    background: '#FFFFFF',
    // backgroundImage: 'url(../images/login/PetalsBG.png)',
    backgroundSize: "cover",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      padding: "5px 1px",
      justifyContent: "center",
    },
  }));

export const SearchHeader = styled(Box)(({ theme }) => ({
  display: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "auto",
  height: "100%",
  margin: "0px 4px 20px 2px",
  padding: "2px 4px",
  background: "#FFFFFF",
  backgroundSize: "cover",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
  },
}));

export const SearchBodyContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "auto",
  // padding: '40px 10px',
  // marginLeft: "40px",
  // marginRight: "40px",
  // backgroundImage: 'url(../images/login/PetalsBG.png)',
  backgroundSize: "cover",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
  },
}));

export const SearchFilterContainer = styled(Box)(({ theme }) => ({
  display: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  padding: "5px 5px",
  background: "#F7F7F7",
  // backgroundImage: 'url(../images/login/PetalsBG.png)',
  backgroundSize: "cover",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
  },
}));
export const SearchCardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "top",
  alignItems: "center",
  width: "100%",
  height: "100%",
  // padding: '5px 5px',
  // backgroundImage: 'url(../images/login/PetalsBG.png)',
  backgroundSize: "cover",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
  },
}));

export const MyProfilePage = styled(Box)(({ theme }) => ({
  display: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  background: "#FFFFFF",
  // backgroundImage: 'url(../images/login/PetalsBG.png)',
  backgroundSize: "cover",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
  },
}));
