import {
  Box,
  // ThemeProvider,
  FormControlLabel,
  Checkbox,
  Typography,
  TablePagination,
} from "@mui/material";
import Label from "../Label";
import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
// import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PersonOffIcon from "@mui/icons-material/PersonOff";
// import SendIcon from "@mui/icons-material/Send";
// import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Popover from "@mui/material/Popover";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputBase,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import api from "../../services/RegisterService";
import Notification from "../Notification";
import { useAuth } from "../../contexts/AuthContext";
import { parseISO } from "date-fns";
import { format } from "date-fns-tz";
import { useSelector } from "react-redux";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#FFF9F8",
    fontSize: "50px",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
  },
  tableHeaderCell: {
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "heebo",
    backgroundColor: "#F9C6BC",
    padding: 10,
    wordWrap: "none",
  },

  tableBodyCell: {
    fontSize: "13px",
    fontFamily: "heebo",
    padding: 5,
  },

  labelStyle: {
    fontWeight: 700,
    fontSize: "15px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    width: "100%",
  },
}));

function Messages() {
  const classes = useStyles();
  const [query, setQuery] = useState("");
  // const [checked, setChecked] = useState(true);
  const [newChecked, setNewChecked] = useState(true);
  const [completedChecked, setCompletedChecked] = useState(false);
  const [editUserId, setEditUserId] = useState(null);
  const [anchorElement, setAnchorElement] = React.useState(null);
  const open = Boolean(anchorElement);
  // const id = open ? "simple-popover" : undefined;
  const [message, setMessages] = useState([]);
  // const [regId, setRegId] = useState();
  const [selectedReg, setSelectedReg] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });
  // const { signup } = useAuth();
  const [loading, setLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const { name, userId, token } = useSelector(
    (state) => state.userPersistedDetails
  );

  function convertCreatedDate(date) {
    const time = date;
    const parsedTime = parseISO(time);
    // console.log(parsedTime); // 2019-10-25T08:10:00.000Z
    const formattedTime = format(parsedTime, "dd/MM/yyyy");
    return formattedTime;
  }

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        let headers = { authorization: `${token}` };
        const response = await api.get("/contactusdetails", {
          headers: headers,
        });
        setMessages(response.data);
        const initalCount = response.data.filter((item) =>
          item.status.includes("N")
        );
        setTotalRecord(initalCount.length);
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else {
          console.log("Error", err.message);
        }
      }
    };
    fetchMessages();
  }, [refreshData]);

  const handleMoreClick = (event, messages) => {
    event.preventDefault();
    setAnchorElement(event.currentTarget);
    setSelectedReg(messages);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleNewChange = (event) => {
    setNewChecked(!newChecked);
  };
  const handleCompletedChange = (event) => {
    setCompletedChecked(!completedChecked);
  };

  const handleComplete = (event) => {
    event.preventDefault();
    let completeFlag = "C";
    handleClose();
    updateMessages(selectedReg, completeFlag);
    setRefreshData(!refreshData);
  };

  async function updateMessages(data, flag) {
    try {
      let headers = {
        authorization: `${token}`,
      };
      let url = "/contactusdetails/" + data.id;
      const response = await api.put(
        url,
        {
          status: flag,
          updatedUser: userId,
        },
        { headers: headers }
      );
      setNotify({
        isOpen: true,
        message: "Task Completed Successfully",
        type: "success",
      });
      setRefreshData(!refreshData);
      return response.data;
    } catch (err) {
      console.log(err);
      setNotify({
        isOpen: true,
        message: "Task Update Failed",
        type: "error",
      });
    }
  }

  // async function createUser(data) {
  //   try {
  //     handleClose();
  //     setLoading(true);
  //     let url = "/userdetails";
  //     const response = await api.post(url, {
  //       userName: data.name,
  //       regId: data.id,
  //       userEmail: data.emailId,
  //       regStatus: "A",
  //       userRole: "U",
  //       expiryDate: "2022-12-30",
  //       profileId: "",
  //       monthlyQuota: 0,
  //       usedQuota: 0,
  //       balanceQuota: 0,
  //       lockStatus: "N",
  //       status: "A",
  //       createdUser: name,
  //       // password: data.password,
  //       phoneNumber: "+" + data.countryPrefix + data.mobileNo,
  //     });
  //     updateRegistration(selectedReg, "A");
  //     setNotify({
  //       isOpen: true,
  //       message: response.data,
  //       type: "success",
  //     });

  //     setLoading(false);

  //     // return response.data;
  //   } catch (err) {
  //     console.log("User Creation Error:", err);
  //     setNotify({
  //       isOpen: true,
  //       message: err.message,
  //       type: "error",
  //     });
  //   }
  // }

  const filterData = () => {
    const appliedFilterdata = MESSAGES.filter(
      (message) =>
        message.userName.toLowerCase().includes(query) &&
        ((newChecked ? message.status.includes("N") : "") ||
          (completedChecked ? message.status.includes("C") : ""))
    );
    return appliedFilterdata;
  };

  useEffect(() => {
    const res = filterData();
    setTotalRecord(res.length);
    setPage(0);
  }, [newChecked, completedChecked]);

  const MESSAGES = [...message];
  // const ITEM_HEIGHT = 48;
  // const options = ["Activate", "Edit"];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        <InputBase
          sx={{
            ml: 1,
            flex: 1,
            fontSize: "15px",
            // mb: 2,
            border: "solid",
            px: 2,
            borderColor: "#F7F7F7",
          }}
          placeholder="Search...."
          inputProps={{ "aria-label": "search google maps" }}
          id="searchprofileid"
          onChange={(e) => setQuery(e.target.value.toLowerCase())}
        />

        <IconButton
          type="submit"
          sx={{ p: "10px" }}
          aria-label="search"
          disableRipple
        >
          <SearchIcon />
        </IconButton>
        <FormControlLabel
          value="new"
          control={<Checkbox checked={newChecked} onChange={handleNewChange} />}
          label="New"
          labelPlacement="end"
          sx={{ justifyContent: "right", fontSize: "0.7em" }}
        />
        <FormControlLabel
          value="completed"
          control={
            <Checkbox
              checked={completedChecked}
              onChange={handleCompletedChange}
            />
          }
          label="Completed"
          labelPlacement="end"
          sx={{ justifyContent: "right", fontSize: "0.7m" }}
        />
        {/* <FormControlLabel
          value="inactive"
          control={
            <Checkbox
              checked={inactiveChecked}
              onChange={handleRejectedChange}
            />
          }
          label="Rejected"
          labelPlacement="end"
          sx={{ justifyContent: "right", fontSize: "0.7em" }}
        /> */}
        <IconButton
          type="submit"
          color="secondary"
          sx={{ p: "10px" }}
          aria-label="search"
          onClick={() => {
            setRefreshData(!refreshData);
          }}
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="regtable">
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.tableHeaderCell}
                sx={{ textAlign: "center" }}
              >
                Message ID
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>Name</TableCell>
              <TableCell className={classes.tableHeaderCell}>Email</TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Phone No.
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>Subject</TableCell>
              <TableCell className={classes.tableHeaderCell}>Message</TableCell>
              <TableCell className={classes.tableHeaderCell}>Status</TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Created Date
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody key={String(refreshData)}>
            {filterData().slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ).length > 0 &&
              filterData()
                .sort((a, b) => (a.dateCr < b.dateCr ? 1 : -1))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((message, index) => (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      className={classes.tableBodyCell}
                      sx={{ textAlign: "center" }}
                    >
                      {message.id}
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      {message.userName}
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      {message.userEmailId}
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      {message.countryPrefix}-{message.mobileNo}
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      {message.subject}
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      {message.message}
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      {message.status === "N" ? (
                        <Label
                          variant="ghost"
                          color="info"
                          sx={{
                            fontSize: "12px",
                            py: "5px",
                            px: "15px",
                            borderRadius: 5,
                          }}
                        >
                          New
                        </Label>
                      ) : message.status === "C" ? (
                        <Label
                          variant="ghost"
                          color="success"
                          sx={{
                            fontSize: "12px",
                            py: "5px",
                            px: "15px",
                            borderRadius: 5,
                          }}
                        >
                          Completed
                        </Label>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      {convertCreatedDate(message.dateCr)}
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}>
                      <IconButton
                        onClick={(event) => handleMoreClick(event, message)}
                        disabled={message.status === "C" ? true : false}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Popover
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        PaperProps={{
                          sx: {
                            p: 1,
                            width: "auto",
                            elevation: 1,
                            boxShadow: "0 2px 7px rgba(0, 0, 0, 0.2)",
                          },
                        }}
                        open={Boolean(open)}
                        anchorEl={anchorElement}
                        onClose={handleClose}
                      >
                        <>
                          <MenuItem onClick={handleComplete} disabled={loading}>
                            <Typography
                              sx={{
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <HowToRegIcon
                                fontSize="small"
                                color="secondary"
                                sx={{ pr: 2 }}
                              />
                              Complete Task
                            </Typography>
                          </MenuItem>

                          {/* <MenuItem onClick={handleReject} disabled={loading}>
                            <Typography
                              sx={{
                                fontSize: "15   px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <PersonOffIcon
                                fontSize="small"
                                color="secondary"
                                sx={{ pr: 2 }}
                              />
                              Reject
                            </Typography>
                          </MenuItem> */}
                        </>
                      </Popover>
                    </TableCell>
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
        {filterData().slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        ).length > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={totalRecord}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
}

export default Messages;
