import { Box, Hidden, List, styled, Typography } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import React from 'react';
import { Paper } from '@mui/material';

export const LandingTitle = styled(Typography)(({theme}) => ({
    lineHeight: 1.5,
    fontSize: '50px',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
        fontSize: '40px'
    }

}));

export const LandingDescription = styled(Typography)(({theme}) => ({
    lineHeight: 1.25,
    letterSpacing: 1.25,
    marginBottom: '3em',
    [theme.breakpoints.down('md')]: {
        lineHeight: 1.15,
        letterSpacing: 1.15,
        marginBottom: '1.5em'
    }

}));

export const LandingColumn = styled(Box)(({ theme }) => ({

    display: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'auto',
    // background: '#00DE34',
    fontFamily: 'heebo',
    backgroundSize: 'cover',
    overflow: 'hidden',
    padding: '10px 10px',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
        padding: '5px 1px',
        justifyContent: 'center'

    }
}));

export const Circle = styled(Box)(({ theme }) => ({

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '125px',
    height: '125px',
    borderRadius: "50%",
    background: 'linear-gradient(to right bottom, #F9C6BC, #FF907A)',
    fontFamily: 'heebo',
    fontSize: "40px",
    fontWeight: 100,
    marginRight:"40px",
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
        padding: '5px 1px',
        justifyContent: 'center'

    }
}));
