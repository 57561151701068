import { Paper, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { LoginContainer } from "../../styles/login";
import { LoginForm } from "../../sections/auth/login";
import { Box } from "@mui/material";
import "@fontsource/heebo";
// import Image from "../Image";
import ColorBar from "../ColorBar";
// import { Link } from "react-router-dom";

export default function LoginBody() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <LoginContainer>
      <Box
        align="justify"
        display={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          lineHeight: 2,
          textAlign: "justify",
          fontSize: 20,
          fontFamily: "heebo",
          pl: 20,
          pr: 20,
          pt: 0,
          pb: 0,
        }}
      >
        <ColorBar />
        <Typography
          sx={{
            fontFamily: "heebo",
            fontSize: 30,
            fontWeight: 700,
            marginBottom: "40px",
          }}
        >
          Login
        </Typography>

        <Box sx={{ pl: "30%", pb: 20, pr: "30%", backgroundColor: "none" }}>
          <LoginForm />
        
        </Box>

        <Box display="flex" sx={{ justifyContent: "flex-end", mt: 10 }}>
          
        </Box>
      </Box>
    </LoginContainer>
  );
}
