import { Box, Hidden, List, styled, Typography } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
// import Image from '../src/images/banner.png';
// import React from 'react';
// import { Paper } from '@mui/material';

// const styles = {
//     paperContainer: {
//         backgroundImage: `url(${Image})`
//     }
// };

// export default class Home extends React.Component{
//     render(){
//         return(
//             <Paper style={styles.paperContainer}>
//                 Some text to fill the Paper Component
//             </Paper>
//         )
//     }
// }
// export { default as CarouselSection } from '../../components/carousel/CarouselSection';
export const BannerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "right",
  width: "auto",
  height: "100%",
  padding: "50px 10px",
  background: "#F5F5DC",
  backgroundImage: "url(../images/banner/banner.png)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
  },
}));

export const BannerImage = styled("img")(({ src, theme }) => ({
  position: "absolute",
  src: "url(${src})",
  zIndex: 1,
  marginTop: "0px",
  right: 0,
  width: "100%",
}));
export const BannerContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  maxWidth: 500,
  padding: "30px",
  alignItems: "right",
  zIndex: 100,
  background: "white",
  borderRadius: 20,
  marginTop: "20px",
  marginRight: "40px",
  [theme.breakpoints.down("md")]: {
    display: "column",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: 500,
    padding: "20px",
    alignItems: "right",
    zIndex: 100,
    background: "white",
    borderRadius: 20,
    marginTop: "20px",
    marginRight: "20px",
    marginLeft: "20px",
    marginBottom: "20px",
  },
}));

export const BannerTitle = styled(Typography)(({ theme }) => ({
  lineHeight: 1.5,
  fontSize: "50px",
  marginBottom: "10px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "40px",
  },
}));

export const BannerDescription = styled(Typography)(({ theme }) => ({
  lineHeight: 1.25,
  letterSpacing: 1.25,
  marginBottom: "3em",
  [theme.breakpoints.down("md")]: {
    lineHeight: 1.15,
    letterSpacing: 1.15,
    marginBottom: "1.5em",
  },
}));

export const CarouselContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  maxWidth: 400,
  padding: "20px",
  alignItems: "right",
  zIndex: 100,
  background: "linear-gradient(90deg, #FFEBD2 0%, #FDB8A0 100%)",
  borderRadius: 20,
  marginRight: "40px",
  marginBottom: "20px",
  [theme.breakpoints.down("md")]: {
    lineHeight: 1.15,
    letterSpacing: 1.15,
    marginBottom: "1.5em",
    alignItems: "center",
    padding: "20px",
    marginRight: "20px",
    marginLeft: "20px",
    marginTop: "20px",
  },
}));

export const CarouselInner = styled(Box)(({ theme }) => ({
  display: "column",
  height: "100%",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    display: "column",
    height: "100%",
    width: "100%",
  },
}));

export const BannerContentGroup = styled(Box)(({ theme }) => ({
  display: "column",
  // paddingTop: "10%",
  justifyContent: "centre",
  verticalAlign: "center",
  // marginRight: '20px',
  height: "100%",
  width: "450px",
  padding: "0px 0px",
  border: 2,
  zIndex: 10,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
}));
