import { Box, Typography } from "@mui/material";
import React from "react";
import ColorBar from "../ColorBar";

export default function ServiceText({ Title, Desc }) {
  return (
    <Box sx={{ textAlign: "justify" }}>
      <ColorBar />
      <Typography variant="h4" sx={{ fontFamily: "heebo", mt: 5, mb: 4 }}>
        {Title}
      </Typography>
      <Typography sx={{ fontSize: 16 }}>{Desc}</Typography>
    </Box>
  );
}
