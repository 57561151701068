import { FamilyRestroom } from "@mui/icons-material";
import { Snackbar, Alert, Typography, Slide } from "@mui/material";
import React from "react";

export default function Notification(props) {
  const { notify, setNotify } = props;
  const handleClose = (event, reason) => {
    setNotify({
      ...notify,
      isOpen: false,
    });
    if (reason === "clickaway") {
      return;
    }
  };

  function Transition(props) {
    return <Slide {...props} direction="up" />;
  }

  const [transition, setTransition] = React.useState(undefined);
  return (
    <Snackbar
      open={notify.isOpen}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Alert variant="filled" severity={notify.type} onClose={handleClose}>
        <Typography variant="h7">{notify.message} </Typography>
      </Alert>
    </Snackbar>
  );
}
