import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  RegisterContainer,
  RegisterInnerContainer,
} from "../../styles/register";
import { RegisterFullForm } from "../../sections/auth/register";
import { Box } from "@mui/material";
import "@fontsource/heebo";
import Image from "../Image";
import { Link } from "react-router-dom";
import ColorBar from "../ColorBar";

export default function RegisterBody(data) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <RegisterContainer>
      <RegisterInnerContainer>
        <Box
          align="justify"
          display={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            lineHeight: 2,
            textAlign: "justify",
            fontSize: 20,
            fontFamily: "heebo",
            pl: { xl: 20, md: 10 },
            pr: { xl: 10, md: 10 },
            pt: 0,
            pb: 0,
          }}
        >
          <ColorBar />
          <Typography
            sx={{
              fontFamily: "heebo",
              fontSize: 30,
              fontWeight: 700,
              marginBottom: "40px",
            }}
          >
            Registration
          </Typography>
          <Box
            display={matches ? "none" : "flex"}
            sx={{
              my: 4,
              mx: 2,
              color: "darkred",
              alignItems: "center",
              backgroundColor: "rgba(249, 198, 188, 0.4)",
              borderRadius: "20px",
              justifyContent: "center",
              fontSize: "15px",
            }}
          >
            Already have an account?
            <Box sx={{ mx: 2 }}>
              <Link
                to="/login"
                sx={{
                  color: "white",
                }}
              >
                Login
              </Link>
            </Box>
          </Box>
          <Box sx={{ backgroundColor: "none" }}>
            <RegisterFullForm />
          </Box>
        </Box>
      </RegisterInnerContainer>
      <RegisterInnerContainer
        display={"grid"}
        grid-template-columns="repeat(4, auto)"
        justify-content="start"
      >
        <Image src="../images/register/roses.png"></Image>
      </RegisterInnerContainer>
    </RegisterContainer>
  );
}
