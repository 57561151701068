import {
  Box,
  // ThemeProvider,
  FormControlLabel,
  Checkbox,
  Typography,
  TablePagination,
} from "@mui/material";
import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
// import LockIcon from "@mui/icons-material/Lock";
// import LockOpenIcon from "@mui/icons-material/LockOpen";
import Popover from "@mui/material/Popover";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputBase,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
// import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import api from "../../services/RegisterService";
import { useNavigate } from "react-router-dom";
import { applyEditUserId } from "../../features/myProfileSlice";
import { useDispatch } from "react-redux";
import Notification from "../Notification";
import { useSelector } from "react-redux";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#FFF9F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
  },
  tableHeaderCell: {
    fontWeight: 700,
    fontFamily: "heebo",
    color: "#000000",
    backgroundColor: "#F9C6BC",
    padding: 8,
  },

  tableBodyCell: {
    fontSize: "13px",
    fontFamily: "heebo",
    padding: 8,
  },
}));

function UserList() {
  const classes = useStyles();
  const [query, setQuery] = useState("");
  // const [checked, setChecked] = useState(true);
  const [activeChecked, setActiveChecked] = useState(true);
  const [inactiveChecked, setInactiveChecked] = useState(true);
  // const [editUserId, setEditUserId] = useState(null);
  const [anchorElement, setAnchorElement] = React.useState(null);
  const open = Boolean(anchorElement);
  // const id = open ? "simple-popover" : undefined;
  const [users, setUsers] = useState([]);
  const USERS = [...users];
  let navigate = useNavigate();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isNotSelectedUser, setIsNotSelectedUser] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const dispatch = useDispatch();
  const { name, userId, token } = useSelector(
    (state) => state.userPersistedDetails
  );
  // const axiosPrivate = useAxiosPrivate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  async function updateUser(data, flag) {
    handleClose();
    try {
      // console.log(data);
      let headers = { authorization: `${token}` };
      let url = "/userdetails/" + data.userId;
      const response = await api.put(
        url,
        {
          ...data,
          updatedUser: userId,
          status: flag,
        },
        { headers: headers }
      );
      setNotify({
        isOpen: true,
        message: flag === "A" ? "User Activated" : "User Deactivated",
        type: "success",
      });
      navigate("/userlist");
      return response.data;
    } catch (err) {
      console.log(err);
      setNotify({
        isOpen: true,
        message: "User Update Failed",
        type: "error",
      });
    }
  }

  const filterData = () => {
    const appliedFilterdata = USERS.filter(
      (user) =>
        user.userName.toLowerCase().includes(query) &&
        ((activeChecked ? user.status.includes("A") : "") ||
          (inactiveChecked ? user.status.includes("I") : ""))
    );
    return appliedFilterdata;
  };

  useEffect(() => {
    const res = filterData();
    setTotalRecord(res.length);
    setPage(0);
  }, [activeChecked, inactiveChecked]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        let headers = {
          authorization: `${token}`,
        };
        const response = await api.get("/userdetails", { headers: headers });
        setUsers(response.data);
        const initalCount = response.data.filter((item) =>
          item.status.includes("A")
        );
        setTotalRecord(initalCount.length);
        //console.log("Response", response.data);
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else {
          console.log("Error", err.message);
        }
      }
    };
    fetchUsers();
  }, [isRefresh]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleActiveChange = (event) => {
    setActiveChecked(!activeChecked);
  };
  const handleInactiveChange = (event) => {
    setInactiveChecked(!inactiveChecked);
  };

  const handleMoreClick = (event, user) => {
    setIsNotSelectedUser(false);
    event.preventDefault();
    setAnchorElement(event.currentTarget);
    setSelectedUserId(user);
    // console.log("Selected Registration:", selectedUserId);
  };

  const createNewProfile = (event) => {
    if (
      !(selectedUserId.profileId !== null && selectedUserId.profileId !== "")
    ) {
      navigate("/newprofile", { state: { selectedUserId } });
    }
  };

  const handleEditClick = () => {
    // console.log(selectedUserId.userId);
    dispatch(applyEditUserId(selectedUserId.userId));
    navigate("/edituser");
  };
  const activateUser = (event) => {
    // console.log("Activate User Clicked from UserId:", selectedUserId);
    if (selectedUserId.status === "A") {
      setNotify({
        isOpen: true,
        message: "User is Already Active",
        type: "info",
      });
    } else {
      updateUser(selectedUserId, "A");
      setIsRefresh(!isRefresh);
    }
  };

  const deactivateUser = (event) => {
    // console.log("Deactivate User Clicked from UserId:", selectedUserId);
    if (selectedUserId.status === "I") {
      setNotify({
        isOpen: true,
        message: "User is Already Inactive",
        type: "info",
      });
    } else {
      updateUser(selectedUserId, "I");
      setIsRefresh(!isRefresh);
    }
  };

  // const ITEM_HEIGHT = 48;
  // const options = ["Activate", "Edit"];
  // console.log("Active: ", activeChecked);
  // console.log("Inactive: ", inactiveChecked);
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        <InputBase
          sx={{
            ml: 1,
            flex: 1,
            fontSize: "20px",
            mb: 2,
            border: "solid",
            px: 2,
            borderColor: "#F7F7F7",
          }}
          placeholder="Search...."
          inputProps={{ "aria-label": "search google maps" }}
          id="searchprofileid"
          onChange={(e) => setQuery(e.target.value.toLowerCase())}
        />

        <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <FormControlLabel
          value="active"
          control={
            <Checkbox checked={activeChecked} onChange={handleActiveChange} />
          }
          label="Active"
          labelPlacement="end"
        />
        <FormControlLabel
          value="inactive"
          control={
            <Checkbox
              checked={inactiveChecked}
              onChange={handleInactiveChange}
            />
          }
          label="Inactive"
          labelPlacement="end"
        />
        <IconButton
          type="submit"
          color="secondary"
          sx={{ p: "10px" }}
          aria-label="search"
          onClick={() => {
            setIsRefresh(!isRefresh);
          }}
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          // key={String(isRefresh)}
          sx={{ minWidth: 650 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.tableHeaderCell}
                sx={{ textAlign: "center" }}
              >
                User ID
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                User Name
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>Email</TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Profile Id
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                User Status
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Expiry Date
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>Role</TableCell>
              {/* <TableCell className={classes.tableHeaderCell}>
                Account Locked?
              </TableCell> */}
              <TableCell className={classes.tableHeaderCell}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody key={String(isRefresh)}>
            {filterData().slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ).length > 0 &&
              filterData()
                .sort((a, b) => (a.dateCr < b.dateCr ? 1 : -1))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user, index) => (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className={classes.tableRow}
                  >
                    <TableCell
                      align="left"
                      className={classes.tableBodyCell}
                      sx={{ textAlign: "center" }}
                    >
                      {user.userId}
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      {user.userName}
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      {user.userEmail}
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      {" "}
                      {user.profileId}
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      <Switch
                        color="success"
                        checked={user.status === "A" ? true : false}
                        readOnly
                      ></Switch>
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      {user.expiryDate}
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      {user.userRole === "U"
                        ? "User"
                        : user.userRole === "A"
                        ? "Admin"
                        : "Unknown"}
                    </TableCell>
                    {/* <TableCell align="center">
                  {user.lockStatus === "Y" ? (
                    <div>
                      <LockIcon color="error" />
                    </div>
                  ) : (
                    <div>
                      <LockOpenIcon color="success" />
                    </div>
                  )}
                </TableCell> */}
                    <TableCell className={classes.tableBodyCell}>
                      <IconButton
                        // aria-label="more"
                        // id="long-button"
                        // aria-controls={open ? "long-menu" : undefined}
                        // aria-expanded={open ? "true" : undefined}
                        // aria-haspopup="true"
                        onClick={(event) => handleMoreClick(event, user)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Popover
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        PaperProps={{
                          sx: {
                            p: 1,
                            width: "auto",
                            // overflow: "inherit",
                            elevation: 1,
                            boxShadow: "0 2px 7px rgba(0, 0, 0, 0.2)",
                          },
                        }}
                        open={Boolean(open)}
                        anchorEl={anchorElement}
                        onClose={handleClose}
                      >
                        <>
                          <MenuItem onClick={activateUser}>
                            <Typography
                              sx={{
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <ToggleOnIcon
                                color="secondary"
                                fontSize="small"
                                sx={{ pr: 2 }}
                              />
                              Activate
                            </Typography>
                          </MenuItem>
                          <MenuItem onClick={deactivateUser}>
                            <Typography
                              sx={{
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <ToggleOffIcon
                                color="secondary"
                                fontSize="small"
                                sx={{ pr: 2 }}
                              />
                              Deactivate
                            </Typography>
                          </MenuItem>

                          <MenuItem onClick={handleEditClick}>
                            <Typography
                              sx={{
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <EditIcon
                                color="secondary"
                                fontSize="small"
                                sx={{ pr: 2 }}
                              />
                              Edit
                            </Typography>
                          </MenuItem>
                          {/* for visibility only edit or create button */}
                          {isNotSelectedUser ||
                          (selectedUserId.profileId !== null &&
                            selectedUserId.profileId !== "") ? (
                            ""
                          ) : (
                            <MenuItem onClick={createNewProfile}>
                              <Typography
                                sx={{
                                  fontSize: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <PersonAddIcon
                                  color="secondary"
                                  fontSize="small"
                                  sx={{ pr: 2 }}
                                />
                                New Profile
                              </Typography>
                            </MenuItem>
                          )}
                        </>
                      </Popover>
                    </TableCell>
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
        {filterData().slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        ).length > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={totalRecord}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
      <Notification notify={notify} setNotify={setNotify} />
      {/* <DataGrid rows={rows} columns={columns} /> */}
    </Box>
  );
}

export default UserList;
