import { ThemeProvider } from "@mui/material";
import { Container } from "@mui/system";
import Menubar from "./components/menubar";
import theme from "./styles/theme";
import Footer from "./components/footer";
import RegistrationListBody from "./components/registration";
import MessagesBody from "./components/messages";

function AdminMessages() {
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          display: "column",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Menubar></Menubar>
        <MessagesBody></MessagesBody>
        <Footer></Footer>
      </Container>
    </ThemeProvider>
  );
}
export default AdminMessages;
