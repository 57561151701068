import { Box, Typography } from "@mui/material";
import React from "react";

function TermsandConditions() {
  const style1 = {
    mt: 2,
    fontSize: "14px",
    textAlign: "justify",
  };
  const style2 = {
    mt: 2,
    fontSize: "15px",
    textAlign: "justify",
  };
  return (
    <>
      <Typography
        textAlign={"center"}
        sx={{
          fontFamily: "heebo",
          fontSize: 30,
          fontWeight: 700,
          marginBottom: "40px",
        }}
      >
        Terms and Conditions
      </Typography>
      <Typography id="modal-modal-description" sx={style1}>
        PLEASE READ THE TERMS & CONDITIONS CAREFULLY BEFORE USING THE WEBSITE
        (www.sgmuslimmatrimony.com) / WAP (Wireless Application Protocol) /APP
        (Mobile APP). ACCESSING, BROWSING OR OTHERWISE USING THE WEBSITE/WAP/APP
        IMPLIES YOUR AGREEMENT TO BE BOUND BY ALL THESE TERMS AND CONDITIONS
        ("Agreement").
      </Typography>
      <Typography sx={style1}>
        <p>
          This document is an electronic record in terms of the Information
          Technology Act, and rules there under pertaining to electronic records
          as applicable and amended. This electronic record is generated by a
          computer system and does not require any physical or digital
          signatures. The terms and conditions may be changed and/or altered by
          SG Muslim Matrimony from time to time at its sole discretion.
        </p>
      </Typography>
      <Typography sx={style1}>
        In order to use the SG Muslim Matrimony Service you must register as a
        member on the Website/ community sites/ mobile applications ("Site"). If
        you wish to become a Registrant and promote/ advertise your profile to
        other users and make use of the SG Muslim Matrimony Service, read these
        Terms of Use and follow the instructions in the registration process.
        Registrant also includes persons such as father, mother, brother,
        sister, relative or a friend ("Registrant") of the prospective bride/
        bridegroom. By using the Service, you consent to submit your personal
        information & to authorise, agree & consent to collect, process, display
        and use the said information to SG Muslim matrimony to provide the
        Service and if you have any objection in collecting/processing your
        personal data, we advise you not to register with our Site.
      </Typography>
      <Typography sx={style1}>
        SG Muslim Matrimony acts as a platform to enable any user to register on
        it (upon approval from admin of SG Muslim Matrimony) and will be able to
        access their own profile and to voluntarily search for profile(s) from
        the database of SG Muslim Matrimony's already registered users, for
        seeking prospective lawful matrimonial alliances for themselves. To
        Register your profile, you must have an unique email ID for each profile
        registration and valid/operational mobile phone number. Email ID used
        for Registration for one profile cannot be used for registering another
        profile.
      </Typography>
      <Typography sx={style1}>
        The profiles in the database of SG Muslim Matrimony are classified on
        basis of Global & Singapore (SG) profiles for the ease and convenience
        of its SG Muslim Matrimony members. Members are provided with paid
        access for searching profiles from the database of SG Muslim Matrimony,
        as per the partner preference & Expectations set by you (on the
        Website/WAP/APP and you shortlist the profiles in which you are
        interested.
      </Typography>
      <Typography sx={style2}>
        <p>
          <strong>1. Eligibility: </strong>
        </p>
      </Typography>
      <Typography sx={style2}>
        SG Muslim Matrimony Profile Registration is reserved solely for:
      </Typography>
      <Typography sx={style2}>A. Muslim Men/ Muslim Women</Typography>
      <Typography sx={style2}>
        B. Unmarried Man/Unmarried Women, Divorced/ Widowed.
      </Typography>
      <Typography sx={style2}>
        C. Must be 18years or above (for woman) or 21 years or above ( for Man)
      </Typography>
      <Typography sx={style2}>
        D. Legally abide to marry as per the law applicable to you.{" "}
      </Typography>
      <Typography sx={style2}>
        E. Not Prohibited or prevented as per any applicable law.
      </Typography>
      <Typography sx={style2}>
        F. In case of Registrant is applying on behalf of Bride/Bridegroom,
        implies that you have taken their consent for their profile registration
        with SG Muslim Matrimony & for accessing SG Muslim Matrimony Website.
      </Typography>
      <Typography sx={style2}>
        G. If you are the resident or citizen of other country, you must be
        legally competent to marry as per the local rules applicable to your
        country and shall comply with the laws of the country of the person you
        are marrying to.
      </Typography>
      <p></p>
      <Typography sx={style2}>
        <p>
          <strong>2. Registration:</strong>
        </p>
      </Typography>
      <Typography sx={style2}>
        A. Each Profile must be renewed every 3 month. SG Muslim Matrimony has
        the right to block or remove the profiles which are not renewed after
        every 3 months.
      </Typography>
      <Typography sx={style2}>
        B. Registrant must be a responsible Muslim in using the information
        acquired from this website (SG Muslim Matrimony). Each and every
        participant must bear in mind that each one of them are accountable to
        Allah for our every deeds & actions.
      </Typography>
      <Typography sx={style2}>
        C. Misuse of data, any type of harassment will result in remove & block
        those who are responsible for such acts. SG Muslim Matrimony is not
        responsible for any such incident that occurs.
      </Typography>
      <Typography sx={style2}>
        D. Photographs of brides / bridegrooms will not be uploaded in our SG
        Muslim Matrimony website.
      </Typography>
      <Typography sx={style2}>
        E. If, We SG Muslim Matrimony come to know or is informed by third party
        or has reasons to believe that any information provided by you for
        registration is found to be untrue, inaccurate, or incomplete, We SG
        Muslim Matrimony shall have the full right to suspend or terminate
        (without any notice) your profile from our SG Muslim Matrimony database
        and forfeit any amount paid by you towards the Service fee and refuse to
        provide any Matrimony service to you thereafter.
      </Typography>
      <Typography sx={style2}>
        F. Registration of duplicate profiles of the same person is not allowed
        in SG Muslim Matrimony Website/WAP/APP.
      </Typography>
      <Typography sx={style2}>
        G. If you are the resident or citizen of other country, you must be
        legally competent to marry as per the local rules applicable to your
        country and shall comply with the laws of the country of the person you
        are marrying to.
      </Typography>
      <p></p>
      <Typography sx={style2}>
        <p>
          <strong>
            3. Do’s & Don’ts (Responsibilities) of Users/ Members:{" "}
          </strong>
        </p>
      </Typography>
      <Typography sx={style2}>
        A. You are responsible for safeguarding the confidentiality of your SG
        Muslim Matrimony login credentials such as your user id, password,
        Profile ID etc., and for restricting access to your computer/mobile to
        prevent unauthorized access to your account. You are cautioned not to
        share your password to any persons. You agree to accept responsibility
        for all activities that occur under your account.
      </Typography>
      <Typography sx={style2}>
        B. Users/ Members of SG Muslim Matrimony agrees that you will regularly
        log in to the profile maintained in the Website/App and update the admin
        of SG Muslim Matrimony if there is any change in your profiles details.
      </Typography>
      <Typography sx={style2}>
        C. Users/Members shall, in case of finalization of his/her/their
        Registrant's marriage, should intimate us for removal/deletion of their
        profile.
      </Typography>
      <Typography sx={style2}>
        D. Users / Members must refrain from: <br />
        1.1. Using of abusive language when communicating with other Users of SG
        Muslim Matrimony.
        <br /> 1.2. Using Racial Comments or discriminative remarks towards
        other users. <br />
        1.3. Sharing of confidential and personal data with each other. <br />
        1.4. Sharing of other profiles details without the consent of that
        concerned profile.
        <br />
        1.5. Violating any law for the time being in force. <br />
        1.6. Contacting other Users/Members for monetory or commercial benefits
        other than the communication for matrimony purpose.
      </Typography>
      <Typography sx={style2}>
        E. Roles of SG Muslim Matrimony: <br />
        1.1. SG Muslim Matrimony is responsible for providing ONLY interface to
        its users (ONLY information sharing), to search their prospective
        profiles by themselves. We do not provide Match making Service.
        <br />
        1.2. SG Muslim Matrimony does not authenticate/endorse any information
        of any profile and hence you as a user need to verify the credentials
        and information provided by other users.
        <br />
        1.3. SG Muslim Matrimony will not authenticate for any Bride/Grooms
        status with regards to marital, Job. Mental, Family background etc.
      </Typography>
    </>
  );
}

export default TermsandConditions;
