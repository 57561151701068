import axios from "axios";

const USER_REST_API_URL = process.env.REACT_APP_BE_API_URL;

export default axios.create({
  baseURL: USER_REST_API_URL,
});

export const axiosPrivate = axios.create({
  baseURL: USER_REST_API_URL,
});
