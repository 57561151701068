import { createSlice } from "@reduxjs/toolkit";

const myProfileSlice = createSlice({
    name: 'myProfile',
    initialState: {
        selectedProfileId: "",
        selectedEditProfileid: "",
        selectedEditUserId: ""
    },
    reducers: {
        applySelectedProfileId(state, action) {
            state.selectedProfileId = action.payload;
        },
        applyEditProfileId(state, action) {
            state.selectedEditProfileid = action.payload;
        },
        applyEditUserId(state, action) {
            state.selectedEditUserId = action.payload;
        }
    },
});

export const { applySelectedProfileId, applyEditProfileId, applyEditUserId } = myProfileSlice.actions;
export default myProfileSlice.reducer;