import { Box, Hidden, List, styled, Typography } from "@mui/material";
import "@fontsource/heebo";

export const CardHeader = styled(Box)(({ theme }) => ({
  display: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "135px",
  padding: "20px 0px",
  background: "#ED819B",
  backgroundSize: "cover",
  borderRadius: "20px 20px 0px 0px",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 1px",
    justifyContent: "center",
  },
}));
