import {
  Box,
  Checkbox,
  FormControlLabel,
  TablePagination,
} from "@mui/material";
import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Edit";
import Popover from "@mui/material/Popover";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputBase,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
import api from "../../services/RegisterService";
import { countries } from "../../_mock/map";
import Notification from "../Notification";
import { useDispatch, useSelector } from "react-redux";
import { applyEditProfileId } from "../../features/myProfileSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const calculate_age = (dob1) => {
  var today = new Date();
  var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
  var age_now = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age_now--;
  }
  return age_now;
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#FFF9F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
  },
  tableHeaderCell: {
    fontWeight: 700,
    fontFamily: "heebo",
    color: "#000000",
    backgroundColor: "#F9C6BC",
  },

  tableBodyCell: {
    fontSize: "13px",
    fontFamily: "heebo",
    padding: 10,
  },
}));

function ProfileList() {
  const classes = useStyles();
  const [query, setQuery] = useState("");
  const [checked, setChecked] = useState("true");
  const [activeChecked, setActiveChecked] = useState(true);
  const [inactiveChecked, setInactiveChecked] = useState(true);
  const [anchorElement, setAnchorElement] = React.useState(null);
  const [profiles, setProfiles] = useState([]);
  const PROFILES = [...profiles];
  const open = Boolean(anchorElement);
  const id = open ? "simple-popover" : undefined;
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const navigate = useNavigate();
  const { userId, token } = useSelector((state) => state.userPersistedDetails);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });

  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleActiveChange = (event) => {
    setActiveChecked(!activeChecked);
  };
  const handleInactiveChange = (event) => {
    setInactiveChecked(!inactiveChecked);
  };

  const handleClick = (event, profile) => {
    setAnchorElement(event.currentTarget);
    setSelectedProfile(profile);
  };

  const handleClose = () => {
    setAnchorElement(null);
    setRefreshData(!refreshData);
  };

  const editProfile = (event) => {
    if (
      selectedProfile.profileId !== null &&
      selectedProfile.profileId !== ""
    ) {
      dispatch(applyEditProfileId(selectedProfile.profileId));
      navigate("/editprofile");
    }
  };

  const activateProfile = (event) => {
    event.preventDefault();
    handleClose();
    if (selectedProfile.status === "A") {
      setNotify({
        isOpen: true,
        message: "Profile is Already Active",
        type: "info",
      });
    } else {
      updateProfile(selectedProfile, "A");
      setRefreshData(!refreshData);
    }
  };

  const deactivateProfile = (event) => {
    event.preventDefault();
    handleClose();
    if (selectedProfile.status === "I") {
      setNotify({
        isOpen: true,
        message: "Profile is Already Inactive",
        type: "info",
      });
    } else {
      updateProfile(selectedProfile, "I");
      setRefreshData(!refreshData);
    }
  };

  async function updateProfile(data, flag) {
    try {
      let headers = { authorization: `${token}` };
      let url = "/profiledetails/" + data.profileId;
      const response = await api.put(
        url,
        {
          ...data,
          updatedUser: userId,
          status: flag,
        },
        { headers: headers }
      );
      setNotify({
        isOpen: true,
        message: flag === "A" ? "Profile Activated" : "Profile Deactivated",
        type: "success",
      });

      return response.data;
    } catch (err) {
      console.log(err);
      setNotify({
        isOpen: true,
        message: "Profile Update Failed",
        type: "error",
      });
    }
  }

  const filterData = () => {
    const appliedFilterdata = PROFILES.filter(
      (profile) =>
        ((activeChecked ? profile.status.includes("A") : "") ||
          (inactiveChecked ? profile.status.includes("I") : "")) &&
        (profile.profilename.toLowerCase().includes(query) ||
          profile.profileId.toLowerCase().includes(query))
    );
    return appliedFilterdata;
  };

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        let headers = {
          authorization: `${token}`,
        };

        const response = await api.get(`/profiledetails?userRole=A`, { headers: headers });

        // const response = await api.get(`/profiledetails`, config);
        // const response = await axios({
        //   method: "get",
        //   url: "https://theindianalgorithm.online/app/api/profiledetails",
        //   headers: { "x-auth-token": `${token}` },
        // });

        setProfiles(response.data);
        const initalCount = response.data.filter((item) =>
          item.status.includes("A")
        );
        setTotalRecord(initalCount.length);
      } catch (err) {
        if (err.response) {
          console.log("Error", err);
          console.log("Response Data", err.response.data);
          console.log("Response status", err.response.status);
          console.log("Response headers", err.response.headers);
        } else if (err.request) {
          console.log("request error", err);
        } else {
          console.log("Error", err.message);
        }
      }
    };
    fetchProfiles();
  }, [refreshData]);

  useEffect(() => {
    const res = filterData();
    setTotalRecord(res.length);
    setPage(0);
  }, [activeChecked, inactiveChecked]);

  const ITEM_HEIGHT = 48;
  const options = ["Activate", "Edit"];
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        <InputBase
          sx={{
            ml: 1,
            flex: 1,
            fontSize: "15px",
            mb: 2,
            border: "solid",
            px: 2,
            borderColor: "#F7F7F7",
          }}
          placeholder="Search...."
          inputProps={{ "aria-label": "search google maps" }}
          id="searchprofileid"
          onChange={(e) => setQuery(e.target.value.toLowerCase())}
        />

        <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <FormControlLabel
          value="active"
          control={
            <Checkbox checked={activeChecked} onChange={handleActiveChange} />
          }
          label="Active"
          labelPlacement="end"
        />
        <FormControlLabel
          value="inactive"
          control={
            <Checkbox
              checked={inactiveChecked}
              onChange={handleInactiveChange}
            />
          }
          label="Inactive"
          labelPlacement="end"
        />
        <IconButton
          type="submit"
          color="secondary"
          sx={{ p: "10px" }}
          aria-label="search"
          onClick={() => {
            setRefreshData(!refreshData);
          }}
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          key={String(refreshData)}
          sx={{ minWidth: 650 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.tableHeaderCell}
                sx={{ textAlign: "center" }}
              >
                Profile ID
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>Name</TableCell>
              <TableCell className={classes.tableHeaderCell}>Age</TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Nationality
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Marital Status
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Profile Status
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Submission Status
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterData().slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ).length > 0 &&
              filterData()
                .sort((a, b) => (a.dateCr < b.dateCr ? 1 : -1))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                .map((profile, index) => (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      className={classes.tableBodyCell}
                      sx={{ textAlign: "center" }}
                    >
                      {profile?.profileId}
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      {profile?.profilename}
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      {calculate_age(profile?.dateOfBirth)}
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      {countries
                        .filter(
                          (value) =>
                            value.code.toLowerCase() ===
                            profile?.citizenShipCountry.toLowerCase()
                        )[0]
                        ?.label.toUpperCase()}
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      {profile?.marriageStatus}
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      <Switch
                        color="success"
                        checked={profile?.status === "A" ? true : false}
                        readOnly
                      ></Switch>
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      {profile?.submissionStatus === "S"
                        ? "Submitted"
                        : "Draft"}
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}>
                      <IconButton
                        onClick={(event) => handleClick(event, profile)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Popover
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        PaperProps={{
                          sx: {
                            p: 1,
                            width: "auto",
                            elevation: 1,
                            boxShadow: "0 2px 7px rgba(0, 0, 0, 0.2)",
                          },
                        }}
                        open={Boolean(open)}
                        anchorEl={anchorElement}
                        onClose={handleClose}
                      >
                        <>
                          <MenuItem onClick={editProfile}>
                            <EditIcon
                              fontSize="small"
                              color="secondary"
                              sx={{ pr: 2 }}
                            />
                            Edit
                          </MenuItem>
                          {selectedProfile?.submissionStatus === "D" ? (
                            ""
                          ) : (
                            selectedProfile?.status === "A" ? ("") : (
                              <MenuItem onClick={activateProfile}>
                                <ToggleOnIcon
                                  fontSize="small"
                                  color="secondary"
                                  sx={{ pr: 2 }}
                                />
                                Activate
                              </MenuItem>)
                          )}
                          {selectedProfile?.submissionStatus === "D" ? (
                            ""
                          ) : (
                            selectedProfile?.status === "I" ? ("") : (
                            <MenuItem onClick={deactivateProfile}>
                              <ToggleOffIcon
                                fontSize="small"
                                color="secondary"
                                sx={{ pr: 2 }}
                              />
                              Deactivate
                            </MenuItem>)
                          )}
                        </>
                      </Popover>
                    </TableCell>
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
        {filterData().slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        ).length > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={totalRecord}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
}

export default ProfileList;
