import {
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import { Box, Container } from "@mui/system";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
  MyProfilePage,
  ProfileSearchContainer,
} from "../../styles/profilesearch";
import theme from "../../styles/theme";
import Footer from "../footer";
import Label from "../Label";
import Menubar from "../menubar";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import Notification from "../Notification";
import axios from "axios";
import { format, parse } from "date-fns";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import { useEffect } from "react";
import api from "../../services/RegisterService";
import moment from "moment";
import { useSelector } from "react-redux";
import { DataObject } from "@mui/icons-material";
import ColorBar from "../ColorBar";

function EditUserPage() {
  const { selectedEditUserId } = useSelector((state) => state.myProfile);
  const { name, userId, token } = useSelector(
    (state) => state.userPersistedDetails
  );
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });
  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const RegisterSchema = Yup.object().shape({
    userId: Yup.string().required("UserId Id is required"),
    userName: Yup.string().required("User Name is required"),
    userEmail: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
    userRole: Yup.string().required("User Role is required"),
    userText: Yup.string().required("User Text is required"),
    monthlyQuota: Yup.number().required("Monthly Quota is required"),
    usedQuota: Yup.number().required("Used Quota is required"),
    balanceQuota: Yup.number().required("Balance Quota is required"),
    expiryDate: Yup.string().required("ExpiryDate is required"),
  });

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(RegisterSchema),
    defaultValues: {
      userId: "",
      phoneNumber: "",
      userName: "",
      userEmail: "",
      userRole: "",
      userText: "",
      monthlyQuota: "",
      usedQuota: "",
      balanceQuota: "",
      expiryDate: "",
    },
  });

  useEffect(() => {
    async function fetchprofile() {
      try {
        let headers = {
          "authorization": `${token}`
          };
        const response = await api.get(`/userdetails/${selectedEditUserId}`, { headers: headers });
        const myUserData = response.data;
        const {
          userId,
          phoneNumber,
          userName,
          userEmail,
          userRole,
          userText,
          monthlyQuota,
          usedQuota,
          balanceQuota,
          expiryDate,
          regId,
          regStatus,
          lockStatus,
          status,
          createdUser,
          dateCr,
          updatedUser,
          dateUp,
          profileId,
        } = myUserData;

        const defaultValues = {
          userId: userId !== "" && userId !== null ? userId : "-",
          phoneNumber: phoneNumber.replace(/\s+/g, ""),
          userName: userName !== "" && userName !== null ? userName : "-",
          userEmail: userEmail !== "" && userEmail !== null ? userEmail : "-",
          userRole: userRole !== "" && userRole !== null ? userRole : "-",
          userText: userText !== "" && userText !== null ? userText : "-",
          monthlyQuota:
            monthlyQuota !== "" && monthlyQuota !== null ? monthlyQuota : "-",
          usedQuota: usedQuota !== "" && usedQuota !== null ? usedQuota : "-",
          balanceQuota:
            balanceQuota !== "" && balanceQuota !== null ? balanceQuota : "-",
          expiryDate:
            expiryDate !== "" && expiryDate !== null ? expiryDate : "-",

          regId: regId,
          regStatus: regStatus,
          lockStatus: lockStatus,
          status: status,
          createdUser: createdUser,
          // updatedUser: updatedUser,
          profileId: profileId,
        };
        reset(defaultValues);
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else {
          console.log("Error", err.message);
        }
      }
    }
    fetchprofile();
  }, []);

  const onSubmit = (data) => {
    saveProfile(data);
  };
  async function saveProfile(data) {
    try {
      let headers = {
        "authorization": `${token}`
        };
      const response = await api.put(
        "/userdetails/" + data.userId,
        {
          userName: data.userName,
          userEmail: data.userEmail,
          regStatus: data.regStatus,
          userRole: data.userRole,
          expiryDate: data.expiryDate,
          monthlyQuota: data.monthlyQuota,
          usedQuota: data.usedQuota,
          balanceQuota: data.balanceQuota,
          lockStatus: data.lockStatus,
          status: data.status,
          phoneNumber: data.phoneNumber,
          updatedUser: userId,
          userText: data.userText,
        },
        { headers: headers }
      );
      setNotify({
        isOpen: true,
        message: "User Saved Successfully",
        type: "success",
      });
      navigate("/userlist", {
        state: { data },
      });
      return response.data;
    } catch (err) {
      console.log(err);
      setNotify({
        isOpen: true,
        message: "User Update Failed",
        type: "error",
      });
    }
  }
  return (
    <ProfileSearchContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MyProfilePage>
          <Box
            align="justify"
            display={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              lineHeight: 2,
              textAlign: "justify",
              fontSize: 20,
              fontFamily: "heebo",
              pl: 1,
              pr: 5,
              pt: 0,
              pb: "45px",
            }}
          >
            <ColorBar />
            <Typography
              sx={{
                fontFamily: "heebo",
                fontSize: 30,
                fontWeight: 700,
                marginBottom: "10px",
              }}
            >
              Edit User
            </Typography>
            {/* user form section start  */}

            <Grid
              spacing={5}
              container
              item
              sm={12}
              sx={{ marginTop: "-25px" }}
            >
              <Grid spacing={5} item container>
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "20px" }}>User ID</span>
                  </Label>
                  <TextField
                    inputlabelprops={{ shrink: false }}
                    fullWidth
                    disabled
                    name="userId"
                    {...register("userId")}
                    error={!!errors?.userId}
                    helperText={errors?.userId ? errors.userId.message : null}
                  ></TextField>
                </Grid>
                {/* marital status */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "20px" }}>Phone Number</span>
                  </Label>

                  <TextField
                    disabled
                    inputlabelprops={{ shrink: false }}
                    fullWidth
                    name="phoneNumber"
                    {...register("phoneNumber")}
                    error={!!errors?.phoneNumber}
                    helperText={
                      errors?.phoneNumber ? errors.phoneNumber.message : null
                    }
                  ></TextField>
                </Grid>
              </Grid>
              <Grid spacing={5} item container>
                {/* full name field */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "20px" }}>User Name</span>
                  </Label>

                  <TextField
                    inputlabelprops={{ shrink: false }}
                    fullWidth
                    name="userName"
                    {...register("userName")}
                    error={!!errors?.userName}
                    helperText={
                      errors?.userName ? errors.userName.message : null
                    }
                  ></TextField>
                </Grid>
                {/* month/year of birth field */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "20px" }}>User Email</span>
                  </Label>

                  <TextField
                    disabled
                    inputlabelprops={{ shrink: false }}
                    fullWidth
                    name="userEmail"
                    {...register("userEmail")}
                    error={!!errors?.userEmail}
                    helperText={
                      errors?.userEmail ? errors.userEmail.message : null
                    }
                  ></TextField>
                </Grid>
              </Grid>
              <Grid spacing={5} container item sm={12}>
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "20px" }}>User Role</span>
                  </Label>
                  <Controller
                    name="userRole"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        inputlabelprops={{ shrink: false }}
                        fullWidth
                        select
                        name="userRole"
                        {...register("userRole")}
                        error={!!errors?.userRole}
                        helperText={
                          errors?.userRole ? errors.userRole.message : null
                        }
                        value={value}
                        onChange={onChange}
                      >
                        <MenuItem sx={{ height: "40px" }} key="M" value="A">
                          Admin
                        </MenuItem>
                        <MenuItem sx={{ height: "40px" }} key="F" value="U">
                          User
                        </MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                {/* Height (cm) field */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "20px" }}>User Text</span>
                  </Label>

                  <TextField
                    inputlabelprops={{ shrink: false }}
                    fullWidth
                    name="userText"
                    {...register("userText")}
                    error={!!errors?.userText}
                    helperText={
                      errors?.userText ? errors.userText.message : null
                    }
                  ></TextField>
                </Grid>
              </Grid>
              <Grid spacing={5} container item sm={12}>
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "20px" }}>Monthly Quota</span>
                  </Label>
                  <TextField
                    inputlabelprops={{ shrink: false }}
                    fullWidth
                    name="monthlyQuota"
                    {...register("monthlyQuota")}
                    error={!!errors?.monthlyQuota}
                    helperText={
                      errors?.monthlyQuota ? errors.monthlyQuota.message : null
                    }
                  ></TextField>
                </Grid>
                {/* Height (cm) field */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "20px" }}>Used Quota</span>
                  </Label>

                  <TextField
                    inputlabelprops={{ shrink: false }}
                    fullWidth
                    name="usedQuota"
                    {...register("usedQuota")}
                    error={!!errors?.usedQuota}
                    helperText={
                      errors?.usedQuota ? errors.usedQuota.message : null
                    }
                  ></TextField>
                </Grid>
              </Grid>
              <Grid spacing={5} container item sm={12}>
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "20px" }}>Balance Quota</span>
                  </Label>
                  <TextField
                    inputlabelprops={{ shrink: false }}
                    fullWidth
                    name="balanceQuota"
                    {...register("balanceQuota")}
                    error={!!errors?.balanceQuota}
                    helperText={
                      errors?.balanceQuota ? errors.balanceQuota.message : null
                    }
                  ></TextField>
                </Grid>
                {/* Height (cm) field */}
                <Grid item md={6} lg={6} sm={12}>
                  <Label>
                    <span style={{ fontSize: "20px" }}>Expiry Date</span>
                  </Label>

                  <Box>
                    <Controller
                      control={control}
                      name="expiryDate"
                      render={({ field: { onChange, value } }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            inputlabelprops={{
                              shrink: false,
                              readOnly: true,
                            }}
                            name="expiryDate"
                            renderInput={(params) => (
                              <TextField fullWidth {...params} />
                            )}
                            value={moment(value)} // DatePicker accepts a moment object
                            onChange={(dateObject) => {
                              try {
                                onChange(format(dateObject.$d, "yyyy-MM-dd")); // No need of a state
                              } catch (error) {}
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {/* profile section end  */}
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="10vh"
          >
            <LoadingButton
              className="submit-button"
              size="large"
              type="submit"
              variant="contained"
              id="submitButton"
              sx={{
                color: "#000000",
                backgroundColor: "#f9c6bc",
                "&:hover": {
                  color: "#000000",
                  backgroundColor: "#f9c6bc",
                },
              }}
            >
              save
            </LoadingButton>
          </Box>
        </MyProfilePage>
      </form>
      <Footer></Footer>

      <Notification notify={notify} setNotify={setNotify} />
    </ProfileSearchContainer>
  );
}

export default EditUserPage;
